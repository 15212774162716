import { useEffect, useMemo, useState } from 'react';
import { WEBINAR_THEMES } from 'shared';
import { StyledLiveStatusText } from './header.styled';
import { useGetCurrentEvent } from 'lib/api/event';
import { differenceInSeconds } from 'date-fns';
import formatDistanceStrict from 'date-fns/formatDistanceStrict';

export const WebinarStartsBlock = () => {
  const [now, setNow] = useState(Date.now());
  useEffect(() => {
    const interval = setInterval(() => {
      setNow(Date.now());
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const { data: webinar } = useGetCurrentEvent();

  const timeToStart = useMemo(() => {
    return differenceInSeconds(new Date(webinar.startsAtUtc), now);
  }, [now, webinar]);

  const timeToEnd = useMemo(() => {
    return differenceInSeconds(new Date(webinar.endsAtUtc), now);
  }, [now, webinar]);

  if (timeToEnd < 0) {
    return <StyledLiveStatusText color={WEBINAR_THEMES.RED}>Webinar ended</StyledLiveStatusText>;
  }

  if (timeToStart < 0) {
    return (
      <StyledLiveStatusText color={WEBINAR_THEMES.GREEN}>Webinar is live</StyledLiveStatusText>
    );
  }
  return (
    <StyledLiveStatusText color={WEBINAR_THEMES.CORAL}>
      Webinar start in {formatDistanceStrict(new Date(webinar.startsAtUtc), now)}
    </StyledLiveStatusText>
  );
};
