import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useApiClient } from '.';
import { Poll, PollAnswer } from 'models/Poll';
import { useEffect, useMemo } from 'react';
import { pusher } from '../../services';
import { WEBINAR_PUSHER_EVENTS } from '../../shared';
import { useIsOrganizerOfCurrentWebinar } from 'lib/webinar';
import { useGetCurrentSession } from './session';
import { useParams } from 'react-router-dom';

export type PollsParams = { session?: string; isPublished?: boolean };

const getKey = (webinarId: string, params?: PollsParams) => ['webinar', webinarId, 'pools', params];

const getSinglePollKey = (webinarId: string, pollId: string) => [
  'webinar',
  webinarId,
  'pool',
  pollId,
];

const useAddPollToQuery = (webinarId: string, params?: PollsParams) => {
  const queryClient = useQueryClient();
  return (poll: Poll) => {
    if (
      (poll.session && !params.session) ||
      (!poll.session && params.session) ||
      poll.session !== params.session
    ) {
      return;
    }
    queryClient.setQueryData<Poll[]>(getKey(webinarId, params), (polls = []) => {
      polls = polls.slice();
      const pollIndex = polls.findIndex((p) => p._id === poll._id);
      if (pollIndex === -1) {
        polls.push(poll);
      }
      return polls;
    });
  };
};

const useAddAnswerToPollInQuery = (webinarId: string, params?: PollsParams) => {
  const queryClient = useQueryClient();
  return (pollId: string, answer: PollAnswer) => {
    queryClient.setQueryData<Poll[]>(getKey(webinarId, params), (polls = []) => {
      polls = polls.slice();
      const pollIndex = polls.findIndex((p) => p._id === pollId);
      if (pollIndex !== -1) {
        if (!polls[pollIndex].answers) {
          polls[pollIndex].answers = [answer];
          polls[pollIndex].answersCount = 1;
        } else {
          const answerIndex = polls[pollIndex].answers?.findIndex((a) => a._id === answer._id);
          if (answerIndex && answerIndex === -1) {
            polls[pollIndex].answers = [...polls[pollIndex].answers, answer];
            polls[pollIndex].answersCount++;
          }
        }
      }
      return polls;
    });
    queryClient.setQueryData<Poll>(getSinglePollKey(webinarId, pollId), (poll) => {
      if (!poll) {
        return poll;
      }

      if (poll.answers.find((a) => a._id === answer._id)) {
        return poll;
      }

      poll.answers = [...(poll.answers ?? []), answer];
      poll.answersCount = (poll.answersCount ?? 0) + 1;
      return {
        ...poll,
      };
    });
  };
};

const useRemoveAnswerFromPollInQuery = (webinarId: string, params?: PollsParams) => {
  const queryClient = useQueryClient();
  return (pollId: string, answer: PollAnswer) => {
    queryClient.setQueryData<Poll[]>(getKey(webinarId, params), (polls = []) => {
      polls = polls.slice();
      const pollIndex = polls.findIndex((p) => p._id === pollId);
      if (pollIndex !== -1) {
        if (polls[pollIndex].answers) {
          const answerIndex = polls[pollIndex].answers?.findIndex((a) => a._id === answer._id);
          if (answerIndex && answerIndex !== -1) {
            polls[pollIndex].answers = polls[pollIndex].answers?.filter(
              (a) => a._id !== answer._id,
            );
            polls[pollIndex].answersCount--;
          }
        }
      }
      return polls;
    });

    queryClient.setQueryData<Poll>(getSinglePollKey(webinarId, pollId), (poll) => {
      if (!poll) {
        return poll;
      }
      poll.answers = poll.answers?.filter((a) => a._id !== answer._id).slice();
      poll.answersCount = (poll.answersCount ?? 1) - 1;
      return {
        ...poll,
      };
    });
  };
};

const useRemovePollFromQuery = (webinarId: string, params?: PollsParams) => {
  const queryClient = useQueryClient();
  return (pollId: string) => {
    queryClient.setQueryData<Poll[]>(getKey(webinarId, params), (polls = []) => {
      polls = polls.slice();
      return polls.filter((p) => p._id !== pollId);
    });
  };
};

const useUpdatePollInQuery = (webinarId: string, params?: PollsParams) => {
  const queryClient = useQueryClient();
  return (pollId: string, updateParams: Partial<Poll>) => {
    queryClient.setQueryData<Poll[]>(getKey(webinarId, params), (polls = []) => {
      polls = polls.slice();
      const pollIndex = polls.findIndex((p) => p._id === pollId);
      if (pollIndex !== -1) {
        polls[pollIndex] = {
          ...polls[pollIndex],
          ...updateParams,
        };
      }
      return polls;
    });
  };
};

export const useGetPoll = (webinarId: string, pollId: string) => {
  const apiClient = useApiClient();
  return useQuery(getSinglePollKey(webinarId, pollId), async () => {
    const { data } = await apiClient.get<{ data: { poll: Poll } }>(
      `/webinars/${webinarId}/polls/${pollId}`,
      { includeAnswers: true },
    );
    return data.poll;
  });
};

export const useGetPolls = (webinarId: string, params?: PollsParams) => {
  const apiClient = useApiClient();
  return useQuery(
    getKey(webinarId, params),
    async () => {
      const requestParams = {
        include: 'user',
        includeAnswers: true,
        ...params,
      };
      if (!requestParams.session) {
        requestParams.session = 'null';
      }
      const { data } = await apiClient.get<{ data: { polls: Poll[] } }>(
        `/webinars/${webinarId}/polls`,
        requestParams,
      );
      return data.polls;
    },
    { enabled: !!webinarId },
  );
};

export const useCreatePoll = (webinarId: string, params?: PollsParams) => {
  const apiClient = useApiClient();
  const addPollToQuery = useAddPollToQuery(webinarId, params);
  return useMutation(
    async (poll: Partial<Poll>) => {
      const { data } = await apiClient.post<{ data: { poll: Poll } }>(
        `/webinars/${webinarId}/polls`,
        poll,
      );
      return data.poll;
    },
    {
      onSuccess: (poll) => {
        addPollToQuery(poll);
      },
    },
  );
};

export const useAddPollAnswer = (webinarId: string, pollId: string, params?: PollsParams) => {
  const apiClient = useApiClient();
  const removeAnswerFromPollInQuery = useRemoveAnswerFromPollInQuery(webinarId, params);
  const addAnswerToPollInQuery = useAddAnswerToPollInQuery(webinarId, params);
  return useMutation(
    async (value: string | string[] | number) => {
      const { data } = await apiClient.post<{
        data: { answer: PollAnswer; deletedAnswer: PollAnswer };
      }>(`/webinars/${webinarId}/polls/${pollId}/answers`, { value }, { include: 'user' });
      return data;
    },
    {
      onSuccess: (responseData) => {
        addAnswerToPollInQuery(pollId, responseData.answer);
        if (responseData.deletedAnswer) {
          removeAnswerFromPollInQuery(pollId, responseData.deletedAnswer);
        }
      },
    },
  );
};

export const useDeletePoll = (webinarId: string, pollId: string, params?: PollsParams) => {
  const apiClient = useApiClient();
  const removePollFromQuery = useRemovePollFromQuery(webinarId, params);
  return useMutation(
    async () => {
      await apiClient.delete(`/webinars/${webinarId}/polls/${pollId}`);
    },
    {
      onSuccess: () => {
        removePollFromQuery(pollId);
      },
    },
  );
};

export const useUpdatePoll = (webinarId: string, pollId: string, params?: PollsParams) => {
  const apiClient = useApiClient();
  const updatePollInQuery = useUpdatePollInQuery(webinarId, params);
  return useMutation(
    async (updateParams: Partial<Poll>) => {
      await apiClient.patch(`/webinars/${webinarId}/polls/${pollId}`, updateParams);
      return updateParams;
    },
    {
      onSuccess: (updateParams) => {
        updatePollInQuery(pollId, updateParams);
      },
    },
  );
};

export const usePollsPusherEvents = (
  webinarId: string,
  options: {
    onPollPublish: (poll: Poll, sessionTitle?: string) => unknown;
    onPollUpdated: (poll: Poll, sessionTitle?: string) => unknown;
    onPollAcceptingAnswers: (poll: Poll, sessionTitle?: string) => unknown;
    onPollStopAcceptingAnswers: (poll: Poll, sessionTitle?: string) => unknown;
  },
  params?: PollsParams,
) => {
  const addPoll = useAddPollToQuery(webinarId, params);
  const addAnswerToPoll = useAddAnswerToPollInQuery(webinarId, params);
  const updatePoll = useUpdatePollInQuery(webinarId, params);
  const removePoll = useRemovePollFromQuery(webinarId, params);
  const queryClient = useQueryClient();
  const isOrganizer = useIsOrganizerOfCurrentWebinar();
  const parameters = useParams();

  useEffect(() => {
    const channel = pusher.subscribe(`private-event-${webinarId}-polls`);

    channel.bind(
      WEBINAR_PUSHER_EVENTS.POLL_UPDATED,
      (data: { poll: Poll; action: string; sessionTitle?: string }) => {
        if (!data.poll.isPublished && !isOrganizer) {
          removePoll(data.poll._id);
          return;
        }

        const polls = queryClient.getQueryData<Poll[]>(getKey(webinarId, params)) ?? [];
        if (polls.find((p) => p._id === data.poll._id)) {
          updatePoll(data.poll._id, data.poll);
          if (data.action === 'update_poll') {
            options.onPollUpdated(data.poll, data.sessionTitle);
          }
          if (data.action === 'start_accepting_poll_answers') {
            options.onPollAcceptingAnswers(data.poll, data.sessionTitle);
          }

          if (data.action === 'stop_accepting_poll_answers') {
            options.onPollStopAcceptingAnswers(data.poll, data.sessionTitle);
          }
        } else {
          addPoll(data.poll);
          if (data.poll.isPublished) {
            options.onPollPublish(data.poll, data.sessionTitle);
          }
        }
      },
    );

    channel.bind(WEBINAR_PUSHER_EVENTS.POLL_DELETED, (data: { pollId: string }) => {
      removePoll(data.pollId);
    });

    channel.bind(
      WEBINAR_PUSHER_EVENTS.POLL_ANSWER_UPDATED,
      (data: { pollId: string; answer: PollAnswer }) => {
        addAnswerToPoll(data.pollId, data.answer);
      },
    );
    return () => {
      channel.unbind();
      channel.unsubscribe();
    };
  }, [
    webinarId,
    addPoll,
    updatePoll,
    removePoll,
    addAnswerToPoll,
    isOrganizer,
    options,
    params,
    queryClient,
    parameters.sessionUrlKey,
  ]);
};

export const usePollParams = () => {
  const { data: session } = useGetCurrentSession();
  const isOrganizer = useIsOrganizerOfCurrentWebinar();

  return useMemo(() => {
    const params: PollsParams = {};
    if (!isOrganizer) {
      params.isPublished = true;
    }
    params.session = session?._id;
    return params;
  }, [isOrganizer, session]);
};
