import styled from 'styled-components';
import { pulsate, WEBINAR_THEMES } from 'shared';

export const StyledContainer = styled.div`
  position: relative;
`;

export const StyledRing = styled.div`
  ${({ size }) => `
    width: ${size}px;
    height: ${size}px;
 `};
  position: absolute;
  top: -5px;
  left: -5px;
  border: 3px solid ${({ theme }) => theme};
  border-radius: 30px;
  animation: ${pulsate} 1s ease-out infinite;
  opacity: 0;
`;

export const StyledCircle = styled.div`
  ${({ size }) => `
    width: ${size - 10}px;
    height: ${size - 10}px;
 `};
  border-radius: 50%;
  background-color: ${({ theme }) => theme};
  border: 2px solid ${WEBINAR_THEMES.WHITE};
`;

export const StyledOfflineDot = styled.div`
  ${({ size }) => `
    width: ${size - 10}px;
    height: ${size - 10}px;
 `};
  position: absolute;
  top: -4px;
  right: 8px;
  border-radius: 50%;
  background-color: ${WEBINAR_THEMES.GREY};
  border: 2px solid ${WEBINAR_THEMES.WHITE};
`;
