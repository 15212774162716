import styled from 'styled-components';
import { WEBINAR_THEMES } from 'shared';

export const StyledParagraph = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: ${WEBINAR_THEMES.DARK};
`;
