import { WebinarTheme } from 'lib/webinar';
import { FC } from 'react';
import ReactSelect, { Props as ReactSelectProps } from 'react-select';
import { WEBINAR_THEMES, hexToRGB } from 'shared';

interface Props extends Omit<ReactSelectProps, 'theme'> {
  theme?: WebinarTheme;
}

export const Select: FC<Props> = ({ theme, ...rest }) => (
  <ReactSelect
    styles={{
      container: (baseStyles) => ({
        ...baseStyles,
        margin: '8px 0',
        borderColor: theme.itemsColor,
      }),
      valueContainer: (baseStyles) => ({
        ...baseStyles,
        borderColor: theme.itemsColor,
      }),
      control: (baseStyles) => ({
        ...baseStyles,
        borderRadius: '12px',
        borderColor: WEBINAR_THEMES.LIGHTER_GREY,
        boxShadow: '0',
        '&:hover': {
          borderColor: theme.itemsColor,
          boxShadow: `0 0 0 0.25rem ${hexToRGB(theme.itemsColor, '25%')}`,
        },
      }),
      dropdownIndicator: (baseStyles) => ({
        ...baseStyles,
        color: theme.itemsColor,
      }),
      menu: (baseStyles) => ({
        ...baseStyles,
        zIndex: 100,
      }),
      singleValue: (baseStyles) => ({
        ...baseStyles,
        overflow: 'visible',
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      option: (baseStyles, state) => ({
        ...baseStyles,
        color: state.isFocused ? 'white' : WEBINAR_THEMES.BLACK,
        backgroundColor: state.isFocused ? theme.itemsColor : 'transparent',
        '&:active': {
          backgroundColor: theme.itemsColor,
        },
      }),
    }}
    {...rest}
  />
);
