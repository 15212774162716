import Slide, { SlideProps } from '@material-ui/core/Slide';

interface Props extends SlideProps {
  forwardedRef?: React.Ref<unknown>;
}

export const SlideBase = ({ forwardedRef, ...rest }: Props) => (
  <Slide {...rest} ref={forwardedRef} />
);

SlideBase.defaultProps = {
  in: true,
  mountOnEnter: true,
  unmountOnExit: true,
  direction: 'left',
};
