import { useContext, useState } from 'react';
import { OpenRightSidebarTabContext } from 'contexts';
import { WEBINAR_RIGHT_SIDEBAR_TABS } from 'shared';
import styled from 'styled-components';
import Badge from '@material-ui/core/Badge';
import { WEBINAR_THEMES } from 'shared';
import { Tabs } from 'components';
import { StyledTitle } from 'components/ui/Tabs/tabs.styled';
import { useGetCurrentEvent } from 'lib/api/event';
import { useIsOrganizerOfCurrentWebinar, useWebinarTheme } from 'lib/webinar';
import { Engage, EngageTabs } from './Engage/Engage';
import { Resources } from './Resources';
import { useChatContext } from '../ChatContext';
import { usePollParams, usePollsPusherEvents } from 'lib/api/poll';
import { useSendNotification } from 'lib/notifications';
import { useQuestionParams, useQuestionsPusherEvents } from 'lib/api/qa';
import { useResourcePusherEvents } from 'lib/api/resources';
import { useGetCurrentSession } from 'lib/api/session';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledTabs = styled(({ disabled, ...rest }) => <Tabs {...rest} disabled={disabled} />)`
  &.MuiTabs-root {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: rgb(250, 250, 250);
    min-height: 50px;
    border-radius: 12px;
    padding: 5px;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'initial')};
  }

  & .MuiTabs-fixed {
    display: flex;
  }

  & .MuiTabs-flexContainer {
    width: 100%;
    height: 100%;
    justify-content: space-between;
    border-radius: 12px;
  }

  & .MuiTabs-indicator {
    display: none;
  }

  .MuiTab-root {
    min-height: 40px;
    border-radius: 12px;
    overflow: visible;
  }

  .MuiTab-root.Mui-selected {
    background-color: ${WEBINAR_THEMES.WHITE};
    border: 1px solid ${({ theme }) => theme.itemsColor};
  }

  .MuiTab-root ${StyledTitle} {
    color: ${WEBINAR_THEMES.BLACK};
  }

  .MuiTab-root.Mui-selected ${StyledTitle} {
    color: ${({ theme }) => theme.itemsColor};
  }

  .Mui-selected svg path {
    fill: ${({ theme }) => theme.itemsColor};
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledBadge = styled(({ color, ...rest }) => <Badge {...rest} />)`
  &.MuiBadge-root {
    position: absolute;
    right: 12px;
    top: 9px;
  }

  & .MuiBadge-badge {
    color: ${({ color }) => color};
    background-color: ${WEBINAR_THEMES.WHITE};
    border: 1px solid ${({ color }) => color};
  }
`;

const tabs = [
  {
    id: WEBINAR_RIGHT_SIDEBAR_TABS.chat,
    title: 'Engage',
    component: () => <Engage />,
  },
  {
    id: WEBINAR_RIGHT_SIDEBAR_TABS.resources,
    title: 'Resources',
    component: () => <Resources />,
  },
];

function addSessionTitle(message: string, sessionTitle?: string) {
  if (sessionTitle) {
    return message + ` on "${sessionTitle}" breakout session`;
  }
  return message;
}

export const SidebarContent = () => {
  const notification = useSendNotification();
  const { data: session } = useGetCurrentSession();
  const { openRightSidebarTab, openedRightSidebarTab, chatTab } = useContext(
    OpenRightSidebarTabContext,
  );
  const { data: webinar } = useGetCurrentEvent();
  const { itemsColor } = useWebinarTheme(webinar);

  const [latestCurrentChannel, setLatestCurrentChannel] = useState<string>();
  const { totalUnread, setCurrentChannel, currentChannel } = useChatContext();

  const isOrganizer = useIsOrganizerOfCurrentWebinar();

  const disableTabs = webinar.isCompleted && !isOrganizer;

  const openPollsTab = () => {
    openRightSidebarTab(0);
    chatTab.openChatTab(EngageTabs.findIndex((tab) => tab.id === 'polls'));
  };

  const pollsParams = usePollParams();
  usePollsPusherEvents(
    webinar._id,
    {
      onPollPublish(poll, sessionTitle) {
        if (session?._id === poll.session) {
          notification.send({
            title: 'New poll',
            message: addSessionTitle(`"${poll.label}" was just added`, sessionTitle),
            onClick: openPollsTab,
          });
        }
      },
      onPollAcceptingAnswers(poll, sessionTitle) {
        if (session?._id === poll.session) {
          notification.send({
            title: 'Poll accepting answers',
            message: addSessionTitle(`"${poll.label}" is now accepting answers`, sessionTitle),
            onClick: openPollsTab,
          });
        }
      },
      onPollStopAcceptingAnswers(poll, sessionTitle) {
        if (session?._id === poll.session) {
          notification.send({
            title: 'Poll stopped accepting answers',
            message: addSessionTitle(`"${poll.label}" has stopped accepting answers`, sessionTitle),
            onClick: openPollsTab,
          });
        }
      },
      onPollUpdated(poll, sessionTitle) {
        if (session?._id === poll.session) {
          notification.send({
            title: 'Poll updated',
            message: addSessionTitle(`"${poll.label}" updated`, sessionTitle),
            onClick: openPollsTab,
          });
        }
      },
    },
    pollsParams,
  );

  const openQATab = () => {
    openRightSidebarTab(0);
    chatTab.openChatTab(EngageTabs.findIndex((tab) => tab.id === 'q&a'));
  };

  const questionParams = useQuestionParams();
  useQuestionsPusherEvents(
    webinar._id,
    {
      onNonApprovedQuestionAdded(question, sessionTitle) {
        notification.send({
          title: 'New question',
          message: addSessionTitle(
            `"${question.label}" was just added. Please approve or decline`,
            sessionTitle,
          ),
          onClick: openQATab,
        });
      },

      onApprovedQuestion(question, sessionTitle) {
        if (session?._id === question.session) {
          notification.send({
            title: 'New question',
            message: addSessionTitle(`"${question.label}" was just added`, sessionTitle),
            onClick: openQATab,
          });
        }
      },

      onQuestionAnswered(question, sessionTitle) {
        if (session?._id === question.session) {
          notification.send({
            title: 'Question answered',
            message: addSessionTitle(`"${question.label}" marked as answered`, sessionTitle),
            onClick: openQATab,
          });
        }
      },

      onQuestionUnAnswered(question, sessionTitle) {
        if (session?._id === question.session) {
          notification.send({
            title: 'Question unanswered',
            message: addSessionTitle(`"${question.label}" marked as unanswered`, sessionTitle),
            onClick: openQATab,
          });
        }
      },
    },
    questionParams,
  );

  const openResourcesTab = () => {
    openRightSidebarTab(1);
  };

  useResourcePusherEvents(webinar._id, {
    onNewResource(resource) {
      notification.send({
        title: 'New resource',
        message: `"${resource.title}" was added as resource`,
        onClick: openResourcesTab,
      });
    },
  });

  const onTabClick = (tab: number) => {
    openRightSidebarTab(tab);
    if (tab === 0) {
      setCurrentChannel(latestCurrentChannel);
    } else {
      setLatestCurrentChannel(currentChannel);
      setCurrentChannel('');
    }
  };

  const options = tabs.map((tab) => {
    if (tab.id === WEBINAR_RIGHT_SIDEBAR_TABS.chat) {
      return {
        ...tab,
        icon: () => (
          <StyledBadge badgeContent={totalUnread} color={itemsColor} overlap="circular" />
        ),
      };
    }
    return tab;
  });

  return (
    <StyledTabs
      options={options}
      value={openedRightSidebarTab}
      disabled={disableTabs}
      handleChange={(e, value) => onTabClick(value)}
    />
  );
};
