import withStyles from '@material-ui/styles/withStyles';
import { WEBINAR_THEMES } from 'shared';
import Tooltip from '@material-ui/core/Tooltip';

/**
 * Description:
 *
 * Component for Tooltip
 */

export const TooltipBase = ({ children, ...rest }) => <Tooltip {...rest}>{children}</Tooltip>;

TooltipBase.defaultProps = {
  placement: 'top',
};

export const GreyTooltip = withStyles({
  tooltip: {
    fontFamily: 'Poppins',
    backgroundColor: WEBINAR_THEMES.GREY,
    padding: '10px 20px',
    borderRadius: '10px',
  },
  arrow: {
    color: WEBINAR_THEMES.GREY,
  },
})(Tooltip);

export const WhiteTooltip = withStyles({
  tooltip: {
    backgroundColor: WEBINAR_THEMES.WHITE,
    padding: '60px 50px',
    borderRadius: '20px',
  },
})(Tooltip);
