import { FC, useEffect, useRef, useState } from 'react';
import ClearIcon from '@material-ui/icons/Clear';
import { pinned as pinnedIcon } from 'assets';
import { AppMessage } from 'lib/api/chat';
import { useIsOrganizerOfCurrentWebinar, WebinarTheme } from 'lib/webinar';
import { Slide } from 'components';
import { Message } from '../Message';
import {
  StyledWrapper,
  StyledControls,
  StyledButton,
  StyledTitle,
  StyledToggleWrapper,
  StyledToggleArrow,
} from './pinnedMessage.styled';

interface Props {
  message: AppMessage;
  theme: WebinarTheme;
  onUnpinMessage: () => unknown;
}

export const PinnedMessage: FC<Props> = ({ message, theme, onUnpinMessage }) => {
  const [show, setShow] = useState(false);
  const [messageWidth, setMessageWidth] = useState(null);
  const { itemsColor } = theme;
  const isOrganizer = useIsOrganizerOfCurrentWebinar();
  const messageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setMessageWidth(messageRef?.current ? messageRef.current.offsetWidth : null);
  }, [messageRef, message]);

  return (
    <Slide direction="left" in={!!message}>
      <StyledWrapper show={!show} messageWidth={messageWidth}>
        {isOrganizer && (
          <StyledControls>
            <StyledButton backgroundColor={itemsColor} disableRipple onClick={onUnpinMessage}>
              <ClearIcon fontSize="inherit" />
            </StyledButton>
          </StyledControls>
        )}
        {messageWidth >= 264 && (
          <StyledToggleWrapper onClick={() => setShow(!show)}>
            <StyledToggleArrow
              borderColor={itemsColor}
              style={{
                transition: 'transform 0.2s',
                transform: `rotate(${!show ? 45 : 225}deg`,
                marginTop: show ? '8px' : '5px',
              }}
            />
          </StyledToggleWrapper>
        )}
        <StyledTitle textColor={itemsColor}>{pinnedIcon()} Pinned Message</StyledTitle>
        <Message messageRef={messageRef} theme={theme} message={message} />
      </StyledWrapper>
    </Slide>
  );
};
