import { Grow, Skeleton } from 'components';
import { List } from './List';
import { useGetResources } from 'lib/api/resources';
import { useGetCurrentEvent } from 'lib/api/event';
import {
  StyledNoItems,
  StyledNoItemsText,
  StyledSkeletonsWrapper,
  StyledSkeletonWrapper,
} from './resources.styled';

export const Resources = () => {
  const { data: webinar } = useGetCurrentEvent();
  const { data, isInitialLoading } = useGetResources(webinar._id);

  if (isInitialLoading) {
    return (
      <Grow in timeout={300}>
        <StyledSkeletonsWrapper>
          {Array(5)
            .fill(1)
            .map((_, ind) => (
              <StyledSkeletonWrapper key={ind}>
                <Skeleton
                  variant="rect"
                  height={60}
                  width="100%"
                  style={{ borderRadius: '10px' }}
                />
              </StyledSkeletonWrapper>
            ))}
        </StyledSkeletonsWrapper>
      </Grow>
    );
  }

  if (!data?.length) {
    return (
      <Grow>
        <StyledNoItems>
          <StyledNoItemsText>No Resources</StyledNoItemsText>
        </StyledNoItems>
      </Grow>
    );
  }

  return (
    <Grow>
      <div style={{ overflow: 'hidden' }}>
        <List resources={data} />
      </div>
    </Grow>
  );
};
