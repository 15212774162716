import { useGetCurrentEvent } from '../lib/api/event';
import { useIsOrganizerOrSpeakerOfCurrentWebinar } from '../lib/webinar';

export const OrganizerOrSpeakerProtected = ({ children }) => {
  const { data: webinar, isLoading } = useGetCurrentEvent();
  const isOrganizerOrSpeaker = useIsOrganizerOrSpeakerOfCurrentWebinar();
  if (isLoading || !webinar) {
    return <></>;
  }
  if (!isOrganizerOrSpeaker) {
    return <h1>Only organizers and speakers can see this page</h1>;
  }
  return <>{children}</>;
};
