export const font = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22">
    <path
      d="M 15.047 8.25 L 6.953 8.25 L 6.875 11 L 7.563 11 C 7.81 9.493 8.052 9.361 9.892 9.302 L 10.295 9.288 L 10.295 16.287 C 10.295 16.933 10.157 17.087 9.06 17.188 L 9.06 17.875 L 12.939 17.875 L 12.939 17.188 C 11.835 17.087 11.697 16.934 11.697 16.288 L 11.697 9.288 L 12.107 9.302 C 13.947 9.361 14.189 9.494 14.436 11 L 15.124 11 L 15.045 8.25 Z"
      fill="rgb(0,0,0)"
    />
    <path
      d="M 19.25 6.188 L 19.25 19.25 C 19.25 20.769 18.019 22 16.5 22 L 5.5 22 C 3.981 22 2.75 20.769 2.75 19.25 L 2.75 2.75 C 2.75 1.231 3.981 0 5.5 0 L 13.063 0 Z M 15.125 6.188 C 13.986 6.188 13.063 5.264 13.063 4.125 L 13.063 1.375 L 5.5 1.375 C 4.741 1.375 4.125 1.991 4.125 2.75 L 4.125 19.25 C 4.125 20.009 4.741 20.625 5.5 20.625 L 16.5 20.625 C 17.259 20.625 17.875 20.009 17.875 19.25 L 17.875 6.188 Z"
      fill="rgb(0,0,0)"
    />
  </svg>
);
