import styled from 'styled-components';
import { WEBINAR_THEMES } from 'shared';
import { Avatar, OnlineDot } from 'components/ui';
import { ListeningBars, Pill, Text } from 'components';

export const StyledUserInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledBox = styled.div`
  display: flex;
`;

export const StyledAvatar = styled(Avatar)`
  &.MuiAvatar-root {
    width: 30px;
    height: 30px;
  }
`;

export const StyledNameWrapper = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledDarkText = styled.p`
  font-weight: 600;
  font-size: 13px;
  color: ${WEBINAR_THEMES.DARK};
`;

export const StyledContainer = styled.div`
  display: flex;
`;

export const StyledDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledListeningBars = styled(ListeningBars)`
  margin-right: 60px;
  margin-left: 5px;
`;

export const StyledLabelText = styled.h1`
  font-weight: 600;
  font-size: 16px;
  font-family: 'Poppins', serif;

  a {
    color: ${({ theme }) => theme};
  }
`;

export const StyledDropdownList = styled.ul`
  min-width: 130px;
`;

export const StyledDropdownItem = styled.li`
  display: flex;
  align-items: center;
  height: 40px;
  padding: 10px 0 10px 10px;
  font-size: 12px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme};
    color: ${WEBINAR_THEMES.WHITE};
  }
`;

export const StyledDropdownContent = styled.div`
  height: 40px;
  padding: 10px 0;
  display: flex;
  align-items: center;

  > span {
    margin-left: 5px;
  }
`;

export const StyledRedDot = styled(OnlineDot)`
  position: relative;
  top: 0;
  left: 0;
  margin-right: 5px;
  margin-left: 2px;
`;

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const StyledPollStatusWrapper = styled.div`
  display: flex;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 12px;
  margin-bottom: 20px;
  justify-content: space-between;
  color: ${WEBINAR_THEMES.GREY};
`;

export const StyledQuestionStatus = styled.div`
  margin-right: 5px;
`;

export const StyledPillWrapper = styled.div`
  display: flex;
`;

export const StyledPill = styled(Pill)`
  &.MuiChip-root {
    height: 16px;
    font-size: 10px;
    color: ${WEBINAR_THEMES.WHITE};
    background-color: ${({ theme }) => theme};
  }
`;

export const StyledTime = styled.time`
  display: flex;
  font-weight: 500;
  font-size: 11px;
  font-family: 'Poppins', serif;
  white-space: nowrap;
  color: ${WEBINAR_THEMES.GREY};
`;

export const StyledButtons = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 16px;
  margin-top: 30px;
`;

export const StyledQuestionText = styled(Text)`
  text-align: center;
`;
