import { Event } from 'models/Event';
import { WEBINAR_ROLES, WEBINAR_THEMES } from 'shared';
import { useGetCurrentEvent } from './api/event';

export interface WebinarTheme {
  isWhiteLabel: boolean;
  color: string;
  itemsColor: string;
  additionalColors: {
    primaryColor: string;
    secondaryColor: string;
    tertiaryColor: string;
    defaultColor: string;
    fontColor: string;
  };
}
export const useWebinarTheme = (webinar?: Event): WebinarTheme => {
  const { isWhiteLabel, primaryColor, secondaryColor } = webinar;

  return {
    isWhiteLabel,
    color: primaryColor || WEBINAR_THEMES.BLUE,
    itemsColor: primaryColor || WEBINAR_THEMES.BLUE,
    additionalColors: {
      primaryColor: primaryColor || WEBINAR_THEMES.BLUE,
      secondaryColor: secondaryColor || WEBINAR_THEMES.LIGHT_GREEN,
      tertiaryColor: WEBINAR_THEMES.WHITE,
      defaultColor: WEBINAR_THEMES.BLUE,
      fontColor: WEBINAR_THEMES.BLACK,
    },
  };
};

export const getWebinarRegistrationUrl = (webinarUrlKey: string) => {
  return process.env.REACT_APP_WEB_URL + `/webinar/${webinarUrlKey}`;
};

export const useIsOrganizerOfCurrentWebinar = () => {
  const { data: webinar } = useGetCurrentEvent();
  return webinar?.eventAccess && webinar.eventAccess.role === WEBINAR_ROLES.organizer;
};

export const useIsSpeakerOfCurrentWebinar = () => {
  const { data: webinar } = useGetCurrentEvent();
  return webinar?.eventAccess && webinar.eventAccess.role === WEBINAR_ROLES.speaker;
};

export const useIsOrganizerOrSpeakerOfCurrentWebinar = () => {
  const isOrganizer = useIsOrganizerOfCurrentWebinar();
  const isSpeaker = useIsSpeakerOfCurrentWebinar();
  return isOrganizer || isSpeaker;
};
