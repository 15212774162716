import { forwardRef } from 'react';
import { WebinarTheme } from 'lib/webinar';
import { StyledHelperText, StyledInput } from './Input.styled';

export interface InputProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label: string;
  error?: string | boolean;
  helperText?: string;
  hideLabel?: boolean;
  theme?: WebinarTheme | WebinarTheme['additionalColors'];
  variant?: string;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ className, label, error, helperText, variant, hideLabel = false, ...rest }, ref) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }} className={className}>
        {!hideLabel && (
          <label style={{ marginBottom: 10 }} htmlFor={rest.id}>
            {label}
          </label>
        )}
        <StyledInput type="text" variant={variant} error={error} {...rest} ref={ref} />
        {helperText && <StyledHelperText>{helperText}</StyledHelperText>}
      </div>
    );
  },
);
