export const ppt = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22">
    <path
      d="M 9.625 7.563 C 8.866 7.563 8.25 8.178 8.25 8.938 L 8.25 17.875 C 8.25 18.255 8.558 18.563 8.938 18.563 C 9.317 18.563 9.625 18.255 9.625 17.875 L 9.625 15.125 L 11.259 15.125 C 13.347 15.125 15.04 13.432 15.04 11.344 C 15.04 9.255 13.347 7.563 11.259 7.563 Z M 11.259 13.75 L 9.625 13.75 L 9.625 8.938 L 11.259 8.938 C 12.587 8.938 13.665 10.015 13.665 11.344 C 13.665 12.673 12.587 13.75 11.259 13.75 Z"
      fill="rgb(0,0,0)"
    />
    <path
      d="M 19.25 6.188 L 19.25 19.25 C 19.25 20.769 18.019 22 16.5 22 L 5.5 22 C 3.981 22 2.75 20.769 2.75 19.25 L 2.75 2.75 C 2.75 1.231 3.981 0 5.5 0 L 13.063 0 Z M 15.125 6.188 C 13.986 6.188 13.063 5.264 13.063 4.125 L 13.063 1.375 L 5.5 1.375 C 4.741 1.375 4.125 1.991 4.125 2.75 L 4.125 19.25 C 4.125 20.009 4.741 20.625 5.5 20.625 L 16.5 20.625 C 17.259 20.625 17.875 20.009 17.875 19.25 L 17.875 6.188 Z"
      fill="rgb(0,0,0)"
    />
  </svg>
);
