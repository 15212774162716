import styled from 'styled-components';
import { WEBINAR_THEMES, hexToRGB, TextEllipsis } from 'shared';
import { Text } from 'components';

export const StyledUl = styled.ul`
  padding: 0 20px 10px;
  max-height: 350px;
`;

export const StyledLi = styled.li`
  padding: 15px 0;
  border-bottom: 1px solid ${hexToRGB(WEBINAR_THEMES.DARK_GREY, 0.1)};
  cursor: pointer;

  &:first-of-type {
    border-top: 1px solid ${hexToRGB(WEBINAR_THEMES.DARK_GREY, 0.1)};
  }

  &:last-of-type {
    border-bottom: none;
  }
`;

export const StyledContentWrapper = styled.div``;

export const StyledTitle = styled((props) => <Text {...props} />)`
  ${TextEllipsis};
  color: ${({ theme }) => theme};
`;

export const StyledDesc = styled(Text)`
  margin: 0;
`;

export const StyledText = styled(Text)`
  ${TextEllipsis};
`;

export const StyledButton = styled.button`
  font-size: 12px;
  padding: 5px 10px;
  margin-bottom: 5px;
  color: ${WEBINAR_THEMES.WHITE};
  background-color: ${({ theme }) => theme};
  border-radius: 10px;
  max-width: 150px;

  &:hover {
    transform: scale(1.1);
    transition: 0.2s;
  }
`;
