import { useEffect } from 'react';
import { Userpilot } from 'userpilot';
import { useGetCurrentUser } from '../lib/api/user';

if (process.env.REACT_APP_USER_PILOT_TOKEN) {
  Userpilot.initialize(process.env.REACT_APP_USER_PILOT_TOKEN);
}

export const useUserPilot = () => {
  const { data: user } = useGetCurrentUser();
  useEffect(() => {
    if (user?._id) {
      Userpilot.identify(user._id, {});
    } else {
      Userpilot.anonymous();
    }
  }, [user._id]);
  useEffect(() => {
    Userpilot.reload();
  }, []);
};
