export const firefoxIcon = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 291.678 291.678"
    style={{ enableBackground: 'new 0 0 291.678 291.678' }}
  >
    <g>
      <g>
        <path
          style={{ fill: '#2394BC' }}
          d="M145.66,4.277c75.561,0,136.556,60.995,136.556,136.556S221.22,277.389,145.66,277.389
			S9.104,216.394,9.104,140.833S70.099,4.277,145.66,4.277z"
        />
        <path
          style={{ fill: '#EC8840' }}
          d="M169.329,284.671c69.188-11.835,121.99-71.009,121.99-143.839l-0.91,1.821
			c1.821-13.656,1.821-26.401-0.91-36.415c-0.91,8.193-1.821,12.745-3.642,14.566c0-0.91,0-9.104-2.731-20.028
			c-0.91-8.193-2.731-16.387-5.462-23.67c0.91,3.641,0.91,6.373,0.91,9.104c-10.924-28.222-36.415-63.726-101.051-62.816
			c0,0,22.759,2.731,32.773,18.207c0,0-10.924-2.731-19.118,1.821c10.014,3.641,19.118,8.193,26.401,12.745h0.91
			c1.821,0.91,3.641,2.731,5.462,3.641c13.656,10.014,26.401,23.67,25.49,40.967c-3.641-5.462-7.283-9.104-12.745-10.014
			c6.373,24.58,6.373,44.608,1.821,60.085c-3.641-10.924-6.373-16.387-9.104-19.118c3.641,32.773-0.91,56.443-15.476,71.919
			c2.731-9.104,3.641-17.297,3.641-23.67c-17.297,25.49-36.415,39.146-58.264,40.056c-8.193,0-16.387-0.91-24.58-3.641
			c-10.924-3.641-20.939-10.014-30.042-19.118c13.656,0.91,27.311-0.91,38.236-7.283l18.207-11.835l0,0
			c2.731-0.91,4.552-0.91,7.283,0c4.552-0.91,6.373-2.731,4.552-7.283c-1.821-2.731-5.462-5.462-10.014-8.193
			c-9.104-4.552-19.118-3.641-29.132,2.731c-10.014,5.462-19.118,5.462-28.222-0.91c-5.462-3.641-11.835-9.104-17.297-16.387
			l-1.821-3.641c-0.91,8.193,0,17.297,3.641,30.042l0,0l0,0c-3.641-11.835-4.552-21.849-3.641-30.042l0,0
			c0-6.373,2.731-10.924,8.193-10.924h-1.821h2.731c6.373,0.91,12.745,1.821,20.939,4.552c0.91-7.283,0-15.476-5.462-23.67l0,0
			c7.283-7.283,13.656-11.835,19.118-14.566c2.731-0.91,3.641-3.641,4.552-6.373l0,0l0,0l0,0c1.821-3.641,0.91-5.462-0.91-7.283
			c-5.462,0-10.014,0-15.476-0.91l0,0c-1.821-0.91-4.552-2.731-8.193-5.462l-8.193-8.193l-2.731-1.821l0,0l0,0l0,0l-0.91-0.91
			l0.91-0.91c0.91-6.373,2.731-11.835,5.462-16.387l0.91-0.91c2.731-4.552,8.193-9.104,15.476-14.566
			c-14.566,1.821-27.311,8.193-39.146,19.118c-9.104-2.731-20.939-1.821-33.684,3.641l-1.821,0.91l0,0l1.821-0.91l0,0
			c-8.193-3.641-13.656-14.566-16.387-32.773C20.939,36.14,16.387,55.258,16.387,81.659l-2.731,4.552l-0.91,0.91l0,0l0,0l0,0
			c-1.821,2.731-3.641,6.373-6.373,10.924c-3.641,7.283-5.462,12.745-5.462,18.207l0,0l0,0v1.821l0,0c0,0.91,0,2.731,0,3.641
			l8.193-6.373c-2.731,8.193-5.462,16.387-6.373,24.58v3.641L0,140.833c0,30.953,10.014,60.085,26.401,83.754l0.91,0.91l0.91,0.91
			c11.835,16.387,27.311,30.042,45.519,40.967c12.745,7.283,26.401,12.745,40.967,16.387l2.731,0.91
			c2.731,0.91,6.373,0.91,9.104,1.821c2.731,0,4.552,0.91,7.283,0.91h2.731h4.552h4.552h3.641h6.373c3.641,0,7.283-0.91,10.924-0.91
			C166.598,284.671,169.329,284.671,169.329,284.671z M261.277,107.149v0.91V107.149L261.277,107.149z"
        />
      </g>
    </g>
  </svg>
);
