export const chromeIcon = () => (
  <svg
    viewBox="0 0 256 257"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid"
  >
    <defs>
      <linearGradient
        x1="49.9976632%"
        y1="0.70578635%"
        x2="49.9976632%"
        y2="96.9898739%"
        id="linearGradient-1"
      >
        <stop stopColor="#86BBE5" offset="0%" />
        <stop stopColor="#1072BA" offset="100%" />
      </linearGradient>
    </defs>
    <g>
      <path
        d="M127.788721,0.035213205 C127.788721,0.035213205 203.109766,-3.34525447 243.041541,72.3631362 L121.379917,72.3631362 C121.379917,72.3631362 98.4209078,71.6236589 78.8071527,99.477304 C73.1730399,111.168088 67.1163686,123.211004 73.9125172,146.944704 C64.1232462,130.359285 21.9378267,56.9045392 21.9378267,56.9045392 C21.9378267,56.9045392 51.6929849,3.02833563 127.788721,0.035213205 L127.788721,0.035213205 Z"
        fill="#EF3F36"
      />
      <path
        d="M239.132875,192.228886 C239.132875,192.228886 204.377442,259.169188 118.87978,255.859147 C129.443741,237.583494 179.728198,150.501238 179.728198,150.501238 C179.728198,150.501238 191.876754,130.993122 177.544979,100.075928 C170.255846,89.335901 162.82586,78.1028886 138.88088,72.1166437 C158.142503,71.9405777 242.830261,72.1166437 242.830261,72.1166437 C242.830261,72.1166437 274.557359,124.830812 239.132875,192.228886 L239.132875,192.228886 Z"
        fill="#FCD900"
      />
      <path
        d="M16.9727648,192.757084 C16.9727648,192.757084 -23.6280605,129.197249 22.0082531,56.7988996 C32.5370014,75.074553 82.821458,162.156809 82.821458,162.156809 C82.821458,162.156809 93.6671252,182.439615 127.577442,185.46795 C140.500688,184.517194 153.952132,183.70729 171.136176,165.995048 C161.663824,182.756534 119.161486,256 119.161486,256 C119.161486,256 57.6088033,257.126823 16.9727648,192.757084 L16.9727648,192.757084 Z"
        fill="#61BC5B"
      />
      <path
        d="M118.844567,256.492985 L135.958184,185.080605 C135.958184,185.080605 154.762036,183.601651 170.537552,166.311967 C160.748281,183.531224 118.844567,256.492985 118.844567,256.492985 L118.844567,256.492985 Z"
        fill="#5AB055"
      />
      <path
        d="M70.4616231,129.056396 C70.4616231,97.5757909 95.9911967,72.0462173 127.471802,72.0462173 C158.952407,72.0462173 184.481981,97.5757909 184.481981,129.056396 C184.481981,160.537001 158.952407,186.066575 127.471802,186.066575 C95.9911967,186.031362 70.4616231,160.537001 70.4616231,129.056396 L70.4616231,129.056396 Z"
        fill="#FFFFFF"
      />
      <path
        d="M80.0044017,129.056396 C80.0044017,102.857772 101.237964,81.5889959 127.471802,81.5889959 C153.670426,81.5889959 174.939202,102.822558 174.939202,129.056396 C174.939202,155.255021 153.70564,176.523796 127.471802,176.523796 C101.273177,176.523796 80.0044017,155.255021 80.0044017,129.056396 L80.0044017,129.056396 Z"
        fill="url(#linearGradient-1)"
      />
      <path
        d="M242.795048,72.1518569 L172.333425,92.8220083 C172.333425,92.8220083 161.699037,77.2225585 138.845667,72.1518569 C158.670702,72.0462173 242.795048,72.1518569 242.795048,72.1518569 L242.795048,72.1518569 Z"
        fill="#EACA05"
      />
      <path
        d="M72.5392022,144.338927 C62.6442916,127.190096 21.9378267,56.9045392 21.9378267,56.9045392 L74.1237964,108.527098 C74.1237964,108.527098 68.7713893,119.548831 70.778542,135.324347 L72.5392022,144.338927 L72.5392022,144.338927 Z"
        fill="#DF3A32"
      />
    </g>
  </svg>
);
