import { useState } from 'react';
import { formatDistanceToNow } from 'date-fns/esm';
import bell from 'assets/images/webinar/bell.png';
import { storageService } from 'services';
import { usePopoverState } from 'components';
import { useNotificationCenter } from 'react-toastify/addons/use-notification-center';
import {
  StyledBadge,
  StyledPopover,
  StyledSettings,
  StyledBellImage,
  StyledIconButton,
} from './notifications.styled';
import { useGetCurrentEvent } from 'lib/api/event';
import { useWebinarTheme } from 'lib/webinar';
import {
  StyledContentWrapper,
  StyledDesc,
  StyledLi,
  StyledText,
  StyledTitle,
  StyledUl,
} from './list.styled';
import { NotificationData } from 'lib/notifications';
import { ThemedSwitch } from 'components/ui/ThemedSwitch';

export const Notifications = () => {
  const { notifications, unreadCount, markAllAsRead } = useNotificationCenter<NotificationData>();
  const [disabled, setDisabled] = useState(() => {
    const value = storageService.getItem('notificationsDisabled');
    return !!JSON.parse(value);
  });
  const [anchorEl, handleOpenNotificationPopup, handleCloseNotificationPopup] = usePopoverState();
  const { data: webinar } = useGetCurrentEvent();
  const { itemsColor } = useWebinarTheme(webinar);
  const openNotifications = (e) => {
    handleOpenNotificationPopup(e);
    markAllAsRead();
  };

  return (
    <>
      <StyledIconButton onClick={openNotifications}>
        <StyledBadge variant="dot" overlap="circular" invisible={unreadCount === 0}>
          <StyledBellImage src={bell} alt="Bell icon" />
        </StyledBadge>
      </StyledIconButton>

      <StyledPopover open={!!anchorEl} anchorEl={anchorEl} onClose={handleCloseNotificationPopup}>
        <StyledSettings>
          <ThemedSwitch
            label="Disable notifications"
            name="Disable notifications"
            onChange={({ target }) => {
              const { checked } = target;
              storageService.setItem('notificationsDisabled', checked);
              setDisabled(checked);
            }}
            checked={disabled}
            appVariant="primaryColor"
          />
        </StyledSettings>
        <StyledUl>
          {notifications.map((notification) => (
            <StyledLi key={notification.id} onClick={notification.data.onClick}>
              <StyledContentWrapper>
                <StyledTitle theme={itemsColor} variant="bold">
                  {notification.data.title}
                </StyledTitle>
                <StyledDesc>{notification.data.message}</StyledDesc>
                <StyledText>{formatDistanceToNow(notification.createdAt)}</StyledText>
              </StyledContentWrapper>
            </StyledLi>
          ))}
        </StyledUl>
      </StyledPopover>
    </>
  );
};
