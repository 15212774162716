import { useQuery } from '@tanstack/react-query';
import { useApiClient } from '.';
import { Session } from '../../models/Session';
import { useEventUrlKey, useSessionUrlKey } from '../../shared';

const getKey = (webinarUrlKey: string, sessionUrlKey?: string) => [
  'webinar',
  webinarUrlKey,
  'sessions',
  sessionUrlKey,
];

export const useGetWebinarSessions = (webinarUrlKey: string) => {
  const apiClient = useApiClient();
  return useQuery(getKey(webinarUrlKey), async () => {
    const { data } = await apiClient.get<{ data: { sessions: Session[] } }>(
      `/events/${webinarUrlKey}/sessions`,
      {
        projections: 'videoRoom,members,viewers,participatingUsers',
        scheduleItems: true,
      },
    );
    return data.sessions;
  });
};

export const useGetCurrentWebinarSessions = () => {
  const webinarUrlKey = useEventUrlKey();
  return useGetWebinarSessions(webinarUrlKey);
};

export const useGetSessionByUrlKey = (webinarUrlKey: string, sessionUrlKey: string) => {
  const apiClient = useApiClient();
  return useQuery(
    getKey(webinarUrlKey, sessionUrlKey),
    async () => {
      const { data } = await apiClient.get<{ data: { session: Session } }>(
        `/events/${webinarUrlKey}/sessions/${sessionUrlKey}`,
      );
      return data.session;
    },
    { enabled: !!webinarUrlKey && !!sessionUrlKey },
  );
};

export const useGetCurrentSession = () => {
  const webinarUrlKey = useEventUrlKey();
  const sessionUrlKey = useSessionUrlKey();
  return useGetSessionByUrlKey(webinarUrlKey, sessionUrlKey);
};
