import { useContext, useMemo } from 'react';
import { OpenRightSidebarTabContext } from 'contexts';
import { Slide } from 'components';
import { StyledChatWrapper, StyledChatTabs } from './engage.styled';
import { useGetCurrentEvent } from 'lib/api/event';
import { Polls } from './Polls';
import { QA } from './Q&A';
import { Chat } from './Chat/Chat';

export const EngageTabs = [
  {
    id: 'chat',
    title: 'Chat',
    component: () => <Chat />,
  },
  {
    id: 'polls',
    title: 'Polls',
    component: () => <Polls />,
  },
  {
    id: 'q&a',
    title: 'Q&A',
    component: () => <QA />,
  },
];

export const Engage = () => {
  const { chatTab } = useContext(OpenRightSidebarTabContext);
  const { data: webinar } = useGetCurrentEvent();
  const eventFormat = webinar.eventFormat;
  const tabs = useMemo(() => {
    if (eventFormat === 'webinar') {
      return EngageTabs;
    }
    return EngageTabs.filter((tab) => tab.id === 'chat');
  }, [eventFormat]);

  return (
    <StyledChatWrapper>
      <Slide direction="down" in>
        <div className="chatTabPanelWrapper">
          <StyledChatTabs
            classNames={{ tabPanel: 'chatTabPanel' }}
            options={tabs}
            value={chatTab.activeChatTab}
            handleChange={(e, value) => chatTab.openChatTab(value)}
          />
        </div>
      </Slide>
    </StyledChatWrapper>
  );
};
