import { Text } from 'components';
import styled from 'styled-components';

export const StyledSection = styled.section`
  padding: 30px;
`;

// eslint-disable-next-line no-unused-vars
export const StyledQuestion = styled(({ color, ...rest }) => <Text {...rest} />)`
  text-align: center;
  margin-bottom: 20px;
  color: ${({ color }) => color};
`;

export const StyledText = styled(Text)`
  font-size: 15px;
`;
