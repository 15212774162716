import styled from 'styled-components';
import { WEBINAR_THEMES, Breakpoint } from 'shared';

export const StyledHeading = styled.p`
  text-align: center;
  color: ${WEBINAR_THEMES.DARK};
`;

export const StyledList = styled.ul`
  margin-top: 40px;
  padding: 0 40px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: ${Breakpoint.mobileL}px) {
    padding: 0;
  }
`;

export const StyledLi = styled.li`
  margin-right: 10px;

  a {
    color: ${WEBINAR_THEMES.RED};
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  svg {
    width: 65px;
  }
`;

export const StyledSpan = styled.span`
  margin-top: 10px;
`;
