import { useMemo } from 'react';
import { useAuth } from 'contexts/Auth';
import { ApiClient } from './client';

export const useApiClient = () => {
  const { token } = useAuth();
  const client = useMemo(() => {
    return new ApiClient(token);
  }, [token]);
  return client;
};
