import { strToHslColor } from 'shared';
import styled from 'styled-components';
import MUIAvatar from '@material-ui/core/Avatar';
import { WEBINAR_THEMES } from 'shared';

const getSize = (props) => props.size || 50;

export const StyledAvatar = styled(MUIAvatar)`
  &.MuiAvatar-colorDefault {
    background-color: ${({ color }) => (color ? color : WEBINAR_THEMES.LIGHT_BLUE)};
  }

  &.MuiAvatar-root {
    width: ${(props) => getSize(props)}px;
    height: ${(props) => getSize(props)}px;
    font-size: 14px;
  }
`;

export const Avatar = (props) => {
  const { children, ...rest } = props;

  const avatarProps = {
    children,
    color: children ? strToHslColor(children) : '',
    ...rest,
  };

  return <StyledAvatar {...avatarProps}>{children}</StyledAvatar>;
};
