import styled from 'styled-components';
import { WEBINAR_THEMES } from 'shared';

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
export const StyledSwitch = styled(({ appVariant, ...rest }) => (
  <input type="checkbox" {...rest} />
))`
  height: 1.5em;
  width: 2.5em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  flex-shrink: 0 !important;
  margin-right: 0.5rem !important;
  cursor: pointer;

  border-color: ${WEBINAR_THEMES.LIGHT_GREY};
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
  &:checked {
    background-color: ${({ theme, appVariant }) => theme.additionalColors[appVariant]};
    border-color: ${({ theme, appVariant }) => theme.additionalColors[appVariant]};
    background-position: right center;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  }
`;
