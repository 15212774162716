import styled from 'styled-components';
import { Breakpoint } from 'shared';

export const StyledMessages = styled.div`
  padding: 10px;
  position: relative;
  height: 100%;
  display: grid;
  grid-template-rows: 100%;
  padding: 0;

  @media screen and (max-width: ${Breakpoint.mobileL}px) {
    margin: 10px 20px 0 20px;
  }
`;

export const StyledBtnScroll = styled.div`
  position: fixed;
  bottom: 250px;
  right: 40px;
`;

export const StyledSpinnerContainer = styled.div<{ spinnerColor?: string }>`
  text-align: center;

  .MuiCircularProgress-colorPrimary {
    color: ${({ spinnerColor }) => spinnerColor};
  }
`;
