import Grow from '@material-ui/core/Grow';

/**
 * Description:
 *
 * Component for Grow
 */

export const GrowBase = (props) => <Grow {...props} />;

GrowBase.defaultProps = {
  in: true,
  mountOnEnter: true,
  unmountOnExit: true,
};
