import { FC, useMemo, useState } from 'react';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Zoom from '@material-ui/core/Zoom';
import { WEBINAR_THEMES } from 'shared';
import { usePopoverState } from 'components';
import {
  StyledLabelText,
  StyledPopover,
  StyledDropdownList,
  StyledDropdownItem,
  StyledDropdownText,
  StyledWrapper,
  StyledPollTypeText,
  StyledPollStatus,
  StyledPillWrapper,
  StyledPill,
  StyledTime,
  StyledAnswersBlock,
  StyledAvatarGroup,
  StyledAnswersNoVotesText,
  StyledConfettiBlock,
  StyledConfetti,
  StyledUserAvatar,
} from './singleSelection.styled';
import Dialog from '@material-ui/core/Dialog';
import { StyledQuestionText, StyledButtons } from '../polls.styled';
import { PollsParams, useAddPollAnswer, useDeletePoll, useUpdatePoll } from 'lib/api/poll';
import { useSendNotification } from 'lib/notifications';
import { useIsOrganizerOfCurrentWebinar } from 'lib/webinar';
import { Poll, PollOption } from 'models/Poll';
import { SingleSelectionOption } from './SingleSelectionOption';
import { useGetCurrentUser } from 'lib/api/user';
import Button from 'components/ui/Button';
import { User } from 'models/User';

const Options: React.FC<{
  webinarId: string;
  poll: Poll;
  pollsParams: PollsParams;
  openEditPollForm: () => unknown;
}> = ({ webinarId, poll, pollsParams, openEditPollForm }) => {
  const [anchorEl, handleOpenDropdown, handleCloseDropdown] = usePopoverState();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const { mutateAsync: updatePoll } = useUpdatePoll(webinarId, poll._id, pollsParams);
  const { mutateAsync: deletePoll } = useDeletePoll(webinarId, poll._id, pollsParams);

  const onDeletePoll = () => {
    deletePoll();
    setShowDeleteDialog(false);
  };

  return (
    <>
      <button onClick={handleOpenDropdown}>
        <span>&#8942;</span>
      </button>
      <StyledPopover open={!!anchorEl} anchorEl={anchorEl} onClose={handleCloseDropdown}>
        <StyledDropdownList>
          <StyledDropdownItem
            onClick={() => {
              updatePoll({ isPublished: !poll.isPublished });
              handleCloseDropdown();
            }}
          >
            <StyledDropdownText>
              {!poll.isPublished ? (
                <VisibilityIcon fontSize="small" />
              ) : (
                <VisibilityOffIcon fontSize="small" />
              )}
              <span>{poll.isPublished ? 'Unpublish' : 'Publish'}</span>
            </StyledDropdownText>
          </StyledDropdownItem>
          <StyledDropdownItem
            onClick={() => {
              openEditPollForm();
              handleCloseDropdown();
            }}
          >
            <StyledDropdownText>
              <EditIcon fontSize="small" />
              <span>Edit</span>
            </StyledDropdownText>
          </StyledDropdownItem>
          <StyledDropdownItem
            onClick={() => {
              setShowDeleteDialog(true);
              handleCloseDropdown();
            }}
          >
            <StyledDropdownText>
              <DeleteIcon fontSize="small" />
              <span>Delete</span>
            </StyledDropdownText>
          </StyledDropdownItem>
        </StyledDropdownList>
      </StyledPopover>
      <Dialog
        open={showDeleteDialog}
        TransitionComponent={Zoom}
        onClose={() => setShowDeleteDialog(false)}
        PaperProps={{
          style: {
            borderRadius: 20,
            padding: 40,
            width: 'auto',
          },
        }}
      >
        <div>
          <StyledQuestionText variant="bold">
            Are you sure you want to delete this poll?
          </StyledQuestionText>
          <StyledButtons>
            <Button variant="secondary" onClick={() => setShowDeleteDialog(false)}>
              Cancel
            </Button>
            <Button onClick={onDeletePoll}>Confirm</Button>
          </StyledButtons>
        </div>
      </Dialog>
    </>
  );
};

interface Props {
  webinarId: string;
  poll: Poll;
  pollsParams?: PollsParams;
  openEditPollForm?: () => unknown;
  openDeletePollDialog?: () => unknown;
}

export const SingleSelection: FC<Props> = ({ webinarId, poll, openEditPollForm, pollsParams }) => {
  const notification = useSendNotification();
  const isOrganizer = useIsOrganizerOfCurrentWebinar();
  const [fire, setFire] = useState(null);
  const [showConfetti, setShowConfetti] = useState(false);
  const [confettiClientY, setConfettiClientY] = useState(null);
  const { mutateAsync: createAnswer } = useAddPollAnswer(webinarId, poll._id, pollsParams);
  const { data: user } = useGetCurrentUser();

  const sortedAnswers = useMemo(() => {
    return poll.answers?.sort((a, b) => a.dateCreated.localeCompare(b.dateCreated)) ?? [];
  }, [poll.answers]);

  const hasUserAnswer = useMemo(() => {
    return poll.answers?.some((a) => (a.user as User)._id === user._id) ?? false;
  }, [poll.answers, user]);

  const handleOnVote = async (e: React.MouseEvent, option: PollOption) => {
    setShowConfetti(true);
    const clientY = e.clientY;
    setConfettiClientY(clientY);
    try {
      await createAnswer(option._id);
      setFire({});
    } catch (e) {
      notification.error({
        message: 'Error occurred while submitting your response',
      });
    }
  };

  return (
    <div>
      <StyledWrapper>
        <StyledLabelText>{poll.label}</StyledLabelText>
        {isOrganizer && (
          <Options
            webinarId={webinarId}
            poll={poll}
            pollsParams={pollsParams}
            openEditPollForm={openEditPollForm}
          />
        )}
      </StyledWrapper>
      <StyledPollTypeText>
        {isOrganizer && (
          <>
            <StyledPillWrapper>
              <StyledPollStatus>
                {poll.isPublished ? (
                  <StyledPill size="small" label="Published" />
                ) : (
                  <StyledPill size="small" label="Unpublished" theme={WEBINAR_THEMES.LIGHT_GREY} />
                )}
              </StyledPollStatus>
              <StyledPollStatus>
                {poll.isAcceptingAnswers ? (
                  <StyledPill size="small" label="Accepting answers" />
                ) : (
                  <StyledPill size="small" label="Not Accepting answers" />
                )}
              </StyledPollStatus>
            </StyledPillWrapper>
          </>
        )}
      </StyledPollTypeText>
      {poll.options.map((option) => (
        <SingleSelectionOption
          key={option._id}
          onVote={(e) => handleOnVote(e, option)}
          option={option}
          poll={poll}
          hasUserPollAnswer={hasUserAnswer}
        />
      ))}
      <StyledTime>
        Last updated: {formatDistanceToNow(new Date(poll.dateUpdated), { addSuffix: true })}
      </StyledTime>
      {poll.answersCount > 0 ? (
        <StyledAnswersBlock>
          <StyledAvatarGroup spacing={10} max={5}>
            {sortedAnswers.map(({ user }, index) => (
              <Zoom
                key={index}
                in
                mountOnEnter
                unmountOnExit
                style={{ transitionDelay: `${index * 150}ms` }}
              >
                <StyledUserAvatar key={(user as User)._id} src={(user as User).profileImageUrl} />
              </Zoom>
            ))}
          </StyledAvatarGroup>
        </StyledAnswersBlock>
      ) : (
        <StyledAnswersNoVotesText>No votes</StyledAnswersNoVotesText>
      )}
      {showConfetti && (
        <StyledConfettiBlock top={confettiClientY - 350}>
          <StyledConfetti fire={fire} particleCount={250} onDecay={() => setShowConfetti(false)} />
        </StyledConfettiBlock>
      )}
    </div>
  );
};
