import styled from 'styled-components';
import { TextEllipsis } from 'shared';

export const StyledAvatars = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: center;

  .MuiAvatar-root {
    height: 40px;
    width: 40px;
  }
`;

export const StyledTooltipName = styled.h1`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
`;

export const StyledTooltipRole = styled.p`
  ${TextEllipsis};
  max-width: 170px;
  margin: 5px 0 0 auto;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
`;
