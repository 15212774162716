import styled, { keyframes } from 'styled-components';

export const animate = keyframes`
  0%{
    width: 10.9px;
    height: 50px;
  }
  50%{
    width: 10.9px;
    height: 30px;
  }
  100%{
   width: 10.9px;
   height: 50px;
}
`;

export const StyledContainer = styled.div`
  position: relative;
  transform: scale(${({ size }) => size / 2});
`;

export const StyledBar = styled.div`
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;

  &:first-child {
    background: ${({ theme }) => theme};
    animation: ${animate} 1.1s infinite ease-in-out;
  }
  &:nth-child(2) {
    margin-left: 17px;
    background: ${({ theme }) => theme};
    animation: ${animate} 1.2s infinite ease-in-out;
  }
  &:nth-child(3) {
    margin-left: 35px;
    background: ${({ theme }) => theme};
    animation: ${animate} 1.3s infinite ease-in-out;
  }
  &:nth-child(4) {
    margin-left: 52px;
    background: ${({ theme }) => theme};
    animation: ${animate} 1.4s infinite ease-in-out;
  }
  &:nth-child(5) {
    margin-left: 69px;
    background: ${({ theme }) => theme};
    animation: ${animate} 1.5s infinite ease-in-out;
  }
  &:nth-child(6) {
    margin-left: 86px;
    background: ${({ theme }) => theme};
    animation: ${animate} 1.6s infinite ease-in-out;
  }
`;
