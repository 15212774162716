import styled from 'styled-components';
import { WEBINAR_THEMES } from 'shared';

export const StyledField = styled.div`
  width: 100%;
`;

export const StyledError = styled.p`
  font-size: 12px;
  margin: 10px 0 0 10px;
  color: ${WEBINAR_THEMES.RED};
`;
