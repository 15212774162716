import styled from 'styled-components';
import { hexToRGB, WEBINAR_THEMES, Flex } from 'shared';
import { Tabs } from 'components/ui';
import { StyledTitle } from 'components/ui/Tabs/tabs.styled';

const StyledChatWrapper = styled.section`
  height: 100%;

  .chatTabPanel {
    height: 100%;
  }

  & > div {
    height: 100%;
  }

  & > section:nth-child(2) {
    flex: auto;
    max-height: calc(100% - 170px);
    overflow: hidden;
  }

  .chatTabPanelWrapper {
    display: grid;
    grid-template-rows: auto 1fr;
  }

  .chatTabPanel {
    overflow-y: visible;
    padding: 0 1px;
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledChatTabs = styled(({ ...rest }) => <Tabs {...rest} />)`
  .MuiTabs-flexContainer {
    padding: 0;
  }

  .MuiTab-root {
    border-bottom: 1px solid ${hexToRGB(WEBINAR_THEMES.DARK_GREY, 0.1)};
  }

  .MuiTab-root ${StyledTitle} {
    margin-right: 5px;
  }

  .MuiTab-wrapper > div {
    ${Flex.fullCenter};
    flex-direction: row-reverse;
  }

  .MuiTab-wrapper > div > p:first-child {
    display: flex;
  }

  .MuiTab-root.Mui-selected ${StyledTitle} {
    position: relative;
    color: ${({ theme }) => theme.itemsColor};
  }

  & .MuiTabs-indicator {
    display: none;
  }

  .MuiTab-root.Mui-selected::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: 0;
    background-color: ${({ theme }) => theme.itemsColor};
  }

  .Mui-selected svg path {
    fill: ${({ theme }) => theme.itemsColor};
  }
`;

export { StyledChatWrapper, StyledChatTabs };
