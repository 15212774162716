import { useQuery } from '@tanstack/react-query';
import { useApiClient } from '.';
import { useAuth } from '../../contexts/Auth';
import { User } from '../../models/User';

export const useGetCurrentUser = () => {
  const auth = useAuth();
  const apiClient = useApiClient();
  return useQuery(
    ['current_user', auth.token],
    async () => {
      const { data } = await apiClient.get<{ data: { user: User } }>('/auth/me', {
        projections: 'city,country',
      });
      return data.user;
    },
    { enabled: !!auth.token },
  );
};
