import Zoom from '@material-ui/core/Zoom';

/**
 * Description:
 *
 * Component for Zoom
 */

export const ZoomBase = ({ forwardedRef, ...rest }) => <Zoom {...rest} ref={forwardedRef} />;

ZoomBase.defaultProps = {
  in: true,
  mountOnEnter: true,
  unmountOnExit: true,
};
