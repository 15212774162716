import { FC, useMemo } from 'react';
import { Select } from 'components/ui';
import { WebinarTheme } from 'lib/webinar';
import { useChatContext } from 'Webinar/ChatContext';

interface Props {
  theme: WebinarTheme;
}

export const ChatSelector: FC<Props> = ({ theme }) => {
  const { chatRooms, setCurrentChannel, currentChannel, unreadCounts } = useChatContext();

  const options = useMemo(
    () =>
      (chatRooms ?? []).map((room) => {
        let label = room.name;
        if (unreadCounts?.[room.channel]) {
          label += ` (${unreadCounts[room.channel]})`;
        }

        return { value: room.channel, label };
      }),
    [chatRooms, unreadCounts],
  );

  const value = useMemo(() => {
    const room = (chatRooms ?? []).find((room) => room.channel === currentChannel);
    if (!room) return;
    return { value: room.channel, label: room.name };
  }, [chatRooms, currentChannel]);

  return (
    <Select
      theme={theme}
      options={options}
      onChange={(option: { label: string; value: string }) => setCurrentChannel(option.value)}
      value={value}
    />
  );
};
