import styled from 'styled-components';
import { SHADOWS, WEBINAR_THEMES } from 'shared';
import { IconButton } from 'components';
import { StyledMessage, StyledText, StyledName } from '../Message/message.styled';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledButton = styled(({ backgroundColor, ...rest }) => <IconButton {...rest} />)<{
  backgroundColor?: string;
}>`
  &.MuiIconButton-root {
    padding: 0;
    font-size: 20px;
  }

  &.MuiIconButton-root:hover {
    background-color: transparent;

    svg path {
      fill: ${({ backgroundColor }) => backgroundColor};
      transition: 0.5s;
    }
  }

  &:last-child {
    margin-left: 5px;
  }
`;

const StyledWrapper = styled.ul<{ show: boolean; messageWidth: number }>`
  position: relative;
  box-shadow: ${SHADOWS.SMALL};
  padding: 5px 15px;
  background-color: ${WEBINAR_THEMES.LIGHT_WHITE};
  z-index: 10;

  & > li {
    margin: 0;
  }
  ${StyledMessage} {
    background-color: ${WEBINAR_THEMES.DARK_GREY};
  }
  ${StyledText} {
    color: ${WEBINAR_THEMES.WHITE};
    line-height: 1.2;
    ${({ show, messageWidth }) =>
      show &&
      messageWidth >= 264 &&
      `
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: all 1s ease-in-out;
      `};
  }

  ${StyledName} {
    max-width: 215px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const StyledControls = styled.div`
  position: absolute;
  top: 3px;
  right: 4px;
  font-size: 16px;
  display: flex;
  z-index: 1;
  & svg path {
    fill: ${WEBINAR_THEMES.GREY};
  }
`;

const StyledTitle = styled.div<{ textColor: string }>`
  color: ${({ textColor }) => textColor};
  font-size: 11px;
  display: flex;
  align-items: center;
  > svg {
    margin-right: 5px;
  }
`;

const StyledToggleWrapper = styled.div`
  position: absolute;
  top: 60px;
  right: 18px;
  z-index: 1;
`;

const StyledToggleArrow = styled.div<{ borderColor: string }>`
  cursor: pointer;
  display: flex;
  width: 8px;
  height: 8px;
  padding: 3px;
  border: solid ${WEBINAR_THEMES.GREY};
  border-width: 0 2px 2px 0;
  transition: all 0.5s;
  margin-right: 5px;
`;

export {
  StyledButton,
  StyledWrapper,
  StyledControls,
  StyledTitle,
  StyledToggleWrapper,
  StyledToggleArrow,
};
