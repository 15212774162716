import Skeleton from '@material-ui/lab/Skeleton';

/**
 * Description:
 *
 * Component for Skeleton
 */

export const SkeletonBase = (props) => <Skeleton {...props} />;

SkeletonBase.defaultProps = {
  animation: 'wave',
};
