import { WEBINAR_THEMES } from 'shared';

export const open = (itemColor = WEBINAR_THEMES.BLUE) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 16C0 7.16344 7.16344 0 16 0H32V32H16C7.16344 32 0 24.8366 0 16Z" fill="white" />
    <path
      d="M10.6464 15.6464C10.4512 15.8417 10.4512 16.1583 10.6464 16.3536L13.8284 19.5355C14.0237 19.7308 14.3403 19.7308 14.5355 19.5355C14.7308 19.3403 14.7308 19.0237 14.5355 18.8284L11.7071 16L14.5355 13.1716C14.7308 12.9763 14.7308 12.6597 14.5355 12.4645C14.3403 12.2692 14.0237 12.2692 13.8284 12.4645L10.6464 15.6464ZM21 15.5L11 15.5L11 16.5L21 16.5L21 15.5Z"
      fill={itemColor}
    />
  </svg>
);
