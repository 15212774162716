import styled from 'styled-components';
import { WEBINAR_THEMES } from 'shared';

export const StyledContainer = styled.div`
  padding-top: 8px;
  padding-right: 8px;
  display: grid;
  row-gap: 16px;
  grid-template-rows: 30px 1fr;
  padding-left: 10px;
`;

export const StyledForm = styled.form`
  display: grid;
  row-gap: 20px;
  font-size: 14px;
  .MuiInputBase-root,
  .MuiFormControlLabel-label {
    font-size: inherit;
  }
`;

export const StyledHelperText = styled.time`
  display: flex;
  font-weight: 500;
  font-size: 11px;
  font-family: 'Poppins', serif;
  margin-top: 30px;
  color: ${WEBINAR_THEMES.GREY};
`;
