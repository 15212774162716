import { Zoom } from 'components';
import { StyledTyping, StyledWrapper } from './typingIndicator.styled';
import { useGetCurrentEvent } from 'lib/api/event';
import { useWebinarTheme } from 'lib/webinar';
import { useChatContext } from 'Webinar/ChatContext';

export const TypingIndicator = () => {
  const { typingIndicatorForChannel, currentChannel } = useChatContext();
  const { data: webinar } = useGetCurrentEvent();
  const theme = useWebinarTheme(webinar);

  if (!typingIndicatorForChannel[currentChannel]) {
    return <></>;
  }

  return (
    <Zoom in>
      <StyledWrapper>
        <StyledTyping theme={theme.additionalColors.primaryColor}>
          {typingIndicatorForChannel[currentChannel].split(' ')[0]} is typing...
        </StyledTyping>
      </StyledWrapper>
    </Zoom>
  );
};
