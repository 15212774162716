import { useCallback, useState } from 'react';
import { StyledPopover } from './popoverBase.styled';

/**
 * Description:
 *
 * Component for Popover
 */

const usePopoverState = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpenPopup = useCallback((event) => setAnchorEl(event.currentTarget), [setAnchorEl]);
  const handleClosePopup = useCallback(() => setAnchorEl(null), [setAnchorEl]);

  return [anchorEl, handleOpenPopup, handleClosePopup, setAnchorEl];
};

const PopoverBase = ({ children, ...rest }) => <StyledPopover {...rest}>{children}</StyledPopover>;

PopoverBase.defaultProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
};

export { PopoverBase, usePopoverState };
