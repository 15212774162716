import { lazy, Suspense } from 'react';
import { AuthProvider } from 'contexts/Auth';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { GlobalStyle } from './GlobalStyle.styled';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import {
  CollapseLeftSidebarState,
  CollapseRightSidebarState,
  OpenRightSidebarTabState,
} from 'contexts';
import Webinar from './Webinar/Webinar';
const Stage = lazy(() => import('./Webinar/Stage/Stage'));
import { NotFound } from './Webinar/NotFound';
const Session = lazy(() => import('./Webinar/Session/Session'));
import { UserLoggedInProtected } from 'components/UserLoggedInProtected';
const Backstage = lazy(() => import('./Webinar/Backstage/Backstage'));
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { OrganizerOrSpeakerProtected } from './components/OrganizerOrSpeakerProtected';
import { HeadProvider } from 'react-head';

const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: 'webinars/:webinarUrlKey',
    element: (
      <UserLoggedInProtected>
        <Webinar />
      </UserLoggedInProtected>
    ),
    children: [
      {
        path: '',
        element: (
          <Suspense fallback={<></>}>
            <Stage />
          </Suspense>
        ),
      },
      {
        path: 'backstage/:backstageUrlKey',
        element: (
          <OrganizerOrSpeakerProtected>
            <Suspense fallback={<></>}>
              <Backstage />
            </Suspense>
          </OrganizerOrSpeakerProtected>
        ),
      },
      {
        path: 'backstage',
        element: (
          <OrganizerOrSpeakerProtected>
            <Suspense fallback={<></>}>
              <Backstage />
            </Suspense>
          </OrganizerOrSpeakerProtected>
        ),
      },
      {
        path: 'sessions/:sessionUrlKey',
        element: (
          <Suspense fallback={<></>}>
            <Session />
          </Suspense>
        ),
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
  {
    path: '*',
    element: <div>Not found</div>,
  },
]);

const App = () => (
  <HeadProvider>
    <AuthProvider>
      <GlobalStyle />
      <ToastContainer hideProgressBar toastClassName="app-toast" />
      <QueryClientProvider client={queryClient}>
        <Sentry.ErrorBoundary fallback={null}>
          <CollapseLeftSidebarState>
            <CollapseRightSidebarState>
              <OpenRightSidebarTabState>
                <RouterProvider router={router} />
              </OpenRightSidebarTabState>
            </CollapseRightSidebarState>
          </CollapseLeftSidebarState>
        </Sentry.ErrorBoundary>
        {process.env.REACT_APP_MODE === 'development' && <ReactQueryDevtools />}
      </QueryClientProvider>
    </AuthProvider>
  </HeadProvider>
);

export default App;
