import styled from 'styled-components';
import { Avatar } from 'components';

export const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledAvatar = styled(Avatar)`
  margin-right: 5px;
`;
