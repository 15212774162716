import { StyledTab } from './tab.styled';

/**
 * Description:
 *
 * Component for TabBase
 */

export const Tab = (props) => <StyledTab {...props} />;

Tab.defaultProps = {
  disableRipple: true,
};
