import styled from 'styled-components';
import { WEBINAR_THEMES } from 'shared';

export const StyledTyping = styled.div`
  text-align: center;
  font-weight: normal;
  font-size: 11px;
  height: 30px;
  line-height: 16px;
  color: ${({ theme }) => theme};
`;

export const StyledWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: 68px;
  background-color: ${WEBINAR_THEMES.WHITE};
`;
