export const PollTypes = {
  MultipleSelection: {
    value: 'MultipleSelection',
    label: 'Multiple selection',
  },
  SingleSelection: {
    value: 'SingleSelection',
    label: 'Single selection',
  },
  Rating: {
    value: 'Rating',
    label: 'Star rating',
  },
};
