import Pubnub from 'pubnub';
import { storageService } from './storageService';

class MyPubnub extends Pubnub {
  updateTimetoken(channelId, timetoken) {
    const timetokens = this.getTimetokens();
    timetokens[channelId] = timetoken || Date.now() + '0000';
    storageService.setItem('channelTimetokens', JSON.stringify(timetokens));
    return timetokens;
  }

  getTimetokens() {
    return JSON.parse(storageService.getItem('channelTimetokens')) || {};
  }

  getTimetoken(channelId) {
    return this.getTimetokens()[channelId] || '0';
  }

  unreadMessages(allChannels) {
    return new Promise((resolve) => {
      const channels = [];
      const channelTimetokens = [];
      const timetokens = this.getTimetokens();
      allChannels.forEach(({ id }) => {
        if (
          (id.startsWith('ev-c-') && !id.endsWith('.general-chat')) ||
          id.endsWith('.notifications')
        ) {
          channels.push(id);
          channelTimetokens.push(timetokens[id] || '1');
        }
      });
      if (!channels.length) {
        return resolve(allChannels);
      }
      this.messageCounts({ channels, channelTimetokens }, (status, results) => {
        if (status.error) {
          return resolve(allChannels);
        }
        const result = allChannels.map((channel) => ({
          ...channel,
          messages: results.channels[channel.id] || 0,
        }));
        resolve(result);
      });
    });
  }
}

const pubnubConfig = {
  subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY,
  publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY,
  restore: true,
  heartbeatInterval: 0,
  ssl: true,
  suppressLeaveEvents: true,
  uuid: "123"
};

export const pubnub = new MyPubnub(pubnubConfig);
