import { useEffect } from 'react';
import { chromeIcon, edgeIcon, firefoxIcon, safariIcon } from 'assets';
import { storageService } from 'services';
import { Dialog } from 'components';
import { StyledHeading, StyledLi, StyledList, StyledSpan } from './browserCompatibility.styled';

export const BrowserCompatibility = () => {
  useEffect(() => {
    const setShown = () => storageService.setItem('browserRecommendationsShown', 'true');
    window.addEventListener('beforeunload', setShown);
    return () => {
      window.removeEventListener('beforeunload', setShown);
      setShown();
    };
  }, []);

  return (
    <Dialog
      open
      PaperProps={{
        style: {
          padding: '40px 20px',
          width: '90%',
          display: 'flex',
          alignItems: 'center',
        },
      }}
    >
      <StyledHeading>
        For the best user experience, we recommend using one of the following browsers:
      </StyledHeading>
      <StyledList>
        <StyledLi>
          <a href="https://www.google.com/chrome" target="_blank" rel="noopener noreferrer">
            {chromeIcon()}
            <StyledSpan>Chrome</StyledSpan>
          </a>
        </StyledLi>
        <StyledLi>
          <a
            href="https://www.mozilla.org/en-US/firefox/new"
            target="_blank"
            rel="noopener noreferrer"
          >
            {firefoxIcon()}
            <StyledSpan>Firefox</StyledSpan>
          </a>
        </StyledLi>
        <StyledLi>
          <a
            href="https://developer.apple.com/safari/download"
            target="_blank"
            rel="noopener noreferrer"
          >
            {safariIcon()}
            <StyledSpan>Safari</StyledSpan>
          </a>
        </StyledLi>
        <StyledLi>
          <a href="https://www.microsoft.com/en-us/edge" target="_blank" rel="noopener noreferrer">
            {edgeIcon()}
            <StyledSpan>Edge</StyledSpan>
          </a>
        </StyledLi>
      </StyledList>
    </Dialog>
  );
};
