import * as yup from 'yup';

export const pollValidationSchema = yup.object({
  label: yup.string().required('Required'),
  type: yup.string().required(),
  options: yup
    .array(
      yup.object({
        label: yup.string().required('Required'),
      }),
    )
    .min(2),
  isPublished: yup.boolean(),
  isAcceptingAnswers: yup.boolean(),
});
