import styled from 'styled-components';

export const StyledMention = styled.span`
  display: inline;
  border: 1px solid transparent;
  white-space: nowrap;
  border-radius: 20px;
  padding: 2px 4px;
  font-weight: 600;
  cursor: default;
  margin-right: 0;
  word-break: break-word;
  
  ${({ theme, color }) => `
    background-color: ${theme};
    color: ${color};
  `}}
`;
