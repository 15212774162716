import styled from 'styled-components';
import Tab from '@material-ui/core/Tab';

export const StyledTab = styled(Tab)`
  &.MuiTab-root {
    font-family: Poppins, sans-serif;
    padding: 0;
    flex-grow: 1;
    min-width: max-content;
  }
`;
