export const openMobile = (itemsColor = '#FF4500') => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 20C0 8.95431 8.95431 0 20 0H40V40H20C8.95431 40 0 31.0457 0 20Z"
      fill={itemsColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26 14H14V15H26V14ZM26 19H14V20H26V19ZM14 24H26V25H14V24Z"
      fill="white"
    />
  </svg>
);
