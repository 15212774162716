import { createContext, FC, ReactNode, useContext } from 'react';
import { useChatMessages } from 'lib/api/chat';

type ChatContextType = ReturnType<typeof useChatMessages>;
const ChatContext = createContext<ChatContextType>({} as ChatContextType);

export const useChatContext = () => useContext(ChatContext);

export const ChatContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const chat = useChatMessages();
  return <ChatContext.Provider value={chat}>{children}</ChatContext.Provider>;
};
