import { StyledAvatarGroup } from './avatarGroup.styled';

/**
 * Description:
 *
 * Component for AvatarGroup
 */

export const AvatarGroup = ({ children, ...rest }) => {
  const avatarGroupProps = {
    children,
    ...rest,
  };

  return <StyledAvatarGroup {...avatarGroupProps}>{children}</StyledAvatarGroup>;
};
