import { useReducer } from 'react';
import { COLLAPSE_RIGHT_SIDEBAR } from './contextTypes';
import { CollapseRightSidebarContext } from './collapseRightSidebarContext';
import { collapseRightSidebarReducer } from './collapseRightSidebarReducer';
import { storageService } from 'services';

export const CollapseRightSidebarState = ({ children }) => {
  const [state, dispatch] = useReducer(
    collapseRightSidebarReducer,
    JSON.parse(storageService?.getItem('sidebarCollapsed') || false),
  );

  const setCollapseRightSidebar = (value) => {
    storageService.setItem('sidebarCollapsed', value);
    dispatch({ type: COLLAPSE_RIGHT_SIDEBAR, value });
  };

  return (
    <CollapseRightSidebarContext.Provider
      value={{
        collapsedRightSidebar: state,
        setCollapseRightSidebar,
      }}
    >
      {children}
    </CollapseRightSidebarContext.Provider>
  );
};
