import styled, { css } from 'styled-components';
import ReactCanvasConfetti from 'react-canvas-confetti';
import { Popover } from '@material-ui/core';
import { increaseWidth, WEBINAR_THEMES } from 'shared';
import { Avatar, AvatarGroup, Pill } from 'components';

export const StyledLabelText = styled.h1`
  font-weight: 600;
  font-size: 18px;
  font-family: 'Poppins', serif;
`;

export const StyledPopover = styled(Popover)``;

export const StyledDropdownList = styled.ul`
  width: 100px;
`;

export const StyledDropdownItem = styled.li`
  display: flex;
  align-items: center;
  height: 40px;
  padding: 10px 0 10px 10px;
  font-size: 12px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.additionalColors.primaryColor};
    color: ${WEBINAR_THEMES.WHITE};
  }
`;

export const StyledDropdownText = styled.p`
  height: 40px;
  padding: 10px 0;
  display: flex;
  align-items: center;

  > span {
    margin-left: 5px;
  }
`;

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const StyledPollTypeText = styled.div`
  display: flex;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 12px;
  margin-bottom: 10px;
  justify-content: space-between;
  color: ${WEBINAR_THEMES.GREY};
`;

export const StyledCreatedByWrapper = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 10px;
  color: ${WEBINAR_THEMES.GREY};
`;

export const StyledCreatedByInfo = styled.div`
  display: flex;
`;

export const StyledAvatar = styled(Avatar)`
  &.MuiAvatar-root {
    width: 30px;
    height: 30px;
  }
`;

export const StyledCreatedByNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 5px;
`;

export const StyledPollStatus = styled.div`
  margin-right: 5px;
`;

export const StyledPillWrapper = styled.div`
  display: flex;
`;

export const StyledPill = styled(Pill)`
  &.MuiChip-root {
    height: 16px;
    font-size: 10px;
    color: ${WEBINAR_THEMES.WHITE};
    background-color: ${({ theme }) => theme.itemsColor};
  }
`;

export const StyledTime = styled.time`
  display: flex;
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 10px;
  color: ${WEBINAR_THEMES.GREY};
  font-family: 'Poppins', serif;
`;

export const StyledButtonWrapper = styled.div<{ highlight?: boolean }>`
  margin-bottom: 10px;
  position: relative;
  border-radius: 12px;
  ${({ highlight, theme }) => {
    if (highlight) {
      return 'border: 2px solid ' + theme.additionalColors.primaryColor;
    }
  }}
`;

export const StyledAnswersBlock = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledAvatarGroup = styled(AvatarGroup)`
  &.MuiAvatarGroup-root {
    margin-left: 0;
  }

  .MuiAvatarGroup-avatar {
    font-size: 16px;
  }
`;

export const StyledUserAvatar = styled(Avatar)`
  &.MuiAvatar-root {
    width: 40px;
    height: 40px;
  }
`;

export const StyledAnswersNoVotesText = styled.p`
  margin-top: 20px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  color: ${WEBINAR_THEMES.GREY};
`;

export const StyledPercentageBlock = styled.div<{ answersPercentage?: number }>`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.additionalColors.primaryColor};
  width: ${({ answersPercentage }) => `${answersPercentage}%`};
  position: absolute;
  left: 0;
  border-radius: 5px;
  height: 100%;
  opacity: 0.5;
  color: ${WEBINAR_THEMES.WHITE};
  animation: ${({ answersPercentage }) =>
    css`
      ${increaseWidth(answersPercentage)} 2s
    `};

  > span {
    margin-left: 10px;
  }
`;

export const StyledConfettiBlock = styled.div<{ top: number }>`
  position: fixed;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: -1;
  top: ${({ top }) => `${top}px`};
`;

//@ts-expect-error
export const StyledConfetti = styled(ReactCanvasConfetti)`
  position: absolute;
  top: 0;
  right: 24px;
  z-index: -1;
  height: 100%;
  width: 300px;
`;
