import { Link } from 'react-router-dom';
import { useEventUrlKey } from 'shared';
import { Dialog } from 'components';
import { StyledCenter, StyledBox, StyledButton } from './notFound.styled';
import { FC } from 'react';

export const NotFound: FC<{ hideGoToWebinar?: boolean }> = ({ hideGoToWebinar }) => {
  const eventUrlKey = useEventUrlKey();

  return (
    <Dialog fullScreen open transitionDuration={0}>
      <StyledCenter>
        <StyledBox>
          <p>The page you have requested was not found</p>
          {!hideGoToWebinar && (
            <StyledButton>
              <Link to={`/webinars/${eventUrlKey}`}>Go to webinar</Link>
            </StyledButton>
          )}
        </StyledBox>
      </StyledCenter>
    </Dialog>
  );
};
