import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { pusher } from 'services';
import { WEBINAR_PUSHER_EVENTS } from 'shared';
import { useApiClient } from '.';
import { EventResource } from '../../models/Event';

const getKey = (webinarId: string) => ['webinar_resources', webinarId];

const useAddResourceToQuery = (webinarId: string) => {
  const queryClient = useQueryClient();
  return (resource: EventResource) => {
    queryClient.setQueryData<EventResource[]>(getKey(webinarId), (resources = []) => {
      if (!resources.find((r) => r._id === resource._id)) {
        resources = [...resources, resource];
      }
      return resources;
    });
  };
};

export const useGetResources = (webinarId: string) => {
  const apiClient = useApiClient();
  return useQuery(getKey(webinarId), async () => {
    const { data } = await apiClient.get<{ data: EventResource[] }>(
      `/webinars/${webinarId}/resources`,
    );
    return data;
  });
};

export const useResourcePusherEvents = (
  webinarId: string,
  options: {
    onNewResource: (resource: EventResource) => unknown;
  },
) => {
  const addResource = useAddResourceToQuery(webinarId);

  useEffect(() => {
    const channel = pusher.subscribe(`private-event-${webinarId}-resources`);
    type PusherEventData = { action: string; resources: EventResource[] };

    channel.bind(WEBINAR_PUSHER_EVENTS.RESOURCES_ENABLED, (data: PusherEventData) => {
      for (const resource of data.resources) {
        addResource(resource);
        options.onNewResource(resource);
      }
    });

    return () => {
      channel.unbind();
      channel.unsubscribe();
    };
  }, [webinarId, options, addResource]);
};
