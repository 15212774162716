import styled from 'styled-components';
import { WEBINAR_THEMES } from 'shared';
import { Skeleton } from 'components';

const StyledWrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  position: relative;
`;

const StyledMessageSkeletonWrapper = styled.section`
  position: absolute;
  top: 18%;
  width: 100%;
  flex: 1 1 auto;
`;

const StyledMessageSkeletons = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`;

const StyledMessageSkeleton = styled(Skeleton)`
  &.MuiSkeleton-root {
    width: 70%;
    height: 100px;
    border-radius: 20px 20px 0 20px;
    margin-left: auto;
  }
`;

const StyledFromMessageSkeleton = styled.div`
  display: flex;
  align-items: flex-end;

  & .MuiSkeleton-root:first-child {
    width: 28px;
    height: 28px;
    position: relative;
    bottom: 15px;
  }

  & .MuiSkeleton-root:last-child {
    width: 70%;
    border-radius: 20px 20px 20px 0;
    margin-left: 10px;
    height: 100px;
  }
`;

const StyledSelectorSkeleton = styled(Skeleton)`
  &.MuiSkeleton-root {
    position: absolute;
    margin: 20px;
    max-width: 260px;
    min-height: 45px;
    border-radius: 10px;
    width: 100%;
  }
`;


const StyledMessagesWrapper = styled.div`
  height: 20px;
  flex: 1 1 auto;
  background-color: ${WEBINAR_THEMES.LIGHT_WHITE};
  border-radius: 10px;
`;

export {
  StyledWrapper,
  StyledMessageSkeletonWrapper,
  StyledSelectorSkeleton,
  StyledMessageSkeletons,
  StyledMessageSkeleton,
  StyledFromMessageSkeleton,
  StyledMessagesWrapper,
};
