import { useMemo, useEffect, FC } from 'react';

interface Props {
  driftCode: string;
}

export const DriftWidgetHandler: FC<Props> = ({ driftCode }) => {
  const currentSource = useMemo(() => {
    if (!driftCode) return null;

    return driftCode.replace('<script>', '').replace('</script>', '');
  }, [driftCode]);

  useEffect(() => {
    const scriptElement = (() => {
      if (!currentSource) return null;

      const script = document.createElement('script');

      script.innerHTML = currentSource;
      script.async = true;

      document.head.appendChild(script);

      return script;
    })();

    return () => {
      if (scriptElement) document.head.removeChild(scriptElement);
    };
  }, [currentSource]);

  return null;
};
