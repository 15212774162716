import { Fragment, useCallback, useRef } from 'react';
import { useWindowSize } from 'shared';
import { ChatSelector } from './ChatSelector';
import { Grow } from 'components';
import {
  StyledWrapper,
  StyledMessageSkeletonWrapper,
  StyledMessageSkeletons,
  StyledMessageSkeleton,
  StyledSelectorSkeleton,
  StyledFromMessageSkeleton,
  StyledMessagesWrapper,
} from './chat.styled';
import { TypingIndicator } from './TypingIndicator';
import { Form } from './Form';
import { MessagesList } from './MessagesList';
import { useGetCurrentEvent } from 'lib/api/event';
import { useWebinarTheme } from 'lib/webinar';
import { PinnedMessage } from './MessagesList/PinnedMessage';
import { useChatContext } from 'Webinar/ChatContext';

export const Chat = () => {
  const { data: webinar } = useGetCurrentEvent();
  const theme = useWebinarTheme(webinar);

  const {
    isLoadingChatRooms,
    currentChannel,
    currentChannelMessages,
    fetchNextCurrentChannelMessages,
    isInitialLoadingCurrentChannelMessages,
    removeMessageFromCurrentChannel,
    setCurrentChannelMetadata,
    currentChannelPinnedMessage,
  } = useChatContext();

  const deviceHeight = useWindowSize('height');
  const onScrollRef = useRef(null);

  const onAfterSubmit = useCallback(() => {
    onScrollRef.current?.el.children[0].children[0].scroll(0, 0);
  }, []);

  if (isLoadingChatRooms) {
    return (
      <StyledWrapper>
        <StyledSelectorSkeleton />
        <StyledMessageSkeletonWrapper>
          {Array(deviceHeight > 812 ? 2 : 1)
            .fill(
              <StyledMessageSkeletons>
                <StyledFromMessageSkeleton>
                  <StyledMessageSkeleton />
                </StyledFromMessageSkeleton>
                <StyledMessageSkeleton />
              </StyledMessageSkeletons>,
            )
            .map((sk, ind) => (
              <Fragment key={ind}>{sk}</Fragment>
            ))}
        </StyledMessageSkeletonWrapper>
      </StyledWrapper>
    );
  }

  return (
    <StyledWrapper>
      <Grow in>
        <section>
          <ChatSelector theme={theme} />
        </section>
      </Grow>

      {currentChannel && (
        <>
          {currentChannelPinnedMessage && (
            <PinnedMessage
              message={currentChannelPinnedMessage}
              theme={theme}
              onUnpinMessage={() => setCurrentChannelMetadata({})}
            />
          )}
          <StyledMessagesWrapper>
            <MessagesList
              onPinMessage={(pinnedMessage) => setCurrentChannelMetadata({ pinnedMessage })}
              messagesPages={currentChannelMessages?.pages}
              onRequestLoadMore={fetchNextCurrentChannelMessages}
              isLoading={isInitialLoadingCurrentChannelMessages}
              onScrollRef={onScrollRef}
              onRemoveMessage={(timetoken) => removeMessageFromCurrentChannel(timetoken)}
              theme={theme}
            />
          </StyledMessagesWrapper>
        </>
      )}
      <TypingIndicator />
      <Grow>
        <section>
          <Form onAfterSubmit={onAfterSubmit} />
        </section>
      </Grow>
    </StyledWrapper>
  );
};
