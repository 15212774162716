import styled from 'styled-components';
import { Button as MDButton } from '@material-ui/core';
import { WEBINAR_THEMES } from 'shared';
import { Spinner } from '../Spinner';
import { WebinarTheme } from 'lib/webinar';
import { isColorTooDark } from 'lib/colors';

type Variant = 'primary' | 'secondary' | 'success' | 'danger';
type Size = 'sm' | 'md' | 'lg';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  size?: Size;
  variant?: Variant;
  disabled?: boolean;
  block?: boolean;
  text?: boolean;
}

function getBGColor(theme: WebinarTheme, variant: Variant = 'primary', text?: boolean) {
  if (text) {
    return 'transparent';
  }
  if (variant === 'primary') {
    return theme.additionalColors.primaryColor;
  }
  if (variant === 'secondary') {
    return theme.additionalColors.secondaryColor;
  }
  if (variant === 'success') {
    return WEBINAR_THEMES.GREEN;
  }
  if (variant === 'danger') {
    return WEBINAR_THEMES.RED;
  }
  return WEBINAR_THEMES.BLUE;
}

function getTextColor(theme: WebinarTheme, variant: Variant = 'primary', text?: boolean) {
  const bgColor = getBGColor(theme, variant);
  if (text) {
    return bgColor;
  }
  if (isColorTooDark(bgColor)) {
    return WEBINAR_THEMES.WHITE;
  }
  return WEBINAR_THEMES.BLACK;
}

function getFontSize(size: Size) {
  if (size === 'sm') return 12;
  if (size === 'lg') return 16;
  return 14;
}

function getPadding(size: Size) {
  if (size === 'sm') return '4px 8px';
  return '6px 16px';
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const StyledButton = styled(({ size, variant, block, text, ...rest }) => (
  <MDButton variant="contained" {...rest} />
))<React.PropsWithChildren<ButtonProps>>`
  &.MuiButton-root {
    ${({ block }) => {
      if (block) {
        return `
            display: block;
            width: 100%;`;
      }
    }};
    cursor: ${({ disabled, onClick }) => (!onClick || disabled ? 'default' : 'pointer')};
    text-transform: none;
    opacity: ${({ disabled }) => (disabled ? '0.5' : 1)};
    background-color: ${({ theme, variant, text }) => getBGColor(theme, variant, text)};
    color: ${({ theme, variant, text }) => getTextColor(theme, variant, text)};
    font-size: ${({ size }) => getFontSize(size)}px;
    padding: ${({ size }) => getPadding(size)};
    font-family: inherit;
    border-radius: 8px;
    box-shadow: none;
    transition: all 0.2s ease;
    &:hover {
      box-shadow: none;
      background-color: ${({ theme, variant }) => getBGColor(theme, variant)};
      color: ${({ theme, variant }) => getTextColor(theme, variant)};
      opacity: 0.8;
    }
  }
`;

interface Props extends ButtonProps {
  loading?: boolean;
}

export const Button: React.FC<React.PropsWithChildren<Props>> = ({
  loading,
  variant,
  size,
  disabled,
  children,
  ...rest
}) => {
  return (
    <StyledButton variant={variant} size={size} disabled={disabled || loading} {...rest}>
      {loading ? <Spinner /> : children}
    </StyledButton>
  );
};

export default Button;
