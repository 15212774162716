import { breakoutsSecondary } from 'assets/images/webinar/agenda';
import chevronDown from 'assets/images/webinar/agenda/chevron_down.png';
import { useGetCurrentWebinarSessions } from 'lib/api/session';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { WEBINAR_THEMES } from 'shared';
import styled from 'styled-components';
import SessionsList from './SessionsList';

const StyledTitle = styled.div`
  font-weight: 700;
  color: ${WEBINAR_THEMES.DARK};
  font-size: 14px;
`;

const StyledButton = styled.button`
  width: 100%;
  display: flex;
  justify-content: center;
  column-gap: 16px;
  align-items: center;
  padding: 15px;
  &:hover {
    svg path {
      fill: ${({ theme }) => theme.itemsColor};
      transition: 0.5s;
    }
  }
`;

const StyledIcon = styled.span``;

const RollUpContainer = styled.div<{ show?: boolean }>`
  position: absolute;
  left: 0;
  width: 100%;
  background: white;
  height: 90%;
  top: 10%;
  z-index: 20;
  transition: all 0.2s ease-out;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  box-shadow: 0px -16px 8px rgba(0, 0, 0, 0.08);
  transform: translateY(${({ show }) => (show ? '0' : 'calc(100% - 60px)')});
`;

const BreakoutSessions = () => {
  const [show, setShow] = useState(false);
  const { data: sessions } = useGetCurrentWebinarSessions();
  const location = useLocation();

  useEffect(() => {
    setShow(false);
  }, [location]);

  return (
    <RollUpContainer show={show}>
      <StyledButton onClick={() => setShow(!show)}>
        <StyledIcon>{breakoutsSecondary()}</StyledIcon>
        <StyledTitle>Breakout events</StyledTitle>
        <img
          src={chevronDown}
          alt={chevronDown}
          style={{ transition: 'transform 0.2s', transform: `rotate(${show ? 0 : 180}deg` }}
        />
      </StyledButton>
      {sessions && <SessionsList sessions={sessions} />}
    </RollUpContainer>
  );
};

export default BreakoutSessions;
