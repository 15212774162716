import { WEBINAR_THEMES } from 'shared';

export const close = (itemColor = WEBINAR_THEMES.BLUE) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle r="16" transform="matrix(-1 0 0 1 16 16)" fill="white" />
    <path
      d="M21.3536 15.6464C21.5488 15.8417 21.5488 16.1583 21.3536 16.3536L18.1716 19.5355C17.9763 19.7308 17.6597 19.7308 17.4645 19.5355C17.2692 19.3403 17.2692 19.0237 17.4645 18.8284L20.2929 16L17.4645 13.1716C17.2692 12.9763 17.2692 12.6597 17.4645 12.4645C17.6597 12.2692 17.9763 12.2692 18.1716 12.4645L21.3536 15.6464ZM11 15.5L21 15.5L21 16.5L11 16.5L11 15.5Z"
      fill={itemColor}
    />
  </svg>
);
