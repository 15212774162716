import Color from 'color';

export function isValidColorHexStr(colorHexStr: string) {
  if (!colorHexStr) return false;
  return /^#(?:[0-9a-fA-F]{3}){1,2}$/.test(colorHexStr);
}

export function isColorTooBright(colorHexStr: string) {
  if (!isValidColorHexStr(colorHexStr)) return;
  const color = Color(colorHexStr);
  return color.isLight();
}

export function isColorTooDark(colorHexStr: string) {
  if (!isValidColorHexStr(colorHexStr)) return;
  const color = Color(colorHexStr);
  return color.isDark();
}
