import styled from 'styled-components';
import { WEBINAR_THEMES, SHADOWS } from 'shared';
import { IconButtonBase as IconButton } from '../IconButtonBase';

export const StyledIconBtn = styled(IconButton)`
  &.MuiIconButton-root {
    width: 35px;
    height: 35px;
    background-color: ${WEBINAR_THEMES.WHITE};
    box-shadow: ${SHADOWS.DARK};
  }

  &.MuiIconButton-root:hover {
    background-color: ${WEBINAR_THEMES.WHITE};
    transition: 1s;
  }

  & svg > path {
    fill: ${({ theme }) => theme};
  }
`;
