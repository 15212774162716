const Breakpoint = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tablet: 768,
  laptop: 1024,
  laptopL: 1440,
  desktop: 2560,
};

const Device = {
  mobile: (width) => width <= Breakpoint.mobileL,
};

export { Breakpoint, Device };
