import styled from 'styled-components';
import { StyledAbsoluteCenter, WEBINAR_THEMES } from 'shared';
import Button from 'components/ui/Button';

export const StyledCenter = styled(StyledAbsoluteCenter)`
  flex-direction: column;
  padding: 50px;
`;

export const StyledBox = styled.div`
  text-align: center;
  margin-top: 20px;
`;

export const StyledButton = styled(Button)`
  &.MuiButton-root {
    margin-top: 20px;

    a {
      color: ${WEBINAR_THEMES.WHITE};
    }
  }
`;
