import { WEBINAR_THEMES } from 'shared';
import { StyledContainer, StyledRing, StyledCircle, StyledOfflineDot } from './onlineDot.styled';

export const OnlineDot = ({ online, size, theme, ...rest }) => (
  <>
    {online ? (
      <StyledContainer {...rest}>
        <StyledRing size={size} theme={theme} />
        <StyledCircle size={size} theme={theme} />
      </StyledContainer>
    ) : (
      <StyledOfflineDot size={size} {...rest} />
    )}
  </>
);

OnlineDot.defaultProps = {
  size: 25,
  online: false,
  theme: WEBINAR_THEMES.GREEN,
};
