import { Howl } from 'howler';
import { useMemo } from 'react';

const useSound = (sound: string) =>
  useMemo(() => {
    if (!sound) return;
    return new Howl({
      src: [sound],
    });
  }, [sound]);

export default useSound;
