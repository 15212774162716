import { useGetSessionPresence } from 'lib/api/presence';
import { useScheduleItemTime } from 'lib/scheduleItem';
import { Session } from 'models/Session';
import { getImageForDimensions, useEventUrlKey, WEBINAR_THEMES } from 'shared';
import styled from 'styled-components';
import emptySessionImage from 'assets/images/stage/empty-stage.png';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

const List = styled.div`
  padding: 16px 4px;
  display: grid;
  row-gap: 16px;
`;

const Item = styled.div`
  display: flex;
  padding: 16px 2px;
  column-gap: 16px;
  border: 1px solid ${WEBINAR_THEMES.GREY};
  border-radius: 8px;
  position: relative;
  background-color: white;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const ImageHolder = styled.div`
  flex-basis: 100px;
  flex-grow: 0;
  display: flex;
  align-items: center;
`;

const Image = styled.img`
  width: 100%;
  object-fit: contain;
  border-radius: 8px;
`;

const Content = styled.div`
  display: grid;
  row-gap: 8px;
`;

const Title = styled(Link)`
  font-size: 16px;
  font-weight: 600;
  color: ${WEBINAR_THEMES.BLACK};

  &::after {
    content: ' ';
    position: absolute;
    inset: 0;
  }
  &:visited {
    color: ${WEBINAR_THEMES.BLACK};
  }
`;

const SubContent = styled.div`
  display: grid;
  row-gap: 2px;
  font-size: 12px;
  font-weight: 400;
`;

const ParticipantsPromptHolder = styled.div`
  display: flex;
  column-gap: 4px;
`;

const StartsIn = styled.div`
  color: ${WEBINAR_THEMES.CORAL};
`;

const Over = styled.div`
  color: ${WEBINAR_THEMES.RED};
`;

const Live = styled.div`
  color: ${WEBINAR_THEMES.GREEN};
`;

const Duration = styled.div``;

const SessionTimePrompt: React.FunctionComponent<{ session: Session }> = ({ session }) => {
  const eventUrlKey = useEventUrlKey();
  const { isOver, isUpcoming, timeToStart } = useScheduleItemTime(session.scheduleItem);
  const { data: presence } = useGetSessionPresence(eventUrlKey, session?.urlKey);

  if (isUpcoming) {
    return <StartsIn>Starts in {timeToStart}</StartsIn>;
  }

  if (isOver) {
    return <Over>Ended</Over>;
  }

  return (
    <Live>
      Live now | {presence} Participant{presence === 1 ? '' : 's'}
    </Live>
  );
};

const SessionItem: React.FunctionComponent<{ session: Session }> = ({ session }) => {
  const eventUrlKey = useEventUrlKey();
  const imageUrl = getImageForDimensions(session.imageUrl, 800, 440) || emptySessionImage;
  const { duration, isUpcoming, startTime } = useScheduleItemTime(session.scheduleItem);

  return (
    <Item key={session._id}>
      <ImageHolder>
        <Image src={imageUrl} />
      </ImageHolder>
      <Content>
        <Title to={`/webinars/${eventUrlKey}/sessions/${session.urlKey}`}>{session.title}</Title>
        <SubContent>
          <ParticipantsPromptHolder>
            <SessionTimePrompt session={session} />
          </ParticipantsPromptHolder>
          <Duration>
            Start{isUpcoming ? 's' : 'ed'} at {format(startTime, 'p')}
          </Duration>
          <Duration>Duration {duration}</Duration>
        </SubContent>
      </Content>
    </Item>
  );
};

interface Props {
  sessions: Session[];
}

const SessionsList: React.FunctionComponent<Props> = ({ sessions }) => {
  return (
    <List>
      {sessions
        .sort((a, b) => {
          return (
            new Date(a?.scheduleItem?.startsAtUtc ?? Date.now()).getTime() -
            new Date(b?.scheduleItem?.startsAtUtc ?? Date.now()).getTime()
          );
        })
        .map((session) => (
          <SessionItem session={session} key={session._id} />
        ))}
    </List>
  );
};

export default SessionsList;
