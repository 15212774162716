import BasicTabs from '@material-ui/core/Tabs';
import { Tab } from './Tab';
import { TabPanel } from './TabPanel';
import { StyledIcon, StyledTitle } from './tabs.styled';

/**
 * Description:
 *
 * Component for Tabs
 */

const Tabs = ({ options, value, classNames, handleChange, disabled, ...rest }) => (
  <>
    <BasicTabs value={value} className={classNames?.tabs || ''} onChange={handleChange} {...rest}>
      {options.map(({ icon, title, id }, ind) => (
        <Tab
          key={ind}
          id={`tab-${id}`}
          disabled={disabled}
          label={
            <div>
              {icon && <StyledIcon>{icon()}</StyledIcon>}
              <StyledTitle>{title}</StyledTitle>
            </div>
          }
        />
      ))}
    </BasicTabs>
    {options.map(({ component }, ind) => (
      <TabPanel value={value} index={ind} key={ind} className={classNames?.tabPanel || ''}>
        {component()}
      </TabPanel>
    ))}
  </>
);

export { Tabs };
