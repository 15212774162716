import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

export const GlobalStyle = createGlobalStyle`
  ${normalize}
 
  // writing global styles here
  * {
    box-sizing: border-box;
  }
  
  body {
    padding: 0;
    font-family: 'Poppins',sans-serif;
    font-style: normal;
    font-weight: 600;
    height: 100vh;
  }
  
  #root {
    height: inherit;
  }  
  
  table {
    width: 100%;
    border-collapse: collapse; 
  }
  
  ul {
   list-style: none;
  }  
  
  ul, h1, p {
   margin: 0;
   padding: 0;
  }
  
  button {
   outline: none;
   border: none;
   background-color: transparent;
   cursor: pointer;
  }
  
  a {
    text-decoration: none;
  }
  
  a[href=''][target='_blank'] {
    pointer-events: none;
  }

  .MuiSkeleton-root {
    z-index: 1;
  }

  .opentok-hardware-setup-selector {
    margin-top: 10px;
    select {
      width: 100%;
    }
  }

  .app-toast {
    border-radius: 12px;
  }

`;
