import { FC, useMemo } from 'react';
import { Poll, PollOption } from 'models/Poll';
import { StyledButtonWrapper, StyledPercentageBlock } from './singleSelection.styled';
import CountUp from 'react-countup';
import { useGetCurrentUser } from 'lib/api/user';
import Button from 'components/ui/Button';

interface Props {
  poll: Poll;
  option: PollOption;
  onVote: (e: React.MouseEvent) => unknown;
  hasUserPollAnswer: boolean;
}

export const SingleSelectionOption: FC<Props> = ({ poll, option, onVote, hasUserPollAnswer }) => {
  const { data: user } = useGetCurrentUser();

  const optionAnswers = useMemo(() => {
    return poll.answers?.filter((a) => a.value === option._id) ?? [];
  }, [poll.answers, option]);

  const optionAnswersPercentage = useMemo(() => {
    return (optionAnswers.length / poll.answersCount) * 100;
  }, [optionAnswers, poll.answersCount]);

  const isUserAnsweredThisOption = useMemo(() => {
    return optionAnswers.some((a) => a.user._id === user._id);
  }, [optionAnswers, user]);

  return (
    <StyledButtonWrapper highlight={isUserAnsweredThisOption}>
      {hasUserPollAnswer && !!optionAnswersPercentage && (
        <StyledPercentageBlock answersPercentage={optionAnswersPercentage}>
          <CountUp start={0} end={+optionAnswersPercentage.toFixed()} duration={2} suffix="%" />
        </StyledPercentageBlock>
      )}
      <Button
        block
        disabled={!poll.isAcceptingAnswers || !poll.isPublished || hasUserPollAnswer}
        value={option.label}
        onClick={(e) => onVote(e)}
      >
        {option.label}
      </Button>
    </StyledButtonWrapper>
  );
};
