import { TextFieldProps } from '@material-ui/core';
import { StyledTextField } from './input.styled';

/**
 * Description:
 *
 * Component for input
 */
export const Input: React.FC<TextFieldProps> = (props) => <StyledTextField {...props} />;

Input.defaultProps = {
  autoComplete: 'off',
  variant: 'outlined',
};
