import styled from 'styled-components';
import { WEBINAR_THEMES, hexToRGB, Flex } from 'shared';
import { Text } from 'components/ui';

export const StyledList = styled.ul`
  margin-top: 10px;
  padding: 20px 0;
  overflow-y: scroll;
`;

export const StyledLi = styled.li`
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${hexToRGB(WEBINAR_THEMES.DARK_GREY, 0.1)};

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }
`;

export const StyledSkeletonWrapper = styled.div`
  position: absolute;
  width: calc(100% - 40px);
  overflow: hidden;
`;

export const StyledSkeletons = styled.div`
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 170px;
`;

export const StyledWrapper = styled.div`
  display: flex;
  align-items: center;

  & > .MuiSkeleton-root:first-child {
    margin-right: 10px;
  }
`;

export const StyledNoPollsWrapper = styled.div`
  margin-top: 100px;
  ${Flex.fullCenter};
  flex-direction: column;
`;

export const StyledNoPollsHeading = styled.h3`
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: ${WEBINAR_THEMES.DARK};
`;

export const StyledText = styled.p`
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color: ${WEBINAR_THEMES.GREY};
`;

export const StyledContainer = styled.div<{ withBottom?: boolean }>`
  display: grid;
  grid-template-rows: ${(props) => (props.withBottom ? '1fr 80px' : '1fr')};
`;

export const StyledButtons = styled.div`
  display: flex;
  margin-top: 30px;
  justify-content: center;
  column-gap: 16px;
`;

export const StyledQuestionText = styled(Text)`
  text-align: center;
`;

export const StyledCreatePollBlock = styled.div`
  padding-top: 15px;
  border-top: 1px solid ${hexToRGB(WEBINAR_THEMES.DARK_GREY, 0.1)};
`;
