import { FormControlLabel as MDFormControlLabel } from '@material-ui/core';
import styled from 'styled-components';
import { StyledSwitch } from './switch.styled';

const FormControlLabel = styled(MDFormControlLabel)`
  .MuiTypography-root {
    font-family: inherit;
  }
`;

export const ThemedSwitch = ({ label, ...props }) => (
  <FormControlLabel control={<StyledSwitch {...props} />} label={label} />
);
