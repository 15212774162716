import { useEffect, useMemo, useRef } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import ReactGA from 'react-ga';
import { pusher, storageService } from 'services';
import { BROWSER, WEBINAR_PUSHER_EVENTS } from 'shared';
import { BrowserCompatibility, UpdateDialog, DriftWidgetHandler } from 'components';
import { RightSidebar } from './RightSidebar';
import { Header } from './Header';
import { WebinarEndedDialog } from './WebinarEndedDialog';
import { StyledContainer, StyledContent, StyledMainContent } from './webinar.styled';
import { useGetCurrentEvent } from 'lib/api/event';
import { useIsOrganizerOfCurrentWebinar, useWebinarTheme } from 'lib/webinar';
import { useGetCurrentUser } from 'lib/api/user';
import { IntercomProvider } from 'react-use-intercom';
import { ThemeProvider } from 'styled-components';
import { useUserPilot } from 'services/userPilot';
import { useSendNotification } from 'lib/notifications';
import { NotFound } from './NotFound';
import { Title, Link } from 'react-head';
import { WebinarStartsSoonDialog } from './WebinarStartsSoonDialog';
import RedirectToRegistration from './RedirectToRegistration';
import { useSubscribeCurrentUserToWebinarPresence } from 'lib/api/presence';
import { ChatContextProvider } from './ChatContext';

const GA = {
  trackerId: {
    development: process.env.REACT_APP_CONFIG_TRACKER_ID || 'UA-164542762-4',
    staging: process.env.REACT_APP_CONFIG_TRACKER_ID || 'UA-164542762-5',
    production: process.env.REACT_APP_CONFIG_TRACKER_ID || 'UA-164542762-6',
  },
  trackerName: {
    organizer: 'organizer',
  },
};

const WebinarAfterLoad = () => {
  const notification = useSendNotification();
  const location = useLocation();
  const { data: webinar, refetch } = useGetCurrentEvent();
  const { data: user } = useGetCurrentUser();
  const theme = useWebinarTheme(webinar);
  const { boot: bootIntercom } = useIntercom();
  const hasSentNotification = useRef<boolean>(false);
  const isOrganizer = useIsOrganizerOfCurrentWebinar();
  useSubscribeCurrentUserToWebinarPresence(webinar.urlKey);


  useEffect(() => {
    if (!hasSentNotification.current) {
      notification.send({
        title: `Hi ${user.firstName}`,
        message: `Welcome to the ${webinar.title}`,
      });
      hasSentNotification.current = true;
    }
  }, [user, webinar, notification]);

  useEffect(() => {
    if (webinar.gaTrackingId) {
      ReactGA.initialize(webinar.gaTrackingId);
      ReactGA.pageview(location.pathname, [`${GA.trackerName.organizer}`]);
    }
  }, [webinar, location.pathname]);

  const showBrowserRecommendations = useMemo(
    () =>
      (!BROWSER.ALLOWED && !JSON.parse(storageService.getItem('browserRecommendationsShown'))) ||
      BROWSER.FORBIDDEN,
    [],
  );

  useEffect(() => {
    if (webinar.integrations?.intercomAppId) {
      bootIntercom();
    }
  }, [bootIntercom, webinar]);

  useEffect(() => {
    const channel = pusher.subscribe(`private-event-${webinar._id}-presence-user-${user._id}`);
    return () => {
      channel.unsubscribe();
    };
  }, [webinar, user]);

  useEffect(() => {
    const channel = pusher.subscribe(`private-event-${webinar._id}-user-${user._id}`);
    channel.bind(WEBINAR_PUSHER_EVENTS.ACCESS_BLOCKED, () => refetch());
    return () => {
      channel.unsubscribe();
    };
  }, [webinar, user, refetch]);

  return (
    <ThemeProvider theme={theme}>
      <ChatContextProvider>
        {webinar.integrations?.driftCode && (
          <DriftWidgetHandler driftCode={webinar.integrations?.driftCode} />
        )}
        <StyledContainer>
          <Header />
          <StyledContent>
            <StyledMainContent>
              <Outlet />
            </StyledMainContent>
            <RightSidebar itemsColor={theme.itemsColor} />
          </StyledContent>
        </StyledContainer>
        {!isOrganizer && (
          <>
            <WebinarStartsSoonDialog />
            <WebinarEndedDialog />
          </>
        )}
        <UpdateDialog />
        {showBrowserRecommendations && <BrowserCompatibility />}
      </ChatContextProvider>
    </ThemeProvider>
  );
};

const Webinar = () => {
  useUserPilot();
  const { data: webinar, isLoading } = useGetCurrentEvent();

  if (isLoading) {
    return <></>;
  }

  if (!webinar) {
    return <NotFound hideGoToWebinar />;
  }

  if (!webinar.eventAccess) {
    return <RedirectToRegistration />;
  }

  return (
    <IntercomProvider appId={webinar.integrations?.intercomAppId}>
      <Title>{webinar.title}</Title>
      <Link rel="icon" href={webinar.logoUrl ?? '/favicon.ico'} />
      <WebinarAfterLoad />
    </IntercomProvider>
  );
};

export default Webinar;
