import { useEffect } from 'react';
import { useGetCurrentUser } from 'lib/api/user';
import { getWebinarRegistrationUrl } from 'lib/webinar';
import { Mixpanel } from 'services';
import { MIXPANEL_EVENTS, useEventUrlKey } from 'shared';
import { useAuth } from '../contexts/Auth';

const RedirectToRegistrationPage = () => {
  const eventUrlKey = useEventUrlKey();
  useEffect(() => {
    Mixpanel.track(MIXPANEL_EVENTS.login.error);
    location.href = getWebinarRegistrationUrl(eventUrlKey);
  }, [eventUrlKey]);
  return <></>;
};

export const UserLoggedInProtected = ({ children }) => {
  const { data: user, isLoading, isError } = useGetCurrentUser();
  const { token, hasInitialLoadingDone } = useAuth();

  useEffect(() => {
    if (user) {
      Mixpanel.identify(user._id);
      Mixpanel.track(MIXPANEL_EVENTS.login.success);
    }
  }, [user]);
  if (hasInitialLoadingDone && !token) {
    return <RedirectToRegistrationPage />;
  }
  if (isLoading) {
    return <></>;
  }
  if (isError || !user) {
    return <RedirectToRegistrationPage />;
  }
  return <>{children}</>;
};
