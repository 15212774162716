import { useEffect, useState, useMemo, forwardRef, useRef } from 'react';
import { closeNotification as close } from 'assets';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import { Dialog, Zoom } from 'components';
import { useGetCurrentEvent } from 'lib/api/event';
import {
  StyledEventEndsHeading,
  StyledEventEndsText,
  StyledCloseCardButtonWrapper,
} from './webinar.styled';

export const WebinarEndedDialog = () => {
  const { data: webinar } = useGetCurrentEvent();
  const [showEventEndedWarning, setShowEventEndedWarning] = useState(false);
  const [showEventEndedMessage, setShowEventEndedMessage] = useState(false);
  const [now, setNow] = useState(Date.now());
  const warningWasShown = useRef(false);

  const TimeAllowedAfterEnd = 15;

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(Date.now());
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  });

  const minutesPassedSinceEnd = useMemo(
    () => differenceInMinutes(now, new Date(webinar.endsAtUtc)),
    [now, webinar],
  );

  useEffect(() => {
    if (minutesPassedSinceEnd > 0 && !warningWasShown.current) {
      setShowEventEndedWarning(true);
      warningWasShown.current = true;
    } else {
      setShowEventEndedWarning(false);
    }
  }, [minutesPassedSinceEnd, showEventEndedMessage]);

  useEffect(() => {
    if (minutesPassedSinceEnd >= TimeAllowedAfterEnd) {
      setShowEventEndedMessage(true);
    }
  }, [minutesPassedSinceEnd]);

  const ZoomTransition = useMemo(
    () => forwardRef((props, ref) => <Zoom forwardedRef={ref} {...props} />),
    [],
  );

  return (
    <>
      <Dialog
        open={showEventEndedMessage}
        TransitionComponent={ZoomTransition}
        PaperProps={{
          style: {
            padding: '40px 20px',
            width: '70%',
            display: 'flex',
            alignItems: 'center',
          },
        }}
      >
        <StyledEventEndsHeading>The webinar is over</StyledEventEndsHeading>
        <StyledEventEndsText>Thanks for attending!</StyledEventEndsText>
      </Dialog>
      <Dialog
        open={showEventEndedWarning}
        onClose={() => setShowEventEndedWarning(false)}
        TransitionComponent={ZoomTransition}
        PaperProps={{
          style: {
            padding: '40px 20px',
            width: '70%',
            display: 'flex',
            alignItems: 'center',
          },
        }}
      >
        <StyledCloseCardButtonWrapper>
          <button onClick={() => setShowEventEndedWarning(false)}>
            <span>{close()}</span>
          </button>
        </StyledCloseCardButtonWrapper>
        <StyledEventEndsHeading>The webinar has just ended</StyledEventEndsHeading>
        <StyledEventEndsText>
          But you can stick around for {TimeAllowedAfterEnd - minutesPassedSinceEnd} more minutes,
          before we wrap up.
        </StyledEventEndsText>
      </Dialog>
    </>
  );
};
