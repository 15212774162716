import { COLLAPSE_LEFT_SIDEBAR } from './contextTypes';

const handlers = {
  [COLLAPSE_LEFT_SIDEBAR]: (state, { value }) => value,
  DEFAULT: (state) => state,
};

export const collapseLeftSidebarReducer = (state, action) => {
  const handler = handlers[action.type] || handlers.DEFAULT;
  return handler(state, action);
};
