import styled from 'styled-components';
import { TextEllipsis, WEBINAR_THEMES, Flex, Breakpoint } from 'shared';

export const StyledWebinarHeader = styled.header`
  padding: 6px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
  background-color: ${WEBINAR_THEMES.WHITE};

  @media screen and (max-width: ${Breakpoint.tablet}px) {
    flex-wrap: wrap;
    height: auto;
  }
`;

export const StyledEventInfoBlock = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: ${Breakpoint.tablet}px) {
    width: 100%;
  }
`;

export const StyledHomeButton = styled.a`
  display: flex;
  padding: 0;
  color: inherit;
`;

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const StyledLogoImage = styled.img`
  height: 50px;
  margin-right: 24px;
  vertical-align: middle;
`;

export const StyledWebinarTitle = styled.p`
  ${TextEllipsis};
  font-weight: 700;
  font-style: normal;
  color: ${WEBINAR_THEMES.DARK};
  font-size: 16px;
  letter-spacing: 0;
  line-height: 1.3;
  text-align: left;
  margin-bottom: 3px;
  max-width: 410px;

  @media screen and (max-width: ${Breakpoint.tablet}px) {
    width: 100%;
    white-space: normal;
  }
`;

export const StyledLiveStatusText = styled.p`
  flex-shrink: 0;
  overflow: visible;
  font-weight: 500;
  font-style: normal;
  color: ${({ color }) => color};
  font-size: 13px;
  letter-spacing: 0;
  line-height: 1.3;
  text-align: left;
`;

export const StyledActionsButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledNotificationWrapper = styled.div`
  margin-right: 10px;
`;

export const StyledButtonsList = styled.ul`
  display: flex;
  margin: 15px 0;
`;

export const StyledItem = styled.li`
  min-width: 150px;
  height: 35px;
  background-color: ${({ theme }) => theme};
  border-radius: 15px;
  margin-right: 20px;
  ${Flex.fullCenter};
  color: ${WEBINAR_THEMES.WHITE};
  cursor: pointer;

  @media screen and (max-width: ${Breakpoint.mobileL}px) {
    min-width: 100px;
  }
`;

export const StyledLink = styled.a`
  color: ${WEBINAR_THEMES.WHITE};
`;

export const StyledToggleSidebarButton = styled.button`
  padding: 0;
  right: -20px;
  top: 0;
  position: relative;
`;

export const StyledLiveSpan = styled.span`
  flex-shrink: 0;
  overflow: visible;
  font-weight: 500;
  font-style: normal;
  color: ${WEBINAR_THEMES.GREEN};
  font-size: 13px;
  letter-spacing: 0;
  line-height: 1.3;
  text-align: left;
`;

export const StyledButtonP = styled.p`
  padding: 0 15px;

  @media screen and (max-width: ${Breakpoint.mobileL}px) {
    ${TextEllipsis};
    max-width: 100px;
  }
`;
