import { FC, useState, useCallback, useRef } from 'react';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import { emojiSmile } from 'assets/images/webinar/emojiSmile';
import { useClickOutside } from 'shared';
import { Dialog, StyledIconButton } from './EmojiInput.styled';

interface Props {
  itemsColor: string;
  value?: string;
  onSelection?: (value: string) => void;
}

const EmojiInput: FC<Props> = ({ value, onSelection, itemsColor }) => {
  const [showPicker, setPickerState] = useState(false);
  const picker = useRef(null);

  const dismissPicker = useCallback(() => setPickerState(false), [setPickerState]);

  useClickOutside([picker], dismissPicker);

  const addEmoji = (emoji) => {
    if ('native' in emoji) {
      onSelection(`${value}${emoji.native}`);
      dismissPicker();
    }
  };

  return (
    <div ref={picker}>
      <Dialog>
        {showPicker && <Picker emoji="" title="" native color={itemsColor} onSelect={addEmoji} />}
      </Dialog>
      <StyledIconButton onClick={() => setPickerState((value) => !value)}>
        {emojiSmile()}
      </StyledIconButton>
    </div>
  );
};

export { EmojiInput };
