import { IconButtonBase } from 'components/ui/IconButtonBase/IconButtonBase';
import DeleteIcon from '@material-ui/icons/Delete';
import { StyledOptionItem } from './PollForm.styled';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { Input } from 'components';

const PollOptionField = ({ option, index, formik, disabled, onRemoveClick }) => {
  const hasTouchedLabel =
    formik.touched.options && formik.touched.options[index] && formik.touched.options[index].label;
  const errorLabel =
    formik.errors.options && formik.errors.options[index] && formik.errors.options[index].label;
  return (
    <div key={option._id} draggable={!disabled}>
      <StyledOptionItem error={hasTouchedLabel && Boolean(errorLabel)} disableGutters>
        <div style={{ visibility: disabled ? 'hidden' : 'visible' }}>
          <DragIndicatorIcon />
        </div>
        <Input
          name={`options[${index}].label`}
          label={`Option ${index + 1}`}
          placeholder="Option"
          variant="primaryColor"
          value={option.label}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={hasTouchedLabel && Boolean(errorLabel)}
          helperText={hasTouchedLabel && errorLabel}
          disabled={disabled}
        />
        {formik.values.options.length > 2 && !disabled && (
          <IconButtonBase size="small" onClick={() => onRemoveClick(index)}>
            <DeleteIcon />
          </IconButtonBase>
        )}
      </StyledOptionItem>
    </div>
  );
};

export default PollOptionField;
