import { OPEN_RIGHT_SIDEBAR_TAB, OPEN_CHAT_TAB } from './contextTypes';

const handlers = {
  [OPEN_RIGHT_SIDEBAR_TAB]: (state, { value }) => value,
  [OPEN_CHAT_TAB]: (state, { value }) => value,
  DEFAULT: (state) => state,
};

export const openRightSidebarTabReducer = (state, action) => {
  const handler = handlers[action.type] || handlers.DEFAULT;
  return handler(state, action);
};
