import styled from 'styled-components';
import { WEBINAR_THEMES, SHADOWS } from 'shared';

export const StyledWrapper = styled.div`
  padding: 30px;
  background-color: ${WEBINAR_THEMES.WHITE};
  box-shadow: ${SHADOWS.DARK};
  text-align: center;
  max-width: 400px;
`;

export const StyledText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: ${WEBINAR_THEMES.DARK};
  margin-bottom: 30px;
`;

export const StyledButtons = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;

  > button:first-child {
    margin-right: 20px;
  }
`;
