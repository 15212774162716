import styled from 'styled-components';
import { Text } from 'components';
import { Flex } from 'shared';

export const StyledNoItems = styled.div`
  ${Flex.fullCenter};
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const StyledNoItemsText = styled(Text)`
  margin-top: 10px;
`;

export const StyledSkeletonsWrapper = styled.div`
  position: absolute;
  width: calc(100% - 50px);
  overflow: hidden;
  margin-top: 10px;
  margin-left: 10px;
`;

export const StyledSkeletonWrapper = styled.div`
  margin-bottom: 30px;
`;
