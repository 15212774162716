import styled from 'styled-components';
import { Breakpoint, WEBINAR_THEMES, TextEllipsis } from 'shared';
import { IconButton } from 'components';

const StyledMessageWrapper = styled.div<{ me?: boolean }>`
  position: relative;
  margin: 12px 0 15px;

  > div {
    ${({ me }) => me && 'margin-left: auto'}
  }
`;

const StyledControls = styled.div<{ me?: boolean }>`
  position: absolute;
  top: 7px;
  right: 7px;
  font-size: 16px;
  display: flex;
  visibility: hidden;
  & svg path {
    fill: ${({ me }) => (me ? WEBINAR_THEMES.WHITE : WEBINAR_THEMES.GREY)};
  }
`;

const StyledMessage = styled.div<{ me?: boolean; backgroundColor?: string }>`
  position: relative;
  padding: 10px 15px;
  margin: 0;
  background-color: ${({ backgroundColor }) => backgroundColor};
  display: flex;
  max-width: max-content;

  ${({ me }) =>
    me
      ? `border-radius: 10px 10px 0 10px;
    margin-left: 20px`
      : `border-radius: 10px 10px 10px 0;
  `};

  &:hover ${StyledControls} {
    visibility: visible;
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledButton = styled(({ backgroundColor, ...rest }) => <IconButton {...rest} />)<{
  backgroundColor?: string;
}>`
  &.MuiIconButton-root {
    padding: 0;
    font-size: 20px;
  }

  &.MuiIconButton-root:hover {
    background-color: transparent;
  }

  &:last-child {
    margin-left: 5px;
  }
`;

const StyledText = styled.p`
  color: ${({ color }) => color};
  word-break: break-word;
  white-space: break-spaces;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 1.2;
  display: inline;
  align-items: center;
`;

const StyledFileIcon = styled.img`
  width: 22px;
  margin-right: 5px;
`;

const StyledBlock = styled.div<{ me?: boolean }>`
  display: flex;
  margin-bottom: 5px;
  justify-content: flex-start;

  ${({ me }) => me && 'justify-content: flex-end;'}
`;

const StyledName = styled.p`
  font-weight: 700;
  font-style: normal;
  color: ${({ color }) => color};
  font-size: 12px;
  letter-spacing: 0;
  line-height: 1.2;
  margin-right: 5px;
  max-width: 60%;
  ${TextEllipsis};
`;

const StyledMyName = styled.span`
  font-weight: 700;
  font-style: normal;
  color: ${WEBINAR_THEMES.BLACK};
  font-size: 12px;
  letter-spacing: 0;
  line-height: 1.2;
  margin-left: 5px;
`;

const StyledTime = styled.p<{ me?: boolean }>`
  text-align: ${({ me }) => (me ? 'right' : 'left')};
  font-weight: 400;
  font-style: normal;
  color: ${WEBINAR_THEMES.MIDDLE_GREY};
  font-size: 12px;
  letter-spacing: 0;
  line-height: 1.2;
  white-space: nowrap;
`;

const StyledLinkButton = styled.button`
  text-align: left;
  padding: 0;
  line-height: initial;
  color: ${({ theme }) => theme};
`;

const StyledExpand = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${WEBINAR_THEMES.WHITE};
  font-size: 11px;
  font-weight: 600;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 30px);
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  background: linear-gradient(transparent, ${WEBINAR_THEMES.DARK_GREY} 50%);
  cursor: pointer;

  > svg {
    height: 20px;
    transform: scale(1.2);
  }
`;

const StyledCollapse = styled(StyledExpand)`
  position: relative;
`;

export {
  StyledMessageWrapper,
  StyledMessage,
  StyledButton,
  StyledControls,
  StyledText,
  StyledMyName,
  StyledTime,
  StyledFileIcon,
  StyledBlock,
  StyledName,
  StyledLinkButton,
  StyledExpand,
  StyledCollapse,
};
