import { FC, Fragment, useContext, useEffect, useRef, useState, LegacyRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { scrollTo, WEBINAR_THEMES } from 'shared';
import { CollapseRightSidebarContext } from 'contexts';
import { useDebouncedCallback } from 'services';
import { Grow, ScrollBottomButton, Slide, Spinner } from 'components';
import { Message } from './Message';
import { StyledMessages, StyledBtnScroll, StyledSpinnerContainer } from './messagesList.styled';
import { WebinarTheme } from 'lib/webinar';
import { AppMessage } from 'lib/api/chat';
import styled from 'styled-components';

const StyledCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const StyledNoMessageText = styled.p`
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: ${WEBINAR_THEMES.DARK_GREY};
  width: 100%;
  text-align: center;
  margin-top: 20px;
  flex: 1 1 auto;
`;

interface Props {
  messagesPages: AppMessage[][];
  isLoading: boolean;
  onScrollRef: LegacyRef<InfiniteScroll>;
  theme: WebinarTheme;
  onRemoveMessage: (timetoken: string) => unknown;
  onPinMessage: (timetoken: string) => unknown;
  onRequestLoadMore: () => unknown;
}

export const MessagesList: FC<Props> = ({
  messagesPages,
  theme,
  isLoading,
  onScrollRef,
  onRequestLoadMore,
  onRemoveMessage,
  onPinMessage,
}) => {
  const [showScrollDownBtn, setShowScrollDownBtn] = useState(false);
  const { collapsedRightSidebar } = useContext(CollapseRightSidebarContext);
  const downTargetRef = useRef(null);
  const listRef = useRef(null);

  const scrollHandler = useDebouncedCallback(
    (current) =>
      setShowScrollDownBtn(current.scrollTop < current.scrollHeight - current.offsetHeight - 200),
    50,
  );

  useEffect(() => {
    if (listRef?.current) {
      const { current } = listRef;
      current.addEventListener('scroll', scrollHandler.bind(null, current));
      return () => {
        current.removeEventListener('scroll', scrollHandler.bind(null, current));
      };
    }
  }, [scrollHandler]);

  if (isLoading) {
    return (
      <StyledSpinnerContainer spinnerColor={theme.itemsColor}>
        <Spinner />
      </StyledSpinnerContainer>
    );
  }

  if (!messagesPages || messagesPages.length === 0 || messagesPages[0].length === 0) {
    return (
      <Grow>
        <StyledCenter>
          <StyledNoMessageText>No messages yet</StyledNoMessageText>
        </StyledCenter>
      </Grow>
    );
  }

  return (
    <StyledMessages ref={listRef} id="scrollableDiv">
      <InfiniteScroll
        hasMore
        inverse
        ref={onScrollRef as LegacyRef<InfiniteScroll>}
        dataLength={messagesPages?.length}
        next={onRequestLoadMore}
        scrollableTarget="scrollableDiv"
        style={{
          display: 'flex',
          height: '100%',
          flexDirection: 'column-reverse',
          padding: '0 8px',
        }}
        loader={<Spinner />}
      >
        {messagesPages.map((page, index) => (
          <Fragment key={index}>
            {page.map((message) => (
              <Message
                key={message.timetoken}
                theme={theme}
                message={message}
                onRemoveMessage={() => onRemoveMessage(message.timetoken)}
                onPinMessage={() => onPinMessage(message.timetoken)}
              />
            ))}
          </Fragment>
        ))}
      </InfiniteScroll>

      <div ref={downTargetRef} />

      <Slide in={showScrollDownBtn && !collapsedRightSidebar}>
        <StyledBtnScroll>
          <ScrollBottomButton
            theme={theme.itemsColor}
            onScrollToBottom={() => scrollTo(downTargetRef, { behavior: 'smooth' })}
          />
        </StyledBtnScroll>
      </Slide>
    </StyledMessages>
  );
};
