import { useQuery } from '@tanstack/react-query';
import { useApiClient } from '.';
import { Event } from '../../models/Event';
import { useEventUrlKey } from '../../shared';

const getKeyForURLKey = (urlKey: string) => ['event', urlKey];

export const useGetEventForURLKey = (urlKey: string) => {
  const apiClient = useApiClient();
  return useQuery(
    getKeyForURLKey(urlKey),
    async () => {
      const { data } = await apiClient.get<{ data: { event: Event } }>(`/events/${urlKey}`);
      return data.event;
    },
    { enabled: !!urlKey },
  );
};

export const useGetCurrentEvent = () => {
  const eventUrlKey = useEventUrlKey();
  return useGetEventForURLKey(eventUrlKey);
};
