import { FC, useEffect } from 'react';
import { useEventUrlKey } from 'shared';

const RedirectToRegistration: FC = () => {
  const eventUrlKey = useEventUrlKey();
  useEffect(() => {
    window.location.href = process.env.REACT_APP_WEB_URL + '/webinars/' + eventUrlKey;
  }, [eventUrlKey]);

  return <></>;
};

export default RedirectToRegistration;
