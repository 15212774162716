import { forwardRef, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { VersionUpdatesUtil, EVENT_TIMERS } from 'shared';
import { Dialog, Zoom } from 'components';
import { StyledWrapper, StyledText, StyledButtons } from './updateDialog.styled';

import Button from '../Button';

const versioningTimer = new VersionUpdatesUtil.ActivityBasedTimer();

export const UpdateDialog = () => {
  const [open, setOpen] = useState(false);
  const location = useLocation();

  const ZoomTransition = useMemo(
    () => forwardRef((props, ref) => <Zoom forwardedRef={ref} {...props} />),
    [],
  );

  useEffect(() => {
    VersionUpdatesUtil.isNewerVersionAvailable();
    versioningTimer.setInterval({
      async callback() {
        const newVersionAvailable = await VersionUpdatesUtil.isNewerVersionAvailable();

        if (!newVersionAvailable) {
          return;
        }

        setOpen(true);
      },
      interval: EVENT_TIMERS.checkForUpdateInMs,
      forcedInterval: EVENT_TIMERS.forcedCheckForUpdateInMs,
    });
  }, []);
  useEffect(() => versioningTimer.runTimersCheck(), [location]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      TransitionComponent={ZoomTransition}
      PaperProps={{
        style: { borderRadius: 20 },
      }}
    >
      <StyledWrapper>
        <StyledText>
          An update is ready, please reload the page or click to load the new version
        </StyledText>
        <StyledButtons>
          <Button variant="secondary" onClick={() => setOpen(false)}>
            Close
          </Button>
          <Button onClick={() => window.location.reload()}>Update</Button>
        </StyledButtons>
      </StyledWrapper>
    </Dialog>
  );
};
