import { useReducer } from 'react';
import { OPEN_RIGHT_SIDEBAR_TAB, OPEN_CHAT_TAB } from './contextTypes';
import { OpenRightSidebarTabContext } from './openRightSidebarTabContext';
import { openRightSidebarTabReducer } from './openRightSidebarTabReducer';

export const OpenRightSidebarTabState = ({ children }) => {
  const [state, dispatch] = useReducer(openRightSidebarTabReducer, 0);
  const [activeChatTab, setActiveChatTab] = useReducer(openRightSidebarTabReducer, 0);

  const openRightSidebarTab = (sidebarTab) =>
    dispatch({ type: OPEN_RIGHT_SIDEBAR_TAB, value: sidebarTab });

  const openChatTab = (chatTab) => setActiveChatTab({ type: OPEN_CHAT_TAB, value: chatTab });

  return (
    <OpenRightSidebarTabContext.Provider
      value={{
        openedRightSidebarTab: state,
        openRightSidebarTab,
        chatTab: {
          activeChatTab,
          openChatTab,
        },
      }}
    >
      {children}
    </OpenRightSidebarTabContext.Provider>
  );
};
