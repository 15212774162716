import { render } from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ExtraErrorData } from '@sentry/integrations';
import App from './App';

if (process.env.REACT_APP_MODE !== 'development') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing(), new ExtraErrorData()],
    tracesSampleRate: process.env.REACT_APP_MODE === 'production' ? 0.2 : 1.0,
    environment: process.env.REACT_APP_MODE,
    ignoreErrors: [
      'Non-Error promise rejection captured with keys: __sentry_xhr__',
      'Non-Error promise rejection captured with keys: request, status',
      'Non-Error promise rejection captured with keys: config, data, headers, request, status',
      'Non-Error promise rejection captured with keys: __sentry_xhr__, klIsCORSRequest',
      'Cannot get _domAudioOnlyVideoElement, NativeVideoElementWrapper is destroyed',
      'AbortError: The play() request was interrupted because the media was removed from the document.',
      /@opentok\/client/,
    ],
    beforeSend(event, hint) {
      const isNonErrorException =
        event.exception?.values?.[0]?.value?.startsWith('Non-Error') ||
        (hint.originalException as Error)?.message?.startsWith('Non-Error');

      if (isNonErrorException) {
        return null;
      }
      return event;
    },
  });
}

render(<App />, document.getElementById('root'));
