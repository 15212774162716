import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useApiClient } from '.';
import { pusher } from '../../services';
import { EVENT_TIMERS } from '../../shared';
import { useGetCurrentUser } from './user';

export const useGetPresence = (eventUrlKey: string, pattern: string) => {
  const apiClient = useApiClient();
  return useQuery(
    ['event', eventUrlKey, 'presence', pattern],
    async () => {
      const { data } = await apiClient.get<{ data: { presence: number } }>(
        `/events/${eventUrlKey}/presence`,
        { pattern },
      );
      return data.presence;
    },
    { refetchInterval: EVENT_TIMERS.viewersInterval },
  );
};

export const useGetStagePresence = (eventUrlKey: string, stageUrlKey: string) => {
  return useGetPresence(eventUrlKey, `private-stage-${stageUrlKey}-presence-`);
};

export const useGetEventPresence = (eventUrlKey: string) => {
  return useGetPresence(eventUrlKey, `private-stage-${eventUrlKey}-presence-`);
};

export const useGetSessionPresence = (eventUrlKey: string, sessionUrlKey: string) => {
  return useGetPresence(eventUrlKey, `private-session-${sessionUrlKey}-presence-`);
};

export const useSubscribeCurrentUserToWebinarPresence = (eventUrlKey: string) => {
  const { data: user } = useGetCurrentUser();
  useEffect(() => {
    const channel = pusher.subscribe(`private-stage-${eventUrlKey}-presence-${user._id}`);
    return () => {
      channel.unsubscribe();
    };
  }, [user, eventUrlKey]);
};

export const useSubscribeCurrentUserToStagePresence = (stageUrlKey: string) => {
  const { data: user } = useGetCurrentUser();
  useEffect(() => {
    const channel = pusher.subscribe(`private-stage-${stageUrlKey}-presence-${user._id}`);
    return () => {
      channel.unsubscribe();
    };
  }, [user, stageUrlKey]);
};

export const useSubscribeCurrentUserToSessionPresence = (sessionUrlKey: string) => {
  const { data: user } = useGetCurrentUser();
  useEffect(() => {
    const channel = pusher.subscribe(`private-session-${sessionUrlKey}-presence-${user._id}`);
    return () => {
      channel.unsubscribe();
    };
  }, [user, sessionUrlKey]);
};
