import styled from 'styled-components';
import { Badge } from '@material-ui/core';
import { WEBINAR_THEMES, Breakpoint, hexToRGB } from 'shared';
import { Popover, Text } from 'components';
import { IconButtonBase } from 'components/ui/IconButtonBase';

export const StyledBadge = styled(Badge)`
  & .MuiBadge-badge {
    background-color: ${WEBINAR_THEMES.RED};
    top: 4px;
    right: 6px;
  }
`;

export const StyledIconButton = styled(IconButtonBase)`
  &.MuiIconButton-root:hover {
    background-color: transparent;
  }
`;

export const StyledBellImage = styled.img`
  width: 20px;
  height: 20px;
`;

export const StyledPopover = styled(Popover)`
  .MuiPopover-paper {
    width: 300px;
  }

  @media screen and (max-width: ${Breakpoint.mobileL}px) {
    .MuiPopover-paper {
      max-width: 90%;
    }
  }
`;

export const StyledSettings = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  padding-left: 30px;
`;

export const StyledLabel = styled(Text)`
  color: ${({ theme }) => theme};
  font-size: 12px;
`;

export const StyledEmptyText = styled(Text)`
  border-top: 1px solid ${hexToRGB(WEBINAR_THEMES.DARK_GREY, 0.1)};
  padding: 10px;
  text-align: center;
`;
