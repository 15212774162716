import styled, { css } from 'styled-components';

/** @description
 * Box Shadow shared Object
 * **/
const SHADOWS = {
  DARK: '5px 0 50px rgba(0, 0, 0, 0.12)',
  SMALL: '0px 1px 0px rgba(0, 0, 0, 0.05)',
  BLACK: '0 0 4px rgba(0, 0, 0, 0.4)',
};

/** @description
 * Shared Flex Object
 * **/
const Flex = {
  fullCenter: css`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  fullCenterColumn: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
};

const StyledAbsoluteCenter = styled.div`
  ${Flex.fullCenter};
  position: absolute;
  width: 100%;
  height: 100%;
`;

/** Text Overflow **/
/*
- Don't forget to provide a max-width,
  because it will not work without it
 */

const TextEllipsis = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
/** **/

export { SHADOWS, Flex, StyledAbsoluteCenter, TextEllipsis };
