import { Slide } from 'components';
import { EventResource } from 'models/Event';
import { ResourceCard } from './ResourceCard';

export const List = ({ resources }: { resources: EventResource[] }) => (
  <ul>
    {resources.map((resource, index) => (
      <Slide
        key={resource._id}
        direction={`${index % 2 === 0 ? 'right' : 'left'}`}
        timeout={{
          enter: 500,
        }}
      >
        <div>
          <ResourceCard {...resource} />
        </div>
      </Slide>
    ))}
  </ul>
);
