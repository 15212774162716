import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import styled from 'styled-components';

const StyledDialog = styled(Dialog)<{ bgBlur?: boolean }>`
  .MuiPaper-root {
    overflow: visible;
  }
  .MuiBackdrop-root {
    ${({ bgBlur }) => bgBlur && 'backdrop-filter: blur(10px);'}
  }
`;

const BaseDialog: React.FC<DialogProps & { bgBlur?: boolean }> = (props) => {
  const { children, ...rest } = props;

  const DialogProps = {
    children,
    ...rest,
  };

  return <StyledDialog {...DialogProps}>{children}</StyledDialog>;
};

export { BaseDialog };
