import { useState } from 'react';
import { useGetQuestionsForPage, useQuestionParams } from 'lib/api/qa';
import { useGetCurrentEvent } from 'lib/api/event';
import { useGetCurrentSession } from 'lib/api/session';
import { useIsOrganizerOfCurrentWebinar, useWebinarTheme } from 'lib/webinar';
import { Grow, Skeleton, Slide } from 'components';
import { Question } from './Question';
import { QuestionForm } from './QuestionForm';
import {
  StyledContainer,
  StyledList,
  StyledLi,
  StyledSkeletons,
  StyledContent,
  StyledSkeletonCircle,
  StyledSkeletonWrapper,
  StyledWrapper,
  StyledCreateBlock,
  StyledNoQuestionsWrapper,
  StyledNoQuestionsHeading,
  StyledText,
} from './q&a.styled';
import Button from 'components/ui/Button';

export const QA = () => {
  const [showQuestionForm, setShowQuestionForm] = useState(false);
  const isOrganizer = useIsOrganizerOfCurrentWebinar();
  const { data: webinar } = useGetCurrentEvent();
  const webinarId = webinar?._id;
  const { data: session } = useGetCurrentSession();
  const sessionId = session?._id;

  const { additionalColors } = useWebinarTheme(webinar);
  const questionsParams = useQuestionParams();

  const { data: questions, isLoading } = useGetQuestionsForPage();

  const CreateQuestionButton = (
    <StyledCreateBlock>
      <Button block onClick={() => setShowQuestionForm(true)}>
        Create a question
      </Button>
    </StyledCreateBlock>
  );

  if (showQuestionForm) {
    return (
      <QuestionForm
        webinarId={webinarId}
        onRequestClose={() => setShowQuestionForm(false)}
        sessionId={sessionId}
        questionsParams={questionsParams}
      />
    );
  }

  if (isLoading) {
    return (
      <StyledContainer>
        <Grow in={true} timeout={300}>
          <StyledSkeletonWrapper>
            {Array(3)
              .fill(1)
              .map((_, ind) => (
                <StyledSkeletons key={ind}>
                  <StyledContent>
                    <StyledSkeletonCircle variant="circle" />
                    <div style={{ width: '100%' }}>
                      <Skeleton
                        variant="rect"
                        height={10}
                        width="30%"
                        style={{ borderRadius: '10px', marginBottom: '5px' }}
                      />
                      <Skeleton
                        variant="rect"
                        height={10}
                        width="20%"
                        style={{ borderRadius: '10px' }}
                      />
                    </div>
                  </StyledContent>
                  <StyledWrapper>
                    <Skeleton
                      variant="rect"
                      height={15}
                      width="20%"
                      style={{ borderRadius: '10px' }}
                    />
                    <Skeleton
                      variant="rect"
                      height={15}
                      width="20%"
                      style={{ borderRadius: '10px' }}
                    />
                  </StyledWrapper>
                  <Skeleton
                    variant="rect"
                    height={25}
                    width="100%"
                    style={{ borderRadius: '4px' }}
                  />
                </StyledSkeletons>
              ))}
          </StyledSkeletonWrapper>
        </Grow>
      </StyledContainer>
    );
  }

  if (!questions || questions.length === 0) {
    return (
      <StyledContainer>
        <StyledNoQuestionsWrapper>
          <StyledNoQuestionsHeading>No questions yet</StyledNoQuestionsHeading>
          <StyledText>
            {isOrganizer ? 'No questions submitted' : 'Ask the first question'}
          </StyledText>
        </StyledNoQuestionsWrapper>
        {CreateQuestionButton}
      </StyledContainer>
    );
  }

  return (
    <StyledContainer>
      <StyledList>
        {questions.map((question, index) => (
          <Slide
            key={question._id}
            direction={`${index % 2 === 0 ? 'right' : 'left'}`}
            timeout={{
              enter: 300,
            }}
          >
            <StyledLi>
              <Question
                question={question}
                isOrganizer={isOrganizer}
                theme={additionalColors}
                webinarId={webinarId}
                questionsParams={questionsParams}
              />
            </StyledLi>
          </Slide>
        ))}
      </StyledList>

      {CreateQuestionButton}
    </StyledContainer>
  );
};
