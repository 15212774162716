import styled from 'styled-components';
import { WEBINAR_THEMES, hexToRGB } from 'shared';

interface StyledInputProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  error?: string | boolean;
  variant: string;
}

export const StyledInput = styled.input<StyledInputProps>`
  display: block;
  border-radius: 12px;
  width: ${(props) => (props.width ? props.width : '100%')};
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: ${WEBINAR_THEMES.DARK};
  z-index: 2;
  background-color: ${WEBINAR_THEMES.WHITE};
  background-clip: padding-box;
  border: 1px solid ${({ error }) => (error ? WEBINAR_THEMES.RED : WEBINAR_THEMES.LIGHTER_GREY)};
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:focus {
    color: ${WEBINAR_THEMES.DARK};
    background-color: ${WEBINAR_THEMES.WHITE};
    border-color: ${({ theme, variant, error }) =>
      error ? WEBINAR_THEMES.RED : hexToRGB(theme.additionalColors[variant])};
    outline: 0;
    box-shadow: 0 0 0 0.25rem
      ${({ theme, variant, error }) =>
        error
          ? hexToRGB(WEBINAR_THEMES.RED, '25%')
          : hexToRGB(theme.additionalColors[variant], '25%')};
  }
`;
export const StyledHelperText = styled.text`
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: ${WEBINAR_THEMES.RED};
  margin-left: 10px;
  margin-top: 5px;
`;
