import Color from 'color';
import * as RDD from 'react-device-detect';

/**
 * @description
 * Method for converting hex with opacity to rgba
 * **/
const hexToRGB = (hex, alpha) => {
  if (!hex) return '';
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r},${g},${b}${alpha ? `,${alpha}` : ''})`;
};

/**
 * @description
 * Method converts string to capitalize
 * **/
const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

/**
 * @description
 * Method are used for scrolling to specific point
 * **/
const scrollTo = (targetRef, option) =>
  targetRef?.current?.scrollIntoView(option) || targetRef?.current?.scrollTo(option);

const strToHslColor = (str, s = 40, l = 60) => {
  let hash = 0;

  for (const strElement of str) {
    hash = str.charCodeAt(strElement) + ((hash << 5) - hash);
  }

  return 'hsl(' + (hash % 360) + ', ' + s + '%, ' + l + '%)';
};

/**
 * @description
 * Get image dimensions
 * **/
const getImageForDimensions = (url, width, height) => {
  if (url && !url?.endsWith('.gif')) {
    const path = url.substring(0, url.lastIndexOf('/'));
    const fileName = url.substring(url.lastIndexOf('/') + 1, url.lastIndexOf('.'));
    const extension = url.substring(url.lastIndexOf('.') + 1);
    return `${path}/${fileName}-${width}x${height}.${extension}`;
  }
  return url;
};

/**
 * @description
 * Get image dimensions
 * **/
const getRandomIntInRange = (min, max) => {
  const minInt = Math.ceil(min);
  const maxInt = Math.floor(max);
  return Math.floor(Math.random() * (maxInt - minInt)) + minInt;
};

/**
 * @description
 * Creates an object composed of keys generated from the results of running each element of collection
 * **/
const groupBy = (array, key) =>
  array.reduce((accumulatorObject, item) => {
    (accumulatorObject[item[key]] = accumulatorObject[item[key]] || []).push(item);
    return accumulatorObject;
  }, {});

const generateLinkColor = ({ colorHexStr, setUniqColor = false }) => {
  let linkColorHexStr;
  if (!colorHexStr) {
    return null;
  }
  if (colorHexStr) {
    try {
      const color = Color(colorHexStr);
      if (color.isLight() || setUniqColor) {
        linkColorHexStr = color.darken(0.5).hex();
      } else {
        linkColorHexStr = colorHexStr;
      }
      return linkColorHexStr;
    } catch (err) {
      return null;
    }
  }
};

/**
 * @description
 * This util are used for checking a fresh version of app.
 * **/
const VersionUpdatesUtil = {
  oldSource: null,

  ActivityBasedTimer: function () {
    let globalTimerId = 0;
    const timers = new Map();

    const maybeExecuteTimerCallback = ({ timerId, forced = false }) => {
      const timer = timers.get(timerId);

      if (!timer === undefined) {
        return;
      }

      const { callback, interval, forcedInterval, forcedIntervalId, lastExecution } = timer;
      const intervalToCheckFor = forced === true ? forcedInterval : interval;
      const now = Date.now();

      if (now - lastExecution < intervalToCheckFor) {
        return;
      }

      const newTimer = {
        ...timer,
        lastExecution: now,
      };

      if (forcedIntervalId !== undefined) {
        window.clearInterval(forcedIntervalId);
        newTimer.forcedIntervalId = window.setInterval(() => {
          maybeExecuteTimerCallback({ timerId, forced: true });
        }, forcedInterval);
      }

      timers.set(timerId, newTimer);
      callback({ forced, timerId });
    };

    const setInterval = ({ callback, interval, forcedInterval } = {}) => {
      const timerId = globalTimerId;

      if (typeof callback !== 'function' || typeof interval !== 'number') {
        return undefined;
      }

      const timer = {
        callback,
        interval,
        lastExecution: Date.now(),
      };

      if (forcedInterval !== undefined) {
        timer.forcedInterval = forcedInterval;
        timer.forcedIntervalId = window.setInterval(() => {
          maybeExecuteTimerCallback({ timerId, forced: true });
        }, forcedInterval);
      }

      timers.set(timerId, timer);
      globalTimerId += 1;
      return timerId;
    };

    const clearInterval = (timerId) => {
      const timer = timers.get(timerId);

      if (timer === undefined) {
        return;
      }

      const { forcedIntervalId } = timer;

      if (forcedIntervalId !== undefined) {
        window.clearInterval(forcedIntervalId);
      }

      timers.delete(timerId);
    };

    const runTimersCheck = () => {
      timers.forEach((_timer, timerId) => {
        maybeExecuteTimerCallback({ timerId });
      });
    };

    return {
      setInterval,
      clearInterval,
      runTimersCheck,
    };
  },

  isNewerVersionAvailable: async function isNewerVersionAvailable() {
    try {
      const response = await fetch(window.location.origin);
      const freshSource = await response.text();

      if (this.oldSource === null) {
        this.oldSource = freshSource;
        return false;
      }

      if (this.oldSource === freshSource) {
        return false;
      }

      this.oldSource = freshSource;
      return true;
    } catch (e) {
      return false;
    }
  },
};

const loadScript = (src, callback) => {
  let script = document.createElement('script');
  script.src = src;

  script.onload = () => callback(script);

  document.head.append(script);
};

const BROWSERS = {
  ALLOWED: [RDD.isChrome, RDD.isFirefox, RDD.isSafari, RDD.isMobileSafari, RDD.isEdge, RDD.isOpera],
  FORBIDDEN: [RDD.isIE],
};
const BROWSER = {
  ALLOWED: BROWSERS.ALLOWED.some((isAllowed) => isAllowed),
  FORBIDDEN: BROWSERS.FORBIDDEN.some((isForbidden) => isForbidden),
};

const getEventUrlKeyFromPath = (path) => {
  if (!path) {
    return null;
  }

  if (path) {
    const pathParts = path.split('/');

    if (pathParts[0] !== 'events' && pathParts[0] !== 'webinars') {
      // all paths should start with either /events or /webinars
      return null;
    }

    // We made it all the way here, the next part should be the event urlKey
    return pathParts[1];
  }
};

/**
 * @description
 * Function for check color
 * **/

const replaceURLs = (text) => {
  if (!text) return;

  const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
  return text.replace(urlRegex, (url) => {
    let hyperlink = url;
    if (!hyperlink.match('^https?://')) {
      hyperlink = 'https://' + hyperlink;
    }
    return `<a href="${hyperlink}" target="_blank" rel="noopener noreferrer">${url}</a>`;
  });
};

const toDataURL = async (src) => {
  const image = new Image();
  return new Promise((res) => {
    image.onload = async function () {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.height = this.naturalHeight;
      canvas.width = this.naturalWidth;
      context.drawImage(this, 0, 0);
      const dataURL = await canvas.toDataURL('image/jpeg');
      res(dataURL);
    };
    image.src = src;
  });
};

const getBase64FromFile = (file) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  return new Promise((res) => {
    reader.onload = function () {
      res(reader.result);
    };
  });
};

export {
  VersionUpdatesUtil,
  BROWSER,
  hexToRGB,
  capitalizeFirstLetter,
  scrollTo,
  getImageForDimensions,
  getRandomIntInRange,
  groupBy,
  strToHslColor,
  generateLinkColor,
  loadScript,
  getEventUrlKeyFromPath,
  replaceURLs,
  toDataURL,
  getBase64FromFile,
};
