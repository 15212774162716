import styled from 'styled-components';
import Badge from '@material-ui/core/Badge';
import { WEBINAR_THEMES } from 'shared';

export const StyledBadge = styled(Badge)`
  & .MuiBadge-dot {
    min-width: 6px;
    height: 6px;
    color: ${WEBINAR_THEMES.RED};
  }

  & .MuiBadge-badge {
    width: 28px;
    height: 28px;
    border-radius: 100%;
  }

  & .MuiBadge-anchorOriginTopRightCircle {
    top: -10px;
    right: 2px;
  }

  & .MuiBadge-colorError {
    background-color: ${({ styles }) => styles.backgroundColor};
    border: ${({ styles }) => `1px solid ${styles.borderColor}`};
    color: ${({ styles }) => styles.color};
  }
`;
