import { useCallback } from 'react';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import SaveAlt from '@material-ui/icons/SaveAlt';
import linkIcon from 'assets/images/webinar/link-icon.png';
import { pdf, ppt, music, image, font, code } from 'assets/images/webinar';
import { Mixpanel } from 'services';
import { MIXPANEL_EVENTS } from 'shared';
import { useGetCurrentEvent } from 'lib/api/event';
import { useGetCurrentUser } from 'lib/api/user';
import { EventResource } from 'models/Event';
import {
  StyledMainButton,
  StyledLink,
  StyledCardText,
  StyledCard,
  StyledLinkImage,
  StyledFileIcon,
  StyledIconWidth,
} from './resourceCard.styled';

const fileTypeIcon = {
  pdf: pdf(),
  ppt: ppt(),
  mp3: music(),
  wav: music(),
  jpg: image(),
  jpeg: image(),
  png: image(),
  gif: image(),
  bmp: image(),
  psd: image(),
  otf: font(),
  ttf: font(),
  woff: font(),
  woff2: font(),
  eot: font(),
  html: code(),
  css: code(),
  js: code(),
  json: code(),
};

export const ResourceCard = ({ _id, title, type, extension, resourceUrl }: EventResource) => {
  const { data: webinar } = useGetCurrentEvent();
  const { data: user } = useGetCurrentUser();
  const eventId = webinar._id;
  const userId = user._id;

  const handleClick = useCallback(() => {
    Mixpanel.track(MIXPANEL_EVENTS.resourceClicked.success, {
      eventId,
      userId,
      resourceId: _id,
    });
    window.open(
      /^https?:\/\//.test(resourceUrl) ? resourceUrl : `https://${resourceUrl}`,
      '_blank',
    );
  }, [eventId, userId, _id, resourceUrl]);

  return (
    <StyledMainButton onClick={handleClick}>
      <StyledCard>
        <StyledCardText>
          <StyledIconWidth>
            {!extension && type === 'url' ? (
              <StyledLinkImage src={linkIcon} alt={title} />
            ) : (
              <StyledFileIcon>{fileTypeIcon[extension.toLowerCase()]}</StyledFileIcon>
            )}
          </StyledIconWidth>
          {title}
          <StyledLink>
            {!extension && type === 'url' ? (
              <NavigateNextIcon fontSize="inherit" />
            ) : (
              <SaveAlt fontSize="inherit" />
            )}
          </StyledLink>
        </StyledCardText>
      </StyledCard>
    </StyledMainButton>
  );
};
