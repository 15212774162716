import { FC, FormEventHandler, KeyboardEventHandler, useEffect, useState } from 'react';
import { MentionsInput, Mention } from 'react-mentions';
import { chatSend } from 'assets/images/webinar/chatSend';
import { useDebouncedCallback } from 'services';
import { Grow } from 'components';
import { EmojiInput } from './EmojiInput';
import { MentionSuggestion } from './MentionSuggestion';
import {
  StyledForm,
  StyledSendBtn,
  StyledSpinner,
  StyledSendWrapper,
  mentionStyle,
} from './form.styled';
import { useGetCurrentEvent } from 'lib/api/event';
import { useWebinarTheme } from 'lib/webinar';
import { useChatContext } from 'Webinar/ChatContext';
import { useApiClient } from 'lib/api';
import { isColorTooDark } from 'lib/colors';
import { getImageForDimensions, WEBINAR_THEMES } from 'shared';
import mentionsInputStyle from './mentionsInputStyle';

interface Props {
  onAfterSubmit: () => void;
}

export const Form: FC<Props> = ({ onAfterSubmit }) => {
  const [value, setValue] = useState<string>('');

  const apiClient = useApiClient();

  const {
    postNewMessage,
    sendTypingSignal,
    isPostingNewMessage,
    addRequestFillChatInputListener,
    removeRequestFillChatInputListener,
  } = useChatContext();

  const { data: webinar } = useGetCurrentEvent();
  const { itemsColor, additionalColors } = useWebinarTheme(webinar);

  useEffect(() => {
    addRequestFillChatInputListener(setValue);
    return () => removeRequestFillChatInputListener(setValue);
  }, [addRequestFillChatInputListener, removeRequestFillChatInputListener]);

  const fetchUsers = useDebouncedCallback(async (query, callback) => {
    if (!query || query.split(' ').length > 2) return;
    const { users } = await apiClient.get<{
      users: {
        _id: string;
        user: { _id: string; firstName: string; lastName: string; imageUrl: string };
      }[];
    }>(`/events/${webinar.urlKey}/chat/mentions`, {
      search: query,
    });
    const response = users.map(({ user }) => ({
      id: user._id,
      display: `${user.firstName} ${user.lastName}`,
      avatar: getImageForDimensions(user.imageUrl, 40, 40),
    }));
    callback(response);
  }, 400);

  const handleFormSubmit: FormEventHandler = async (event) => {
    event.preventDefault();
    await postNewMessage(value);
    onAfterSubmit();
    setValue('');
  };

  const handleOnInputChange = (event) => {
    setValue(event.target.value);
    sendTypingSignal();
  };

  const onKeyDown: KeyboardEventHandler<HTMLFormElement> = async (event) => {
    if (value.trim().length && event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      setValue('');
      await postNewMessage(value);
      onAfterSubmit();
      setValue('');
    }
  };

  return (
    <StyledForm onSubmit={handleFormSubmit} onKeyDown={onKeyDown}>
      <MentionsInput
        type="mentions"
        name="message"
        allowSpaceInQuery
        autoFocus
        placeholder="Write a reply..."
        value={value}
        onChange={handleOnInputChange}
        rendernestederror="false"
        style={mentionsInputStyle}
      >
        <Mention
          appendSpaceOnAdd
          displayTransform={(id, display) => `@${display}`}
          trigger="@"
          data={fetchUsers}
          style={{
            backgroundColor: additionalColors.secondaryColor,
            color:
              WEBINAR_THEMES[isColorTooDark(additionalColors.secondaryColor) ? 'WHITE' : 'BLACK'],
            ...mentionStyle,
          }}
          renderSuggestion={MentionSuggestion}
          value={value}
        />
      </MentionsInput>
      <StyledSendWrapper>
        <Grow in={isPostingNewMessage} exit={false} enter={false}>
          <StyledSpinner size={18} />
        </Grow>

        <Grow in={!isPostingNewMessage}>
          <StyledSendBtn
            type="submit"
            theme={itemsColor}
            color={WEBINAR_THEMES[isColorTooDark(itemsColor) ? 'WHITE' : 'BLACK']}
            disabled={!value.trim().length}
          >
            {chatSend()}
          </StyledSendBtn>
        </Grow>
      </StyledSendWrapper>

      <EmojiInput itemsColor={itemsColor} value={value} onSelection={setValue} />
    </StyledForm>
  );
};
