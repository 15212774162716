import styled from 'styled-components';
import { WEBINAR_THEMES } from 'shared';
import AvatarGroup from '@material-ui/lab/AvatarGroup';

export const StyledAvatarGroup = styled(AvatarGroup)`
  & .MuiAvatarGroup-avatar {
    border: 1px solid ${WEBINAR_THEMES.WHITE};
  }

  &.MuiAvatarGroup-root {
    margin-left: 10px;
  }
`;
