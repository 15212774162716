export const WEBINAR_THEMES = {
  BLACK: '#000000',
  LIGHT_BLUE: 'rgb(0, 102, 255)',
  BLUE: '#0177FF',
  LIGHT_WHITE: '#f5f5f5',
  WHITE: '#ffffff',
  LIGHTER_GREY: '#ced4da',
  LIGHT_GREY: '#969cab',
  MIDDLE_GREY: '#b4b5b6',
  GREY: '#A5ADC6',
  DARK_GREY: '#6A728C',
  DARK: '#16151a',
  GREEN: '#1fa800',
  LIGHT_GREEN: '#59cf4a',
  CORAL: '#f77947',
  RED: '#B30000',
};
