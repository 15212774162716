export const image = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22">
    <path
      d="M 8.94 9.625 C 10.079 9.625 11.003 8.702 11.003 7.563 C 11.003 6.423 10.079 5.5 8.94 5.5 C 7.801 5.5 6.878 6.423 6.878 7.563 C 6.878 8.702 7.801 9.625 8.94 9.625 Z"
      fill="rgb(0,0,0)"
    />
    <path
      d="M 19.25 19.25 C 19.25 20.769 18.019 22 16.5 22 L 5.5 22 C 3.981 22 2.75 20.769 2.75 19.25 L 2.75 2.75 C 2.75 1.231 3.981 0 5.5 0 L 13.063 0 L 19.25 6.188 Z M 5.5 1.375 C 4.741 1.375 4.125 1.991 4.125 2.75 L 4.125 16.5 L 7.183 13.442 C 7.406 13.22 7.752 13.177 8.022 13.339 L 11 15.125 L 13.966 10.973 C 14.083 10.808 14.267 10.704 14.468 10.687 C 14.67 10.671 14.868 10.743 15.011 10.886 L 17.875 13.75 L 17.875 6.188 L 15.125 6.188 C 13.986 6.188 13.063 5.264 13.063 4.125 L 13.063 1.375 Z"
      fill="rgb(0,0,0)"
    />
  </svg>
);
