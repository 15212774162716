import { StyledContainer, StyledBar } from './listeningBars.styled';

export const ListeningBars = ({ coloredBars, size, ...rest }) => (
  <StyledContainer size={size} {...rest}>
    {coloredBars.map((bar, index) => (
      <StyledBar key={index} theme={bar} size={size} />
    ))}
  </StyledContainer>
);

ListeningBars.defaultProps = {
  coloredBars: ['#0571ff', '#d6462e', '#ffb200', '#0571ff', '#d6462e', '#ffb200'],
  size: 1,
};
