import { StyledTabPanel } from './tabpanel.styled';

/**
 * Description:
 *
 * Component for Tab panel
 */

export const TabPanel = (props) => {
  const { children, value, index, className, ...other } = props;

  return (
    <StyledTabPanel
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={className}
      {...other}
    >
      {value === index && children}
    </StyledTabPanel>
  );
};
