import { Fragment, useState } from 'react';
import { Grow, Skeleton, Slide } from 'components/ui';
import { SingleSelection } from './SingleSelection';
import { PollForm } from './PollForm';
import {
  StyledList,
  StyledLi,
  StyledSkeletons,
  StyledSkeletonWrapper,
  StyledWrapper,
  StyledNoPollsWrapper,
  StyledNoPollsHeading,
  StyledContainer,
  StyledText,
  StyledCreatePollBlock,
} from './polls.styled';
import { useGetCurrentEvent } from 'lib/api/event';
import { useIsOrganizerOfCurrentWebinar, useWebinarTheme } from 'lib/webinar';
import { useGetPolls, usePollParams } from 'lib/api/poll';
import { Poll } from 'models/Poll';
import Button from 'components/ui/Button';

const pollType = {
  SingleSelection: (props) => <SingleSelection {...props} />,
};

export const Polls = () => {
  const [showPollForm, setShowPollForm] = useState(false);
  const [pollToEdit, setPollToEdit] = useState<Poll>(null);
  const { data: webinar } = useGetCurrentEvent();

  const isOrganizer = useIsOrganizerOfCurrentWebinar();

  const pollsParams = usePollParams();
  const { data: polls, isLoading } = useGetPolls(webinar._id, pollsParams);
  const theme = useWebinarTheme(webinar);

  const closeForm = () => {
    setShowPollForm(false);
    setPollToEdit(null);
  };

  const CreatePollButtonForOrganizer = isOrganizer ? (
    <StyledCreatePollBlock>
      <Button block onClick={() => setShowPollForm(true)}>
        Create a poll
      </Button>
    </StyledCreatePollBlock>
  ) : null;

  if (showPollForm || pollToEdit) {
    return (
      <PollForm
        poll={pollToEdit}
        webinarId={webinar._id}
        onRequestClose={closeForm}
        pollsParams={pollsParams}
      />
    );
  }

  if (isLoading) {
    return (
      <StyledContainer withBottom={isOrganizer}>
        <StyledList>
          <Grow in={true} timeout={300}>
            <StyledSkeletonWrapper>
              {Array(3)
                .fill(
                  <StyledSkeletons>
                    <Skeleton
                      variant="rect"
                      height={15}
                      width="70%"
                      style={{ borderRadius: '5px' }}
                    />
                    <StyledWrapper>
                      <Skeleton
                        variant="rect"
                        height={12}
                        width="20%"
                        style={{ borderRadius: '10px' }}
                      />
                      <Skeleton
                        variant="rect"
                        height={12}
                        width="20%"
                        style={{ borderRadius: '10px' }}
                      />
                    </StyledWrapper>
                    <Skeleton
                      variant="rect"
                      height={25}
                      width="100%"
                      style={{ borderRadius: '4px' }}
                    />
                    <Skeleton
                      variant="rect"
                      height={25}
                      width="100%"
                      style={{ borderRadius: '4px' }}
                    />
                    <Skeleton
                      variant="rect"
                      height={25}
                      width="100%"
                      style={{ borderRadius: '4px' }}
                    />
                  </StyledSkeletons>,
                )
                .map((sk, ind) => (
                  <Fragment key={ind}>{sk}</Fragment>
                ))}
            </StyledSkeletonWrapper>
          </Grow>
        </StyledList>
      </StyledContainer>
    );
  }

  if (!polls || polls.length === 0) {
    return (
      <StyledContainer withBottom={isOrganizer}>
        <StyledNoPollsWrapper>
          <StyledNoPollsHeading>No active polls</StyledNoPollsHeading>
          <StyledText>
            {isOrganizer
              ? 'Create a poll and start collecting feedback from attendees.'
              : 'We want to hear from you! Be ready to reply when a poll shows up here.'}
          </StyledText>
        </StyledNoPollsWrapper>
        {CreatePollButtonForOrganizer}
      </StyledContainer>
    );
  }

  return (
    <StyledContainer withBottom={isOrganizer}>
      <StyledList>
        {polls.map((poll, index) => (
          <Slide
            key={poll._id}
            direction={`${index % 2 === 0 ? 'right' : 'left'}`}
            timeout={{
              enter: 300,
            }}
          >
            <StyledLi>
              {pollType[poll.type]({
                webinarId: webinar._id,
                poll,
                isOrganizer,
                theme,
                openEditPollForm: () => setPollToEdit(poll),
                pollsParams,
              })}
            </StyledLi>
          </Slide>
        ))}
      </StyledList>

      {CreatePollButtonForOrganizer}
    </StyledContainer>
  );
};
