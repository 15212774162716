import styled from 'styled-components';
import { IconButton } from 'components';

interface IconButtonProps {
  onClick: () => void;
  children: JSX.Element;
}

export const Dialog = styled.div`
  position: fixed;
  z-index: 1;
  bottom: 0;
  right: 0;
`;

export const StyledIconButton = styled(IconButton)<IconButtonProps>`
  &.MuiIconButton-root:hover {
    background-color: transparent;
  }

  & .emoji-smile {
    height: 36px;
  }
`;
