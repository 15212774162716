const VIDEO_PROVIDERS = {
  youtube: (videoId) => `https://www.youtube.com/watch?v=${videoId}`,
  wistia: (videoId) => `//fast.wistia.net/embed/iframe/${videoId}`,
  vimeo: (videoId) => `https://player.vimeo.com/video/${videoId}`,
  library: (videoSlug) => `https://${process.env.REACT_APP_LIBRARY_ENV_URL}/embed/${videoSlug}`,
  session: () => '',
};

const MIXPANEL_EVENTS = {
  login: {
    success: 'Successful login',
    error: 'Unsuccessful login',
  },
  stageVisited: {
    success: 'Stage Visited',
    error: 'Stage Visiting Error',
  },
  stageReactionCreated: {
    success: 'Stage reaction created',
    error: 'Stage reaction has not been created',
  },
  backstageVisited: {
    success: 'Backstage Visited',
    error: 'Backstage Visiting Error',
  },
  sessionVisited: {
    success: 'Session visited',
    error: 'Session error',
  },
  resourceClicked: {
    success: 'Resource clicked',
    error: 'Resource error',
  },
  webinarHeaderButtonClicked: {
    success: 'Webinar header button clicked',
    error: 'Webinar header button error',
  },
};

const EVENT_TIMERS = {
  timeToStartForCountdownInMs: 1000 * 60 * 60 * 3, // 3 hours
  eventEndingBufferInMs: 1000 * 60 * 15, // 15 minutes
  checkForUpdateInMs: 1000 * 60 * 10, // 10 minutes
  forcedCheckForUpdateInMs: 1000 * 60 * 60 * 24, // 24 hours
  viewersInterval: 1000 * 15, // 15 seconds
  viewersTimeout: 1000, // 1 second
  scheduleItemsLiveStatusInterval: 360000, // 6 minutes,
};

const SESSION_TYPES = {
  general: 'general',
  roundTable: 'roundTable',
  meeting: 'meeting',
  private: 'private',
};

const OT_SETTINGS = {
  subscriberProperties: {
    height: '100%',
    width: '100%',
    showControls: true,
    style: {
      audioBlockedDisplayMode: 'off',
      audioLevelDisplayMode: 'auto',
      buttonDisplayMode: 'off',
      nameDisplayMode: 'off',
      videoDisabledDisplayMode: 'off',
    },
  },
  sessionHandlers: {
    streamPropertyChanged: (e) => {
      if (e.changedProperty === 'hasAudio') {
        e.stream.trigger('audioChanged', e.newValue);
      }
    },
  },
};

const WEBINAR_RIGHT_SIDEBAR_TABS = {
  chat: 'chat',
  resources: 'resources',
};

const WEBINAR_ROLES = {
  attendee: 'attendee',
  speaker: 'speaker',
  moderator: 'moderator',
  organizer: 'organizer',
  super: 'super',
};

const WEBINAR_PUSHER_EVENTS = {
  POLL_UPDATED: 'poll-updated',
  POLL_DELETED: 'poll-deleted',
  POLL_ANSWER_UPDATED: 'poll-answer-updated',
  QUESTION_CREATED: 'question-created',
  QUESTION_UPDATED: 'question-updated',
  QUESTION_DELETED: 'question-deleted',
  RESOURCES_ENABLED: 'resources-enabled',
  NEW_EVENT_NOTIFICATION: 'new-event-notification',
  NEW_TARGET_NOTIFICATION: 'new-target-notification',
  ACCESS_BLOCKED: 'access-blocked',
  NEW_ACCESS_REQUEST: 'new-access-request',
  NEW_PERMISSION: 'new-permission',
  DELETE_ACCESS_REQUEST: 'delete-access-request',
  LIVE_STREAM_ACTIVE: 'live-stream-active',
  LIVE_STREAM_IDLE: 'live-stream-idle',
  NEW_STAGE_REACTION: 'new-stage-reaction',
  UPDATE_STAGE: 'update-stage',
  UPDATE_SESSION: 'update-session',
  UPDATE_MIRO_BOARD_SESSION: 'updateMiroBoard-session',
  LOCK_SESSION: 'lock-session',
  UNLOCK_SESSION: 'unlock-session',
  AUDIO_DISABLED: 'audio-disabled',
  RECORDING_ERROR: 'recording-error',
};

export {
  VIDEO_PROVIDERS,
  MIXPANEL_EVENTS,
  EVENT_TIMERS,
  SESSION_TYPES,
  OT_SETTINGS,
  WEBINAR_RIGHT_SIDEBAR_TABS,
  WEBINAR_ROLES,
  WEBINAR_PUSHER_EVENTS,
};
