import { useCallback, useEffect, useMemo } from 'react';
import { ReactSortable } from 'react-sortablejs';
import { useFormik } from 'formik';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import List from '@material-ui/core/List';
import { v4 as uuid } from 'uuid';
import { pollValidationSchema } from './formSchema';
import { PollTypes } from 'shared/configs/polls';
import { Select, Input } from 'components';
import { IconButtonBase } from 'components/ui/IconButtonBase/IconButtonBase';
import { ThemedSwitch } from 'components/ui/ThemedSwitch';
import PollOptionField from './PollOptionField';
import { StyledContainer, StyledForm } from './PollForm.styled';
import { useGetCurrentEvent } from 'lib/api/event';
import { useWebinarTheme } from 'lib/webinar';
import { useGetCurrentSession } from 'lib/api/session';
import { useCreatePoll, useUpdatePoll } from 'lib/api/poll';
import { useSendNotification } from 'lib/notifications';
import Button from 'components/ui/Button';

export const PollForm = ({ webinarId, poll, onRequestClose, pollsParams }) => {
  const notification = useSendNotification();
  const { data: webinar } = useGetCurrentEvent();
  const { data: session } = useGetCurrentSession();
  const theme = useWebinarTheme(webinar);
  const { mutateAsync: createPoll, isLoading: isLoadingCreatePoll } = useCreatePoll(
    webinarId,
    pollsParams,
  );
  const { mutateAsync: updatePoll, isLoading: isLoadingUpdatePoll } = useUpdatePoll(
    webinarId,
    poll?._id,
    pollsParams,
  );
  const isLoading = isLoadingCreatePoll || isLoadingUpdatePoll;

  const formik = useFormik({
    initialValues: {
      label: '',
      type: PollTypes.SingleSelection.value,
      options: [
        { key: uuid(), label: '' },
        { key: uuid(), label: '' },
      ],
      isPublished: false,
      isAcceptingAnswers: true,
    },
    validationSchema: pollValidationSchema,
    onSubmit: async (values) => {
      const newPollCreateData = {
        label: values.label,
        isPublished: values.isPublished,
        isAcceptingAnswers: values.isAcceptingAnswers,
        session: session?._id,
      };
      if (session) {
        newPollCreateData.session = session?._id;
      }
      const canEditOptionsAndType = poll ? !poll.answersCount : true;
      if (canEditOptionsAndType) {
        newPollCreateData.options = values.options.map((option, index) => ({
          _id: option.id,
          label: option.label,
          position: index,
        }));
        newPollCreateData.type = values.type;
      }
      try {
        if (poll) {
          await updatePoll(newPollCreateData);
        } else {
          await createPoll(newPollCreateData);
        }
        onRequestClose();
      } catch (e) {
        notification.error({
          message: 'Error creating new poll',
        });
      }
    },
  });
  useEffect(() => {
    if (poll && formik.values._id !== poll._id) {
      poll.options.forEach((option) => {
        option.key = option._id;
      });
      formik.setValues(poll);
    }
  }, [poll, formik]);
  const appendOption = useCallback(() => {
    formik.setFieldValue('options', [...formik.values.options, { key: uuid(), label: '' }]);
  }, [formik]);
  const removeOption = useCallback(
    (index) => {
      formik.setFieldValue('options', [
        ...formik.values.options.slice(0, index),
        ...formik.values.options.slice(index + 1),
      ]);
    },
    [formik],
  );

  const value = useMemo(() => {
    return { value: formik.values.type, label: PollTypes[formik.values.type].label };
  }, [formik.values.type]);

  const hasAnswers = poll?.answersCount > 0;
  return (
    <StyledContainer>
      <div>
        <IconButtonBase size="small" onClick={onRequestClose}>
          <ArrowBackIcon />
        </IconButtonBase>
      </div>
      <div>
        <StyledForm onSubmit={formik.handleSubmit}>
          <Input
            label="Question"
            name="label"
            type="text"
            placeholder="Enter your poll question"
            variant="primaryColor"
            value={formik.values.label}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.label && Boolean(formik.errors.label)}
            helperText={formik.touched.label && formik.errors.label}
            disabled={isLoading}
          />
          <Select
            name="type"
            theme={theme}
            options={[PollTypes.SingleSelection]}
            disabled={isLoading || hasAnswers}
            value={value}
            onChange={formik.handleChange}
          />
          {formik.values.type !== PollTypes.Rating.value && (
            <>
              <List disablePadding>
                <ReactSortable
                  list={formik.values.options}
                  setList={(newList) => {
                    formik.setFieldValue('options', [...newList]);
                  }}
                  disabled={isLoading || hasAnswers}
                >
                  {formik.values.options.map((option, index) => (
                    <PollOptionField
                      key={option.key}
                      option={option}
                      index={index}
                      formik={formik}
                      onRemoveClick={(index) => removeOption(index)}
                      disabled={isLoading || hasAnswers}
                    />
                  ))}
                </ReactSortable>
              </List>
              {!hasAnswers && (
                <Button onClick={appendOption} disabled={isLoading} text>
                  + Add option
                </Button>
              )}
            </>
          )}
          <ThemedSwitch
            label="Publish poll"
            name="isPublished"
            onChange={formik.handleChange}
            checked={formik.values.isPublished}
            appVariant="primaryColor"
            disabled={isLoading}
          />
          <ThemedSwitch
            label="Accept answers"
            name="isAcceptingAnswers"
            onChange={formik.handleChange}
            checked={formik.values.isAcceptingAnswers}
            appVariant="primaryColor"
            disabled={isLoading}
          />

          <Button type="submit" loading={isLoading} disabled={isLoading}>
            {poll ? 'Update' : 'Create'}
          </Button>
        </StyledForm>
      </div>
    </StyledContainer>
  );
};
