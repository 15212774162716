import { useReducer } from 'react';
import { COLLAPSE_LEFT_SIDEBAR } from './contextTypes';
import { CollapseLeftSidebarContext } from './collapseLeftSidebarContext';
import { collapseLeftSidebarReducer } from './collapseLeftSidebarReducer';
import { storageService } from 'services';

export const CollapseLeftSidebarState = ({ children }) => {
  const [state, dispatch] = useReducer(
    collapseLeftSidebarReducer,
    JSON.parse(storageService?.getItem('leftSidebarCollapsed') || false),
  );

  const setCollapseLeftSidebar = (value) => {
    storageService.setItem('leftSidebarCollapsed', value);
    dispatch({ type: COLLAPSE_LEFT_SIDEBAR, value });
  };

  return (
    <CollapseLeftSidebarContext.Provider
      value={{
        collapsedLeftSidebar: state,
        setCollapseLeftSidebar,
      }}
    >
      {children}
    </CollapseLeftSidebarContext.Provider>
  );
};
