import { StyledPill } from './pill.styled';

/**
 * Description:
 *
 * Component for Pill
 */

export const Pill = (props) => <StyledPill {...props} />;

Pill.defaultProps = {};
