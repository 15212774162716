export const code = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22">
    <path
      d="M 19.25 6.188 L 19.25 19.25 C 19.25 20.769 18.019 22 16.5 22 L 5.5 22 C 3.981 22 2.75 20.769 2.75 19.25 L 2.75 2.75 C 2.75 1.231 3.981 0 5.5 0 L 13.063 0 Z M 15.125 6.188 C 13.986 6.188 13.063 5.264 13.063 4.125 L 13.063 1.375 L 5.5 1.375 C 4.741 1.375 4.125 1.991 4.125 2.75 L 4.125 19.25 C 4.125 20.009 4.741 20.625 5.5 20.625 L 16.5 20.625 C 17.259 20.625 17.875 20.009 17.875 19.25 L 17.875 6.188 Z"
      fill="rgb(0,0,0)"
    />
    <path
      d="M 11.888 9.138 C 12.017 9.009 12.192 8.936 12.375 8.936 C 12.558 8.936 12.733 9.009 12.862 9.138 L 15.612 11.888 C 15.741 12.017 15.814 12.192 15.814 12.375 C 15.814 12.558 15.741 12.733 15.612 12.862 L 12.862 15.612 C 12.593 15.881 12.157 15.881 11.888 15.612 C 11.619 15.343 11.619 14.907 11.888 14.638 L 14.153 12.375 L 11.888 10.112 C 11.759 9.983 11.686 9.808 11.686 9.625 C 11.686 9.442 11.759 9.267 11.888 9.138 Z M 10.112 9.138 C 9.983 9.009 9.808 8.936 9.625 8.936 C 9.442 8.936 9.267 9.009 9.138 9.138 L 6.388 11.888 C 6.259 12.017 6.186 12.192 6.186 12.375 C 6.186 12.558 6.259 12.733 6.388 12.862 L 9.138 15.612 C 9.407 15.881 9.843 15.881 10.112 15.612 C 10.381 15.343 10.381 14.907 10.112 14.638 L 7.847 12.375 L 10.112 10.112 C 10.241 9.983 10.314 9.808 10.314 9.625 C 10.314 9.442 10.241 9.267 10.112 9.138 Z"
      fill="rgb(0,0,0)"
    />
  </svg>
);
