import { useFormik } from 'formik';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { questionValidationSchema } from './formSchema';
import { IconButtonBase } from 'components/ui/IconButtonBase/IconButtonBase';
import { ThemedSwitch } from 'components/ui/ThemedSwitch';
import { StyledContainer, StyledForm, StyledHelperText } from './questionForm.styled';
import { NewQuestionParams, QuestionsParams, useCreateQuestion } from 'lib/api/qa';
import { useIsOrganizerOfCurrentWebinar } from 'lib/webinar';
import { Button } from 'components/ui/Button';
import { Input } from 'components';

interface Props {
  webinarId: string;
  onRequestClose: () => unknown;
  sessionId: string;
  questionsParams: QuestionsParams;
}

export const QuestionForm: React.FunctionComponent<Props> = ({
  webinarId,
  onRequestClose,
  sessionId,
  questionsParams,
}) => {
  const { mutateAsync: createQuestion, isLoading } = useCreateQuestion(webinarId, questionsParams);
  const isOrganizer = useIsOrganizerOfCurrentWebinar();
  const formik = useFormik({
    initialValues: {
      label: '',
      isApproved: false,
      isAnswered: false,
    },
    validationSchema: questionValidationSchema,
    onSubmit: async (values) => {
      const newQuestionCreateData: NewQuestionParams = {
        label: values.label,
        isApproved: values.isApproved,
        isAnswered: values.isAnswered,
      };
      if (sessionId) {
        newQuestionCreateData.session = sessionId;
      }
      await createQuestion(newQuestionCreateData);
      onRequestClose();
    },
  });
  return (
    <StyledContainer>
      <div>
        <IconButtonBase size="small" onClick={onRequestClose}>
          <ArrowBackIcon />
        </IconButtonBase>
      </div>
      <div>
        <StyledForm onSubmit={formik.handleSubmit}>
          <Input
            id="label"
            name="label"
            label="Question"
            placeholder="Enter your question"
            variant="primaryColor"
            value={formik.values.label}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.label && Boolean(formik.errors.label)}
            helperText={formik.touched.label && formik.errors.label}
            disabled={isLoading}
          />
          {isOrganizer && (
            <>
              <ThemedSwitch
                label="Approve"
                name="isApproved"
                onChange={formik.handleChange}
                checked={formik.values.isApproved}
                appVariant="primaryColor"
                disabled={isLoading}
              />
              <ThemedSwitch
                label="Answer"
                name="isAnswered"
                onChange={formik.handleChange}
                checked={formik.values.isAnswered}
                appVariant="primaryColor"
                disabled={isLoading}
              />
            </>
          )}
          <StyledHelperText>
            Your question will be reviewed by the organizers and once it is approved, it will be
            visible to everyone, and available to be answered
          </StyledHelperText>
          <Button type="submit" loading={isLoading} disabled={isLoading}>
            Create
          </Button>
        </StyledForm>
      </div>
    </StyledContainer>
  );
};
