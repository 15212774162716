import styled from 'styled-components';
import { WEBINAR_THEMES, Flex } from 'shared';

const StyledMainButton = styled.button`
  width: 100%;
`;

const StyledCardText = styled.div`
  ${Flex.fullCenter};
  color: ${WEBINAR_THEMES.BLACK};
  word-break: break-word;
  font-weight: bold;
  font-style: normal;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 1.2;
  align-items: center;
`;

const StyledIconWidth = styled.div`
  height: 40px;
  width: 40px;
  display: flex;
`;

const StyledLinkImage = styled.img`
  ${Flex.fullCenter};
  align-self: center;
  justify-content: center;
  color: ${WEBINAR_THEMES.BLACK};
  width: 25px;
  margin-right: 7px;
`;

const StyledFileIcon = styled.span`
  ${Flex.fullCenter};
  align-self: center;
  justify-content: center;
  color: ${WEBINAR_THEMES.BLACK};
  margin-right: 7px;
  transform: scale(1.2);
`;

const StyledLink = styled.a`
  ${Flex.fullCenter};
  flex: 2;
  justify-content: flex-end;
  padding: 0;
  font-size: 25px;
`;

const StyledCard = styled.div`
  position: relative;
  padding: 10px 15px;
  margin-top: 10px;
  background-color: ${WEBINAR_THEMES.LIGHT_WHITE};
  border-radius: 10px;
`;

export {
  StyledMainButton,
  StyledCardText,
  StyledCard,
  StyledLinkImage,
  StyledFileIcon,
  StyledLink,
  StyledIconWidth,
};
