import { useContext } from 'react';
import { close, open } from 'assets/images/webinar';
import { CollapseRightSidebarContext } from 'contexts';
import { SidebarContent } from './SidebarContent';
import BreakoutSessions from './BreakoutSessions/BreakoutSessions';
import styled from 'styled-components';
import { WEBINAR_THEMES } from 'shared';
import { useGetCurrentEvent } from 'lib/api/event';
import { EventFormat } from 'models/Event';

const StyledSection = styled.div<{ collapsed?: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: ${({ collapsed }) => `${collapsed ? 'auto' : 'visible'}`};
  min-width: ${({ collapsed }) => `${collapsed ? '0' : '348px'}`};
  width: ${({ collapsed }) => `${collapsed ? '0' : '348px'}`};
  padding-inline: ${({ collapsed }) => `${collapsed ? '0' : '16px'}`};
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
  transition: 0.5s;
  background-color: ${WEBINAR_THEMES.WHITE};
  z-index: 1;
  padding-bottom: 60px;
  height: 100%;
  overflow: hidden;
`;

const StyledButtonCollapse = styled.button<{ collapsed?: boolean }>`
  position: fixed;
  transition: 0.2s;
  z-index: 11;
  -webkit-transform: translateZ(0);
  margin-top: ${({ collapsed }) => `${collapsed ? '20px' : '8px'}`};
  margin-left: ${({ collapsed }) => `${collapsed ? '-35px' : '-40px'}`};
`;

export const RightSidebar = ({ itemsColor }) => {
  const { data: webinar } = useGetCurrentEvent();
  const { collapsedRightSidebar, setCollapseRightSidebar } = useContext(
    CollapseRightSidebarContext,
  );
  return (
    <StyledSection collapsed={collapsedRightSidebar}>
      <StyledButtonCollapse
        collapsed={collapsedRightSidebar}
        onClick={() => setCollapseRightSidebar(!collapsedRightSidebar)}
      >
        <span>{collapsedRightSidebar ? open(itemsColor) : close(itemsColor)}</span>
      </StyledButtonCollapse>
      <SidebarContent />
      {webinar.eventFormat !== EventFormat.Playlist && <BreakoutSessions />}
    </StyledSection>
  );
};
