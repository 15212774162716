import { keyframes } from 'styled-components';

const pulsate = keyframes`
   0% {
    transform: scale(0.1, 0.1); 
    opacity: 0.0;
   }
   50% {
    opacity: 1.0;
   }
   100% {
    transform: scale(1.2, 1.2); 
    opacity: 0.0;
   }
`;

const scaleIn = keyframes`
  from {
      transform: scale(0.5, 0.5);
      opacity: 0.5;
  }
  to {
      transform: scale(1.0, 1.0);
      opacity: 0;
  }
`;

const increaseWidth = (percent) => keyframes`
  0% { 
    width: 0; 
  }
  100% { 
    width: ${percent}%; 
  }
`;

export { pulsate, scaleIn, increaseWidth };
