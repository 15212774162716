export const edgeIcon = () => (
  <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 480 480">
    <path
      fill="#1E88E5"
      d="M434.8,373.2C328,511.9,184.9,475.9,128.7,444.4c-85.8-48-145.9-159.7-110.2-271.3C29,140.2,45,112,64.9,88.7
	c19.5,1.5,42.6,1.5,70-0.5C298.4,76.6,345,216.6,333.4,251.6c-6,17.9-27.1,23.6-46.3,24.9c1.9-2.6,4.2-6.3,7.5-11.9
	c10.2-18,11.4-52.4-20.2-73.2c-30.5-19.8-63.4-7.6-80.3,14.9c-16.9,22.4-10.3,56.1-4.3,71.1c25.7,64.4,73.1,81.1,105.3,90.8
	s80.1,8.3,105.6-2.2c25.4-10.6,32.7-16.7,37.6-11.3C443.2,360,439.7,366.2,434.8,373.2z"
    />
    <path
      fill="#0D47A1"
      d="M435.3,373.2c-40.6,52.8-86.5,80.2-130.8,92.3c-0.4,0.1-0.7,0.1-0.9,0.2c-25.7,4.9-46.1,0.9-68.3-3.4
	c-36.1-7-85.8-46.8-97.8-118.8c-10.3-62,19-114.5,65.2-146.3c-3,2.8-5.7,5.8-8.2,9.1c-16.9,22.4-10.3,56.1-4.3,71.1
	c25.7,64.4,73.1,81.1,105.3,90.8s80.1,8.3,105.6-2.2c25.4-10.6,32.7-16.7,37.6-11.3C443.7,360,440.2,366.2,435.3,373.2z"
    />
    <path
      fill="#00E5FF"
      d="M274.3,9.4c0.2,3,0.4,6.3,0.4,9.5c0,44.1-20.4,83.3-52.3,108.8c-11.9-6.1-25.8-11-42.6-14.2
	c-47.5-9.1-124.1,12.8-155.2,67.3C14.3,198.6,10,216.9,8.1,233.9c0.5-20,3.9-40.4,10.4-60.8C56.9,53.3,168.3-4.6,274.3,9.4z"
    />
    <path
      fill="#00E676"
      d="M443.1,285.3c-32.2,32.4-73.2,33.4-101.2,31.9c-28.1-1.4-41.9-9.6-54.7-17.5c-13-8.1-5.6-16-4.3-17.7
	c1.3-1.8,4.4-4.8,11.7-17.4c3.4-6,5.8-13.8,6.3-22.3c53.9-40,92.9-99.1,106.9-167.4c34.1,34.4,50.2,72.5,55.9,88.8
	C473.9,193.1,481.7,246.5,443.1,285.3z"
    />
    <path
      fill="#1DE9B6"
      d="M407.7,74.8v0.1c-14,68.3-53,127.3-106.9,167.4c0.4-4.9,0-10.2-1.2-15.4c0-0.2-0.1-0.5-0.1-0.6
	c-2.9-17.2-11.6-38.9-25.9-55.7c-14.2-16.8-29.4-31.9-51.2-42.9c31.9-25.4,52.3-64.7,52.3-108.8c0-3.2-0.1-6.4-0.4-9.5
	c4.7,0.6,9.2,1.3,13.9,2.2C342,22,380.5,47.3,407.7,74.8z"
    />
  </svg>
);
