import { forwardRef } from 'react';
import { StyledMenuItem } from './menuItem.styled';

/**
 * Description:
 *
 * Component for Menu item
 */

export const MenuItem = ({ forwardRef, ...rest }) => <StyledMenuItem {...rest} ref={forwardRef} />;

export const MenuItemRef = forwardRef((props, ref) => <StyledMenuItem {...props} ref={ref} />);
