export const music = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22">
    <path
      d="M 15.125 9.13 C 15.125 8.706 14.93 8.307 14.596 8.046 C 14.262 7.785 13.827 7.693 13.416 7.796 L 12.041 8.14 C 11.429 8.293 11 8.843 11 9.474 L 11 15.395 C 10.564 15.214 10.097 15.122 9.625 15.125 C 8.938 15.125 8.286 15.309 7.785 15.643 C 7.29 15.973 6.875 16.507 6.875 17.188 C 6.875 17.868 7.289 18.402 7.785 18.732 C 8.286 19.066 8.939 19.25 9.625 19.25 C 10.313 19.25 10.964 19.066 11.465 18.732 C 11.96 18.402 12.375 17.868 12.375 17.188 L 12.375 12.224 L 15.125 11.536 Z"
      fill="rgb(0,0,0)"
    />
    <path
      d="M 19.25 19.25 L 19.25 6.188 L 13.063 0 L 5.5 0 C 3.981 0 2.75 1.231 2.75 2.75 L 2.75 19.25 C 2.75 20.769 3.981 22 5.5 22 L 16.5 22 C 18.019 22 19.25 20.769 19.25 19.25 Z M 13.063 4.125 C 13.063 5.264 13.986 6.188 15.125 6.188 L 17.875 6.188 L 17.875 19.25 C 17.875 20.009 17.259 20.625 16.5 20.625 L 5.5 20.625 C 4.741 20.625 4.125 20.009 4.125 19.25 L 4.125 2.75 C 4.125 1.991 4.741 1.375 5.5 1.375 L 13.063 1.375 Z"
      fill="rgb(0,0,0)"
    />
  </svg>
);
