import { useMemo, useState, useEffect } from 'react';
import { formatDuration, intervalToDuration } from 'date-fns/esm';
import { ScheduleItem } from 'models/ScheduleItem';

export const useScheduleItemTime = (scheduleItem: ScheduleItem) => {
  const [now, setNow] = useState(Date.now());

  const startTime = useMemo(() => {
    return new Date(scheduleItem.startsAtUtc);
  }, [scheduleItem]);

  const endTime = useMemo(() => {
    return new Date(scheduleItem.endsAtUtc);
  }, [scheduleItem]);

  const duration = useMemo(() => {
    const duration = intervalToDuration({
      start: startTime,
      end: endTime,
    });
    return formatDuration(duration, { format: ['days', 'hours', 'minutes', 'seconds'] });
  }, [startTime, endTime]);

  const isUpcoming = useMemo(() => {
    return startTime.getTime() > now;
  }, [startTime, now]);

  const isNow = useMemo(() => {
    return startTime.getTime() < now && endTime.getTime() > now;
  }, [startTime, endTime, now]);

  const isOver = useMemo(() => {
    return now > endTime.getTime();
  }, [now, endTime]);

  const timeToStart = useMemo(() => {
    const duration = intervalToDuration({
      start: startTime,
      end: new Date(now),
    });
    return formatDuration(duration, { format: ['days', 'hours', 'minutes', 'seconds'] });
  }, [startTime, now]);

  useEffect(() => {
    const intervalID = setInterval(() => setNow(Date.now()), 1000);
    return () => clearInterval(intervalID);
  }, []);
  return { startTime, endTime, duration, isUpcoming, isNow, isOver, timeToStart };
};
